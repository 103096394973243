.Card-d {
	&--teams {
		.Card-d {
			&__wrapper {
				.Picture {
					&__block-img {
						padding-bottom: 100%;
					}
					&__img {
						object-fit: cover;
					}
				}
			}
		}
	}
	&__content {
		position: absolute;
		bottom: 0;
		background: var(--color-a);
		width: 100%;
		padding: 10px 20px 20px 20px;
		text-align: center;
		opacity: 0;
		visibility: hidden;
		transform: translateY(100%);
		transition: 0.3s all;
		.Subtitle,
		.Title {
			font-style: normal;
			line-height: 112.3%;
			/* or 25px */
			margin-top: 5px;
			text-align: center;
			letter-spacing: 0.115em;
			text-transform: none;

			color: #ffffff;
		}
		.Subtitle {
			font-weight: 400;
		}
		.Title {
			font-weight: 700;
		}
	}
	&__content-title {
		justify-content: center;
	}
	&__content-subtitle {
	}
	&__picture {
		filter: grayscale(100%);
		transition: 0.3s all;
	}
	.Picture {
		&__block-img {
			padding-bottom: 30.4%;
		}
		&__img {
			object-fit: contain;
		}
	}
	&__wrapper {
		overflow: hidden;
		position: relative;

		&:hover > .Card-d__content {
			visibility: visible;
			opacity: 1;
			transform: translateY(0%);
		}
		&:hover > .Card-d__picture {
			filter: unset;
		}
	}
}
