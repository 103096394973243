.process {
	&--b {
		.process {
			&__item {
				&:nth-child(even) {
				}
			}
			&__wrap {
			}
			&__list {
				@include adaptiv-value('margin-top', $offset-10, $offsetMobile-10, 1);
				.item-process {
					grid-template-columns: 1fr;
					@include adaptiv-value('padding-top', $offset-12, $offsetMobile-12, 1);
					@include adaptiv-value('padding-bottom', $offset-12, $offsetMobile-12, 1);
					&__title,
					&__text {
						text-align: left;
						justify-content: flex-start;
						line-height: 1.4;
					}
					&__title {
						@include adaptiv-value('margin-bottom', $offset-4, $offsetMobile-4, 1);
					}
					&__wrap {
						display: grid;
						grid-template-columns: 1fr 1fr;
						grid-template-rows: 470px;
						@include adaptiv-value('gap', $offset-16, $offsetMobile-16, 1);
						align-items: start;
					}

					&:nth-child(odd) {
						.item-process__heading {
							.item-process__title,
							.item-process__text {
								color: var(--color-c);
							}
						}
						background-color: var(--color-d);
						.Card-img {
							order: 1;
						}
					}
					&:nth-child(even) {
						.item-process__heading {
							.item-process__title {
								color: var(--color-a);
							}
						}
					}
				}
				.Card-img {
					height: 100%;
					&__bg-picture {
						height: 100%;
					}
				}
				.Picture {
					&__block-img {
						padding-bottom: unset;
						height: 100%;
					}
				}
			}
		}
	}
	&--a {
		.process {
			&__item {
			}
			&__list {
				@include adaptiv-value('margin-top', $offset-12, $offsetMobile-12, 1);
				display: grid;
				grid-template-columns: 1fr;
				@include adaptiv-value('gap', $offset-6, $offsetMobile-6, 1);
				.item-process {
					display: grid;
					grid-template-columns: 1fr 1fr;
					grid-template-rows: 400px;
					align-items: center;
					&:nth-child(even) {
						.Card-img {
							order: 1;
						}
					}
					@include adaptiv-value('gap', $offset-9, $offsetMobile-9, 1);
					&__text {
					}
					.Card-img {
						height: 100%;
						&__bg-picture {
							height: 100%;
						}
					}
					.Picture {
						&__block-img {
							padding-bottom: unset;
							height: 100%;
						}
					}
				}
			}
			&__title {
			}
			&__wrapper {
				@include adaptiv-value('margin-top', $offset-12, $offsetMobile-12, 1);
				&--container {
				}
				&--max-width {
				}
			}
		}
	}
}
