.Card-img {
	// width: 500px;
	// height: 505px;
	&--process {
		position: relative;
		&::before {
			transition: 0.3s all;
			content: ' ';
			position: absolute;
			width: 100%;
			height: 70%;
			bottom: 0;
			z-index: 1;
			background: linear-gradient(180deg, rgba(0, 0, 0, 0.86) 0%, rgba(0, 0, 0, 0.692708) 30.73%, rgba(0, 0, 0, 0.57) 59.91%, rgba(0, 0, 0, 0) 100%);
			pointer-events: none;
			transform: rotate(-180deg);
		}
		overflow: hidden;
		// max-height: 400px;
		// height: 100%;
		.Card-img {
			&__wrapper {
				height: 100%;
			}
			&__inner {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				@include adaptiv-value("padding-top", $offset-10, $offsetMobile-10, 1);
				@include adaptiv-value("padding-bottom", $offset-10, $offsetMobile-10, 1);
				@include adaptiv-value("padding-left", 42, 16, 1);
				@include adaptiv-value("padding-right", 42, 16, 1);
			}
			&__desc {
				@include adaptiv-value('font-size', $font-size-l, 18, 1);
				color: var(--color-c);
				position: relative;
				z-index: 3;
			}
		}
	}
	&__bg {
		width: 100%;
		height: 100%;
		position: absolute;
		pointer-events: none;
	}
	&__bg-img {
		width: 100%;
		height: 100%;
		object-fit: cover;

		.Img {
			// padding-bottom: 30%;
			&__item {
			}
		}
	}
	&__desc {
		margin-top: 10px;
	}
	&__desc-text {
		&:not(:first-child) {
			margin-top: 10px;
		}
	}
	&__heading {
		position: relative;
		display: flex;
	}
	&__heading-icon {
		margin-right: 10px;
	}
	&__heading-inner {
	}
	&__title {
		font-size: 28px;
		color: var(--color-a);
	}
	&__title-text {
	}
	&__inner {
		padding: 45px 35px;
		height: 100%;
		width: 100%;
	}
	&__wrapper {
		position: relative;

		background: var(--color-c);
	}
}
