.introduction {
	&__item {
		.Picture {
			&__block-img {
				padding-bottom: 100%;
			}
		}
	}
	&__list {
		@include adaptiv-value('margin-top', $offset-8, $offsetMobile-8, 1);
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(315px, 1fr));

		@include adaptiv-value('gap', $offset-6, $offsetMobile-6, 1);
	}
	&__text {
		max-width: 1004px;
	}
	&__wrapper {
		@include adaptiv-value('margin-top', $offset-20, $offsetMobile-20, 1);
		&--container {
		}
		&--max-width {
		}
	}
}
