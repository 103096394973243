.Button {
	$blockName: &;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	// overflow: hidden;
	border: 0;
	padding: 0;
	font-family: inherit;
	font-weight: inherit;
	line-height: inherit;
	text-align: center;
	text-decoration-line: none;
	text-overflow: ellipsis;
	// background-color: #fff;
	transition: $transition-time fill, $transition-time color, $transition-time background-color, $transition-time opacity;
	cursor: pointer;
	user-select: none;
	&__icon {
		display: flex;
		transition: $transition-time fill, $transition-time color, $transition-time background-color, $transition-time opacity;
	}

	&__text {
		text-overflow: ellipsis;
		transition: $transition-time fill, $transition-time color, $transition-time background-color, $transition-time opacity;
	}
	&--decor {
		position: relative;
		&::before {
			content: '';
			position: absolute;
			top: 100%;
			display: block;
			width: 100%;
			height: 1px;
			background: var(--color-a);
			-webkit-transform: scale(0);
			-ms-transform: scale(0);
			transform: scale(0);
			-webkit-transition: 0.3s;
			-o-transition: 0.3s;
			transition: 0.3s;
		}
		&:hover {
			&::before {
				transform: scale(1);
				transition: 0.3s;
			}
		}
	}
	&--arrow {
		$colorAccent: var(--color-b);
		$colorAccentHover: var(--color-a);
		position: relative;
		@include adaptiv-value('width', 140, 70, 1);
		justify-content: flex-end;
		position: relative;
		&::before {
			content: '';
			position: absolute;
			top: 100%;
			display: block;
			width: 100%;
			height: 1px;
			background: var(--color-a);
			-webkit-transform: scale(0);
			-ms-transform: scale(0);
			transform: scale(0);
			-webkit-transition: 0.3s;
			-o-transition: 0.3s;
			transition: 0.3s;
		}
		&:hover {
			&::before {
				transform: scale(1);
				transition: 0.3s;
			}
		}
		.Button {
			&__icon-svg {
				@include adaptiv-value('margin-left', 40, 12, 1);
				width: 15px;
				height: 30px;
			}
		}
		#{ $blockName }__icon #{ $blockName }__icon-svg {
			fill: $colorAccent;
			stroke: $colorAccent;
		}

		&:hover > #{ $blockName }__icon #{ $blockName }__icon-svg {
			fill: $colorAccentHover;
			stroke: $colorAccentHover;
		}
	}
	// !Color
	// ? Accent//

	&--color-a {
		$colorAccent: var(--color-e);
		$colorAccentHover: var(--color-a);

		border: 1px solid $colorAccentHover;
		background-color: $colorAccentHover;
		transition: $transition-time fill, $transition-time color, $transition-time background-color, $transition-time opacity;

		#{$blockName}__icon-svg {
			fill: $colorAccent;
			transition: $transition-time fill, $transition-time color, $transition-time background-color, $transition-time opacity;
		}

		#{ $blockName }__text {
			color: $colorAccent;
		}

		&:hover {
			outline: none;
			box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
			color: $colorAccentHover;
			background: $colorAccent;
		}

		&:hover #{ $blockName }__text {
			color: $colorAccentHover;
		}

		&:hover > #{ $blockName }__icon #{ $blockName }__icon-svg {
			fill: $colorAccentHover;
		}

		&:disabled {
			border-color: #e5e5e5;
			background-color: #f7f6fa;
			cursor: initial;

			#{ $blockName }__text {
				color: #888;
			}
		}
	}
	&--color-b {
		$colorAccent: var(--color-e);
		$colorAccentHover: var(--color-b);

		border: 1px solid $colorAccentHover;
		background-color: $colorAccentHover;
		transition: $transition-time fill, $transition-time color, $transition-time background-color, $transition-time opacity;

		#{$blockName}__icon-svg {
			fill: $colorAccent;
			transition: $transition-time fill, $transition-time color, $transition-time background-color, $transition-time opacity;
		}

		#{ $blockName }__text {
			color: $colorAccent;
		}

		&:hover {
			outline: none;
			box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
			color: $colorAccentHover;
			background: $colorAccent;
		}

		&:hover #{ $blockName }__text {
			color: $colorAccentHover;
		}

		&:hover > #{ $blockName }__icon #{ $blockName }__icon-svg {
			fill: $colorAccentHover;
		}

		&:disabled {
			border-color: #e5e5e5;
			background-color: #f7f6fa;
			cursor: initial;

			#{ $blockName }__text {
				color: #888;
			}
		}
	}
	&--color-c {
		$colorAccent: var(--color-e);
		$colorAccentHover: var(--color-c);

		border: 1px solid $colorAccentHover;
		background-color: $colorAccentHover;
		transition: $transition-time fill, $transition-time color, $transition-time background-color, $transition-time opacity;

		#{$blockName}__icon-svg {
			fill: $colorAccent;
			transition: $transition-time fill, $transition-time color, $transition-time background-color, $transition-time opacity;
		}

		#{ $blockName }__text {
			color: $colorAccent;
		}

		&:hover {
			outline: none;
			box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
			color: $colorAccentHover;
			background: $colorAccent;
		}

		&:hover #{ $blockName }__text {
			color: $colorAccentHover;
		}

		&:hover > #{ $blockName }__icon #{ $blockName }__icon-svg {
			fill: $colorAccentHover;
		}

		&:disabled {
			border-color: #e5e5e5;
			background-color: #f7f6fa;
			cursor: initial;

			#{ $blockName }__text {
				color: #888;
			}
		}
	}
	&--color-d {
		$colorAccent: var(--color-e);
		$colorAccentHover: var(--color-d);

		border: 1px solid $colorAccentHover;
		background-color: $colorAccentHover;
		transition: $transition-time fill, $transition-time color, $transition-time background-color, $transition-time opacity;

		#{$blockName}__icon-svg {
			fill: $colorAccent;
			transition: $transition-time fill, $transition-time color, $transition-time background-color, $transition-time opacity;
		}

		#{ $blockName }__text {
			color: $colorAccent;
		}

		&:hover {
			outline: none;
			box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
			color: $colorAccentHover;
			background: $colorAccent;
		}

		&:hover #{ $blockName }__text {
			color: $colorAccentHover;
		}

		&:hover > #{ $blockName }__icon #{ $blockName }__icon-svg {
			fill: $colorAccentHover;
		}

		&:disabled {
			border-color: #e5e5e5;
			background-color: #f7f6fa;
			cursor: initial;

			#{ $blockName }__text {
				color: #888;
			}
		}
	}
	&--color-e {
		$colorAccent: var(--color-e);
		$colorAccentHover: var(--color-b);

		border: 1px solid $colorAccentHover;
		background-color: $colorAccentHover;
		transition: $transition-time fill, $transition-time color, $transition-time background-color, $transition-time opacity;

		#{$blockName}__icon-svg {
			fill: $colorAccent;
			transition: $transition-time fill, $transition-time color, $transition-time background-color, $transition-time opacity;
		}

		#{ $blockName }__text {
			color: $colorAccent;
		}

		&:hover {
			outline: none;
			box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
			color: $colorAccentHover;
			background: $colorAccent;
		}

		&:hover #{ $blockName }__text {
			color: $colorAccentHover;
		}

		&:hover > #{ $blockName }__icon #{ $blockName }__icon-svg {
			fill: $colorAccentHover;
		}

		&:disabled {
			border-color: #e5e5e5;
			background-color: #f7f6fa;
			cursor: initial;

			#{ $blockName }__text {
				color: #888;
			}
		}
	}
	&--color-f {
		$colorAccent: var(--color-e);
		$colorAccentHover: var(--color-f);

		border: 1px solid $colorAccentHover;
		background-color: $colorAccentHover;
		transition: $transition-time fill, $transition-time color, $transition-time background-color, $transition-time opacity;

		#{$blockName}__icon-svg {
			fill: $colorAccent;
			transition: $transition-time fill, $transition-time color, $transition-time background-color, $transition-time opacity;
		}

		#{ $blockName }__text {
			color: $colorAccent;
		}

		&:hover {
			outline: none;
			box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
			color: $colorAccentHover;
			background: $colorAccent;
		}

		&:hover #{ $blockName }__text {
			color: $colorAccentHover;
		}

		&:hover > #{ $blockName }__icon #{ $blockName }__icon-svg {
			fill: $colorAccentHover;
		}

		&:disabled {
			border-color: #e5e5e5;
			background-color: #f7f6fa;
			cursor: initial;

			#{ $blockName }__text {
				color: #888;
			}
		}
	}
	&--color-g {
		$colorAccent: var(--color-e);
		$colorAccentHover: var(--color-g);

		border: 1px solid $colorAccentHover;
		background: $colorAccentHover;
		transition: $transition-time fill, $transition-time color, $transition-time background-color, $transition-time opacity;

		#{$blockName}__icon-svg {
			fill: $colorAccent;
			transition: $transition-time fill, $transition-time color, $transition-time background-color, $transition-time opacity;
		}

		#{ $blockName }__text {
			color: $colorAccent;
		}

		&:hover {
			outline: none;
			box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
			color: $colorAccentHover;
			background: $colorAccentHover;
			opacity: 0.9;
		}

		&:hover #{ $blockName }__text {
			color: $colorAccentHover;
		}

		&:hover > #{ $blockName }__icon #{ $blockName }__icon-svg {
			fill: $colorAccentHover;
		}

		&:disabled {
			border-color: #e5e5e5;
			background: #f7f6fa;
			cursor: initial;

			#{ $blockName }__text {
				color: #888;
			}
		}
	}
	&--color-clear {
		border: none !important;
		transition: 0.3s all;
		padding: 0 !important;

		.Button {
			&__text {
				color: var(--color-b);
			}
			&__icon {
				fill: var(--color-a);
			}
		}
		&:hover > .Button__text,
		&:hover > .Button__icon {
			opacity: 0.8;
		}
	}
	&--line {
		$colorAccent: var(--color-b);
		$colorAccentHover: #b9b9b9;
		border-radius: 0px;

		border-bottom: 1px solid $colorAccentHover;
		transition: $transition-time fill, $transition-time color, $transition-time background-color, $transition-time opacity;

		#{$blockName}__icon-svg {
			fill: $colorAccent;
			transition: $transition-time fill, $transition-time color, $transition-time background-color, $transition-time opacity;
		}

		#{ $blockName }__text {
			color: $colorAccent;
		}

		&:hover {
			outline: none;
			text-shadow: 0 0 0.45px #000, 0 0 0.45px #000;
			border-bottom: 1px solid var(--color-a);
		}

		&:hover #{ $blockName }__text {
		}

		&:hover > #{ $blockName }__icon #{ $blockName }__icon-svg {
			fill: $colorAccentHover;
		}

		&:disabled {
			border-color: #e5e5e5;
			background-color: #f7f6fa;
			cursor: initial;

			#{ $blockName }__text {
				color: #888;
			}
		}
	}
	// ? Transparent
	&--color-a-t {
		$colorAccent: var(--color-a);
		$colorAccentHover: var(--color-e);

		border: 1px solid $colorAccent;
		background-color: $colorAccentHover;
		transition: $transition-time fill, $transition-time color, $transition-time background-color, $transition-time opacity;

		#{$blockName}__icon-svg {
			fill: $colorAccent;
			transition: $transition-time fill, $transition-time color, $transition-time background-color, $transition-time opacity;
		}

		#{ $blockName }__text {
			color: $colorAccent;
		}

		&:hover {
			outline: none;
			box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
			color: $colorAccentHover;
			background: $colorAccent;
		}

		&:hover #{ $blockName }__text {
			color: $colorAccentHover;
		}

		&:hover > #{ $blockName }__icon #{ $blockName }__icon-svg {
			fill: $colorAccentHover;
		}

		&:disabled {
			border-color: #e5e5e5;
			background-color: #f7f6fa;
			cursor: initial;

			#{ $blockName }__text {
				color: #888;
			}
		}
	}
	// !Size
	&--xs {
		width: $button-size-xs + px;
	}
	&--s {
		width: $button-size-s + px;
	}
	&--m {
		width: $button-size-m + px;
		@include adaptiv-value('width', $button-size-m, $button-size-xs, 1);
		min-height: 64px;
	}
	&--l {
		@include adaptiv-value('width', $button-size-l, 200, 1);
	}
	&--full {
		width: 100%;
	}
}
