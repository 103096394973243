.footer {
	min-height: 350px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: var(--color-d);
	@include adaptiv-value("margin-top", $offset-30, $offsetMobile-30, 1);
	@include adaptiv-value('padding-top', $offset-6, $offsetMobile-6, 1);
	@include adaptiv-value('padding-bottom', $offset-7, $offsetMobile-7, 1);
	&__content {
		display: flex;
		justify-content: space-between;
	}
	&__copyright {
		display: flex;
		justify-content: space-between;
		span {
			color: var(--color-c);
			@include adaptiv-value('font-size', $font-size-s, $font-size-xs, 1);
		}
	}
	&__copyright-left {
		display: flex;
		flex-direction: column;
	}
	&__copyright-right {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}
	&__nav {
		@include adaptiv-value('margin-top', $offset-9, $offsetMobile-9, 1);
	}
	&__info {
		max-width: 167px;
	}
	&__link-wrap {
		@include adaptiv-value('margin-top', $offset-3, $offsetMobile-3, 1);
	}
	&__logo {
		.Icon--xxl .Icon__svg {
			width: 88px;
			height: 88px;
		}
	}
	&__logo-creators {
		@include adaptiv-value('margin-top', $offset-1, $offsetMobile-1, 1);
		display: flex;
		justify-content: flex-end;
	}
	&__wrapper {
		&--container {
		}
		&--max-width {
		}
	}
}
