.Icon {
	$blockName: &;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border: 0;
	padding: 0;
	font-family: inherit;
	font-weight: inherit;
	line-height: inherit;
	text-align: center;
	text-decoration-line: none;
	fill: currentColor;
	background-color: transparent;
	transition: $transition-time;
	user-select: none;

	&__content {
		position: relative;
		display: flex;
		align-items: center;
	}

	&__counter {
		position: absolute;
		right: -10px;
		top: -7px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 100px;
		color: #fff;
		transition: $transition-time;
	}

	&__icon {
		display: flex;
	}

	&__text {
		text-overflow: ellipsis;
		transition: $transition-time;
	}

	&:hover,
	&:focus {
		text-decoration-line: none;
		color: inherit;
	}

	//! Size
	&--xs {
		#{$blockName} {
			&__svg {
				@include adaptiv-value('width', $icon-size-xs, $icon-size-xs, 1);
				@include adaptiv-value('height', $icon-size-xs, $icon-size-xs, 1);
			}
		}
	}
	&--s {
		#{$blockName} {
			&__svg {
				@include adaptiv-value('width', $icon-size-s, $icon-size-s, 1);
				@include adaptiv-value('height', $icon-size-s, $icon-size-s, 1);
			}
		}
	}
	&--m {
		#{$blockName} {
			&__svg {
				@include adaptiv-value('width', $icon-size-m, $icon-size-m, 1);
				@include adaptiv-value('height', $icon-size-m, $icon-size-m, 1);
			}
		}
	}
	&--l {
		#{$blockName} {
			&__svg {
				@include adaptiv-value('width', $icon-size-l, $icon-size-l, 1);
				@include adaptiv-value('height', $icon-size-l, $icon-size-l, 1);
			}
		}
	}
	&--xl {
		#{$blockName} {
			&__svg {
				@include adaptiv-value('width', $icon-size-xl, $icon-size-xl, 1);
				@include adaptiv-value('height', $icon-size-xl, $icon-size-xl, 1);
			}
		}
	}
	&--xl-w {
		#{$blockName} {
			&__svg {
				@include adaptiv-value('width', $icon-size-xl, $icon-size-xl, 1);
				height: 10px;
			}
		}
	}
	&--xxl {
		#{$blockName} {
			&__svg {
				@include adaptiv-value('width', $icon-size-xxl, $icon-size-l, 1);
				@include adaptiv-value('height', $icon-size-xxl, $icon-size-l, 1);
			}
		}
	}
	&--xxxl {
		#{$blockName} {
			&__svg {
				@include adaptiv-value('width', $icon-size-xxxl, $icon-size-m, 1);
				@include adaptiv-value('height', $icon-size-xxxl, $icon-size-m, 1);
			}
		}
	}
	&--xxxl-w {
		#{$blockName} {
			&__svg {
				@include adaptiv-value('width', 300, 80, 1);
				@include adaptiv-value('height', 30, 30, 1);
			}
		}
	}
	&--bxl {
		#{$blockName} {
			&__svg {
				@include adaptiv-value('width', $icon-size-bxl, $icon-size-xxl, 1);
				@include adaptiv-value('height', $icon-size-bxl, $icon-size-xxl, 1);
			}
		}
	}
	&--bxxl {
		#{$blockName} {
			&__svg {
				@include adaptiv-value('width', $icon-size-bxl, $icon-size-xxl, 1);
				@include adaptiv-value('height', 50, 40, 1);
			}
		}
	}
	&--creators {
		#{$blockName} {
			&__svg {
				@include adaptiv-value('width', 152, 124, 1);
				@include adaptiv-value('height', 52, 42, 1);
			}
		}
	}

	//! Color
	&--color-a {
		#{$blockName} {
			$colorAccent: var(--color-a);
			$colorAccentHover: var(--color-e);
			display: none;

			&__svg {
				fill: $colorAccent;
				transition: $transition-time;
			}

			&__counter {
				color: var(--color-e);
				transition: $transition-time;
				background-color: $colorAccent;
			}

			&:hover &__counter,
			&:focus &__counter {
				color: $colorAccentHover;
				transition: $transition-time;
			}

			&:hover > &__content > &__counter {
				background-color: var(--color-a);
			}

			&:hover > &__content > &__svg {
				fill: var(--color-d);
			}
		}
	}
	&--color-b {
		#{$blockName} {
			$colorAccent: var(--color-b);
			$colorAccentHover: var(--color-e);
			display: none;

			&__svg {
				fill: $colorAccent;
				transition: $transition-time;
			}

			&__counter {
				color: var(--color-e);
				transition: $transition-time;
				background-color: $colorAccent;
			}

			&:hover &__counter,
			&:focus &__counter {
				color: $colorAccentHover;
				transition: $transition-time;
			}

			&:hover > &__content > &__counter {
				background-color: var(--color-a);
			}

			&:hover > &__content > &__svg {
				fill: var(--color-d);
			}
		}
	}
	&--color-c {
		#{$blockName} {
			$colorAccent: var(--color-c);
			$colorAccentHover: var(--color-e);
			display: none;

			&__svg {
				fill: $colorAccent;
				transition: $transition-time;
			}

			&__counter {
				color: var(--color-e);
				transition: $transition-time;
				background-color: $colorAccent;
			}

			&:hover &__counter,
			&:focus &__counter {
				color: $colorAccentHover;
				transition: $transition-time;
			}

			&:hover > &__content > &__counter {
				background-color: var(--color-a);
			}

			&:hover > &__content > &__svg {
				fill: var(--color-d);
			}
		}
	}
	&--color-d {
		#{$blockName} {
			$colorAccent: var(--color-d);
			$colorAccentHover: var(--color-e);
			display: none;

			&__svg {
				fill: $colorAccent;
				transition: $transition-time;
			}

			&__counter {
				color: var(--color-e);
				transition: $transition-time;
				background-color: $colorAccent;
			}

			&:hover &__counter,
			&:focus &__counter {
				color: $colorAccentHover;
				transition: $transition-time;
			}

			&:hover > &__content > &__counter {
				background-color: var(--color-a);
			}

			&:hover > &__content > &__svg {
				fill: var(--color-d);
			}
		}
	}
	&--color-e {
		#{$blockName} {
			$colorAccent: var(--color-e);
			$colorAccentHover: var(--color-e);
			display: none;

			&__svg {
				fill: $colorAccent;
				transition: $transition-time;
			}

			&__counter {
				color: var(--color-e);
				transition: $transition-time;
				background-color: $colorAccent;
			}

			&:hover &__counter,
			&:focus &__counter {
				color: $colorAccentHover;
				transition: $transition-time;
			}

			&:hover > &__content > &__counter {
				background-color: var(--color-a);
			}

			&:hover > &__content > &__svg {
				fill: var(--color-d);
			}
		}
	}
	&--color-f {
		#{$blockName} {
			$colorAccent: var(--color-f);
			$colorAccentHover: var(--color-f);
			display: none;

			&__svg {
				fill: $colorAccent;
				transition: $transition-time;
			}

			&__counter {
				color: var(--color-e);
				transition: $transition-time;
				background-color: $colorAccent;
			}

			&:hover &__counter,
			&:focus &__counter {
				color: $colorAccentHover;
				transition: $transition-time;
			}

			&:hover > &__content > &__counter {
				background-color: var(--color-a);
			}

			&:hover > &__content > &__svg {
				fill: var(--color-d);
			}
		}
	}

	//! Animation
	&--animation-scale {
		.Icon {
			&__svg {
				animation-name: scale;
				animation-duration: 1.3s;
				animation-timing-function: linear;
				animation-iteration-count: infinite;
				animation-direction: alternate;
			}
		}

		/* ключевые кадры */
		@keyframes scale {
			0% {
				transform: scale(1);
			}
			50% {
				transform: scale(1.3);
			}
			100% {
				transform: scale(1);
			}
		}
	}
}
