.resume {
	&__button {
		@include adaptiv-value('margin-top', $offset-11, $offsetMobile-11, 1);
	}
	&__important {
		@include adaptiv-value('margin-top', $offset-8, $offsetMobile-8, 1);
		font-weight: 500;
		color: var(--color-a);
		@include adaptiv-value("font-size", 24, 16, 1);
	}
	&__requirements,
	&__responsibilities {
		flex: 1 1;
		.Title {
			justify-content: flex-start;
		}
	}
	&__requirements {
		@include width-percent(515);
		border-right: 1px solid #ebebeb;
		@include adaptiv-value('padding-right', $offset-10, $offsetMobile-10, 1);
		@include adaptiv-value('margin-right', $offset-10, $offsetMobile-10, 1);
	}
	&__responsibilities {
		@include width-percent(545);
	}
	&__requirements-item {
		&:not(:first-child) {
			@include adaptiv-value('margin-top', $offset-4, $offsetMobile-4, 1);
		}
	}
	&__requirements-item-text {
	}
	&__requirements-list {
		@include adaptiv-value('margin-top', $offset-4, $offsetMobile-4, 1);
	}
	&__requirements-title {
	}
	&__responsibilities {
	}
	&__responsibilities-item,
	&__requirements-item {
		@include adaptiv-value('margin-top', $offset-4, $offsetMobile-4, 1);
		position: relative;
		@include adaptiv-value('padding-left', $offset-2, $offsetMobile-2, 1);
		&::before {
			content: '-';
			position: absolute;
			left: 0;
		}
	}
	&__responsibilities-item-text {
	}
	&__responsibilities-list {
		@include adaptiv-value('margin-top', $offset-4, $offsetMobile-4, 1);
	}
	&__responsibilities-title {
	}
	&__wrapper {
		display: flex;
		@include adaptiv-value('margin-top', $offset-7, $offsetMobile-7, 1);
	}
}
