.advantages {
	background-color: var(--color-d);
	&__content {
	}
	&__button {
		display: flex;
		justify-content: center;
		@include adaptiv-value('margin-top', $offset-15, $offsetMobile-15, 1);
		.Button {
			&--color-clear {
				.Button {
					&__text {
						color: var(--color-c);
						@include adaptiv-value("max-width", 144, 124, 1);
					}
				}
			}
		}
	}
	&__contacts {
		display: flex;
		justify-content: center;
	}
	&__wrapper {
		@include adaptiv-value('padding-top', $offset-13, $offsetMobile-13, 1);
		@include adaptiv-value('padding-bottom', $offset-9, $offsetMobile-9, 1);
		@include adaptiv-value('margin-top', $offset-23, $offsetMobile-23, 1);
		.Title {
			justify-content: flex-start;
		}
	}
	.dropdown {
		@include adaptiv-value('margin-top', $offset-5, $offsetMobile-5, 1);
	}
}
