.contacts {
	&__info-city {
		font-weight: 700;
		.Title {
			font-style: normal;
			font-weight: 700;
			font-size: 26.851px;
			line-height: 115.8%;
			text-transform: unset;
			letter-spacing: 0.1em;
		}
	}
	&__info-city-time {
		@include adaptiv-value('margin-top', $offset-2, $offsetMobile-2, 1);
	}
	&__info-city-time-text {
		font-weight: 700;
	}
	&__info-city-title {
	}
	&__top {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		@include adaptiv-value('margin-top', $offset-5, $offsetMobile-5, 1);
	}
	&__top-left {
		flex: 1 1 45%;
		@include adaptiv-value('margin-right', $offset-2, $offsetMobile-2, 1);
		display: flex;
		flex-direction: column;
	}
	&__top-right {
		flex: 1 1 45%;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}
	&__feedback-mail {
		font-weight: 700;
	}
	&__feedback-phone {
		font-weight: 700;
	}
	&__social-networks {
		margin-top: auto;
	}
	&__main {
		@include adaptiv-value('margin-top', $offset-4, $offsetMobile-4, 1);
		display: flex;
	}
	&__main-actions {
		flex: 1 1 20%;
		@include adaptiv-value('margin-right', $offset-9, $offsetMobile-9, 1);
		display: flex;
		flex-direction: column;
		.Button {
			&:last-child {
				margin-top: auto;
			}
		}
	}
	&__main-map {
		@include adaptiv-value('height', 450, 200, 1);
		flex: 1 1 60%;
	}
	&__main-map-item {
	}
	&__main-actions-text {
		@include adaptiv-value('margin-top', $offset-4, $offsetMobile-4, 1);
	}
	&__wrapper {
		&--container {
		}
		&--max-width {
		}
	}
	.fancybox-is-open .fancybox-bg {
		opacity: 0;
	}
}
#contacts-page {
	& ~ .fancybox-is-open .fancybox-bg {
		opacity: 0;
	}
}
