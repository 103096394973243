.administration {
	background-color: var(--color-d);

	&__item {
	}
	&__list {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(430px, 1fr));
		justify-content: center;
		@include adaptiv-value('gap', 260, 130, 1);
		.item-administration {
			&__fio {
				@include adaptiv-value('font-size', 20, 16, 1);
				color: var(--color-c);
			}
			&__heading {
				@include adaptiv-value('margin-top', $offset-6, $offsetMobile-6, 1);
				@include adaptiv-value('margin-bottom', $offset-12, $offsetMobile-12, 1);
			}
			&__img {
				.Picture {
					&__block-img {
						padding-bottom: 100%;
					}
				}
			}
			&__text {
				@include adaptiv-value('margin-top', 12, 10, 1);
			}
			&__title {
				color: var(--color-c);
				@include adaptiv-value('font-size', 24, 20, 1);
			}
		}
	}
	&__text {
	}
	&__wrapper {
		@include adaptiv-value('margin-top', $offset-12, $offsetMobile-12, 1);
		@include adaptiv-value('padding-top', $offset-13, $offsetMobile-13, 1);
		@include adaptiv-value('padding-bottom', $offset-13, $offsetMobile-13, 1);
		&--container {
		}
		&--max-width {
		}
	}
}
