.Input {
  position: relative;
  display: inline-block;

  &:not(:first-child) {
    margin-top: 10px;
  }

  &__label {
    display: flex;
    align-items: center;
    text-align: end;
    color: #000;
    cursor: pointer;
  }

	.input-validation__message  {
		font-size: 12px;
	}
  &__inp {
    @include adaptiv-value("width", 297, 220, 1);
    @include adaptiv-value("min-height", 36, 30, 1);
		border-radius: 5px;

    position: relative;
    outline: none;
    border: 1px solid transparent;
		border: 1px solid var(--color-h);
    padding: 7px;
    font-size: 16px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.44);
    background: var(--color-e);

    &::placeholder {
      font-size: 14px;
      line-height: 19px;
      color: rgba(0, 0, 0, 0.44);
    }

    &:focus::placeholder {
      opacity: 0;
      transform: $transition-time;
    }
  }

  &__placeholder {
    @include adaptiv-font(17.6, 16);

    position: absolute;
    left: 22%;
    top: 50%;
    line-height: 21px;
    color: #bdc0cf;
    transform: translateY(-50%);
    transition: 0.3s all;
    user-select: none;
  }

  &__placeholder--star-active {
    @include adaptiv-font(12.6, 9);

    top: -6px;
    color: rgba(0, 0, 0, 0.44);
  }

  &__inp:required + &__placeholder--star::after {
    content: "*";
    color: var(--color-a);
  }
	&__label-text {
		@include adaptiv-value("font-size", 18, 14, 1);
	}

}

.fio {
  // margin: 10px;
  display: block;
}
