.header {
	position: fixed;
	z-index: 500;
	background-color: #fff;
	left: 0;
	right: 0;
	top: 0;
	background-color: transparent;
	border-width: 1px 0px 1px 0;
	border-style: solid;
	border-color: rgba(255, 255, 255, 0.25);
	transition: background-color 0.3s, backdrop-filter 0.3s;
	// border: 1px solid ;
	&.open {
		background: var(--color-d);
		padding-right: 10px;
		.Hamb {
			&__bar {
				background-color: var(--color-c);
			}
		}
		.header {
			&__logo {
				opacity: 0;
				max-height: 0;
				pointer-events: none;
			}
			&__logo-open {
				opacity: 1;
				max-height: 100px;
				pointer-events: auto;
				animation: openLogo 1s forwards;
			}
		}
	}
	&:not(.open) {
		.header {
			&__logo-open {
				opacity: 0;
				max-height: 0;
				pointer-events: none;
			}
			&__logo {
				// transform: translateY(0);
				opacity: 1;

				animation: defLogo 1s forwards;

				pointer-events: auto;
			}
		}
	}

	&--main {
		.header {
			&__nav {
				display: none;
			}
			&__wrapper {
				padding-bottom: 0;
				align-items: center;
			}
		}

		.item-nav {
			&:hover {
				.Nav-link {
					opacity: 1;
					visibility: visible;
				}
			}
		}

		.Nav-link {
			opacity: 0;
			visibility: hidden;
		}

		&-active {
			background-color: var(--color-e);
			box-shadow: 0 5px 15px rgba(143, 150, 176, 0.3);

			.header {
				&__wrapper {
				}
			}
		}
	}

	&--active {
		background-color: var(--color-e);
		box-shadow: 0 5px 15px rgba(143, 150, 176, 0.3);

		.header {
			&__wrapper {
				transition: 0.3s padding;

				// padding-top: 10px;
				// padding-bottom: 0;
			}
		}
	}
	&__btn {
		display: flex;
		justify-content: flex-end;
		& > *:not(:last-child) {
			@include adaptiv-value('margin-right', $offset-4, $offsetMobile-4, 1);
		}
		.Link--color-c-decor:hover::before {
			top: 54px;
		}
	}
	&__action {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	&__logo {
		cursor: pointer;
	}
	&__logo-open {
		opacity: 0;
		max-height: 0;
		pointer-events: none;
	}

	&__logo-picture {
		@include adaptiv-value('width', 85, 85, 1);

		.Picture {
			&__block-img {
				padding-bottom: 71%;
			}
		}
	}

	&__navigation {
		display: flex;
		align-items: center;
		height: 100%;
	}

	&__nav-link {
		@include adaptiv-value('margin-top', $offset-2, $offsetMobile-2, 1);
	}

	&__wrapper {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		min-height: 80px;
		align-items: center;
	}

	@keyframes openLogo {
		0% {
			transform: rotateY(90deg);
		}
		100% {
			transform: rotateY(0deg);
			opacity: 1;
		}
	}
	@keyframes defLogo {
		0% {
			transform: rotateY(90deg);
		}

		100% {
			transform: rotateY(0deg);
		}
	}
}
[header-dec] {
	transition: background-color 0.3s, backdrop-filter 0.3s;
	background-color:rgba(0, 0, 0, 0.7)
}
