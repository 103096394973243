.projects {
	@include adaptiv-value('margin-top', $offset-24, $offsetMobile-24, 1);
	&__button {
		@include adaptiv-value('margin-top', $offset-4, $offsetMobile-4, 1);
	}
	&__left {
	}
	&__right {
		max-width: 645px;
		width: 100%;
		align-self: flex-end;
	}
	&__right-text {
	}
	&__right-subtitle {
		@include adaptiv-value('margin-bottom', $offset-4, $offsetMobile-4, 1);
	}
	&__title {
		@include adaptiv-value('margin-top', $offset-16, $offsetMobile-16, 1);
	}
	&__wrapper {
		display: flex;
		justify-content: space-between;
		min-height: 820px;

		&--container {
		}
		&--max-width {
		}
	}
}
