//! Gilroy
@font-face {
	src: url('../fonts/Gilroy/Gilroy-Bold.woff2') format('woff2'),
		url('../fonts/Gilroy/Gilroy-Bold.ttf') format('ttf'),
		url('../fonts/Gilroy/Gilroy-Bold.woff') format('woff');
	font-family: 'Gilroy';
	font-weight: 700;
	font-style: bold;
}

@font-face {
	src: url('../fonts/Gilroy/Gilroy-Medium.woff2') format('woff2'),
		url('../fonts/Gilroy/Gilroy-Medium.ttf') format('ttf'),
		url('../fonts/Gilroy/Gilroy-Medium.woff') format('woff');
	font-family: 'Gilroy';
	font-weight: 500;
	font-style: medium;
}

@font-face {
	src: url('../fonts/Gilroy/Gilroy-ExtraBold.woff2') format('woff2'),
		url('../fonts/Gilroy/Gilroy-ExtraBold.ttf') format('ttf'),
		url('../fonts/Gilroy/Gilroy-ExtraBold.woff') format('woff');
	font-family: 'Gilroy';
	font-weight: 800;
	font-style: extrabold;
}

@font-face {
	src: url('../fonts/Gilroy/Gilroy-Light.woff2') format('woff2'),
		url('../fonts/Gilroy/Gilroy-Light.ttf') format('ttf'),
		url('../fonts/Gilroy/Gilroy-Light.woff') format('woff');
	font-family: 'Gilroy';
	font-weight: 300;
	font-style: light;
}

@font-face {
	src: url('../fonts/Gilroy/Gilroy-Regular.woff2') format('woff2'),
		url('../fonts/Gilroy/Gilroy-Regular.ttf') format('ttf'),
		url('../fonts/Gilroy/Gilroy-Regular.woff') format('woff');
	font-family: 'Gilroy';
	font-weight: 300;
	font-style: regular;
}

@font-face {
	src: url('../fonts/Gilroy/Gilroy-SemiBold.woff2') format('woff2'),
		url('../fonts/Gilroy/Gilroy-SemiBold.ttf') format('ttf'),
		url('../fonts/Gilroy/Gilroy-SemiBold.woff') format('woff');
	font-family: 'Gilroy';
	font-weight: 600;
	font-style: semibold;
}
//! Roboto
@font-face {
	src: url('../fonts/Roboto/Roboto-Regular.woff2') format('woff2'),
		url('../fonts/Roboto/Roboto-Regular.ttf') format('ttf'),
		url('../fonts/Roboto/Roboto-Regular.woff') format('woff');
	font-family: 'Roboto';
	font-weight: 400;
	font-style: regular;
}
//! PFBeauSansPro
@font-face {
	src: url('../fonts/PFBeauSansPro/PFBeauSansPro-Regular.woff2') format('woff2'),
		url('../fonts/PFBeauSansPro/PFBeauSansPro-Regular.ttf') format('ttf'),
		url('../fonts/PFBeauSansPro/PFBeauSansPro-Regular.woff') format('woff');
	font-family: 'PFBeauSansPro';
	font-weight: 400;
	font-style: regular;
}

@font-face {
	src: url('../fonts/PFBeauSansPro/PFBeauSansPro-SemiBold.woff2')
			format('woff2'),
		url('../fonts/PFBeauSansPro/PFBeauSansPro-SemiBold.ttf') format('ttf'),
		url('../fonts/PFBeauSansPro/PFBeauSansPro-SemiBold.woff') format('woff');
	font-family: 'PFBeauSansPro';
	font-weight: 400;
	font-style: semibold;
}
//! PTSansNarrow
@font-face {
	src: url('../fonts/PTSansNarrow/PTSansNarrow.woff2') format('woff2'),
		url('../fonts/PTSansNarrow/PTSansNarrow.ttf') format('ttf'),
		url('../fonts/PTSansNarrow/PTSansNarrow.woff') format('woff');
	font-family: 'PTSansNarrow';
	font-weight: 400;
	font-style: regular;
}

@font-face {
	src: url('../fonts/PTSansNarrow/PTSansNarrowBold.woff2') format('woff2'),
		url('../fonts/PTSansNarrow/PTSansNarrowBold.ttf') format('ttf'),
		url('../fonts/PTSansNarrow/PTSansNarrowBold.woff') format('woff');
	font-family: 'PTSansNarrow';
	font-weight: 700;
	font-style: bold;
}
//! HelveticaNeueCyr
@font-face {
	src: url('../fonts/HelveticaNeueCyr/HelveticaNeueCyr-Bold.woff2')
			format('woff2'),
		url('../fonts/HelveticaNeueCyr/HelveticaNeueCyr-Bold.ttf') format('ttf');
	font-family: 'HelveticaNeueCyr';
	font-weight: 700;
	font-style: bold;
}

@font-face {
	src: url('../fonts/HelveticaNeueCyr/HelveticaNeueCyr-Medium.woff2')
			format('woff2'),
		url('../fonts/HelveticaNeueCyr/HelveticaNeueCyr-Medium.ttf') format('ttf');
	font-family: 'HelveticaNeueCyr';
	font-weight: 500;
	font-style: medium;
}
@font-face {
	src: url('../fonts/HelveticaNeueCyr/HelveticaNeueCyr-Regular.woff2')
			format('woff2'),
		url('../fonts/HelveticaNeueCyr/HelveticaNeueCyr-Regular.ttf') format('ttf');
	font-family: 'HelveticaNeueCyr';
	font-weight: 400;
	font-style: regular;
}

//! Jost
@font-face {
	src: url('../fonts/Jost/Jost-SemiBold.woff2') format('woff2'),
		url('../fonts/Jost/Jost-SemiBold.ttf') format('ttf');
	font-family: 'Jost';
	font-weight: 600;
	font-style: 'semi-bold';
}

@font-face {
	src: url('../fonts/Jost/Jost-Medium.woff2') format('woff2'),
		url('../fonts/Jost/Jost-Medium.ttf') format('ttf');
	font-family: 'Jost';
	font-weight: 500;
	font-style: medium;
}
@font-face {
	src: url('../fonts/Jost/Jost-Regular.woff2') format('woff2'),
		url('../fonts/Jost/Jost-Regular.ttf') format('ttf');
	font-family: 'Jost';
	font-weight: 400;
	font-style: regular;
}
