html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  vertical-align: baseline;
  margin: 0;
  border: 0;
  padding: 0;
  font-weight: inherit;
  font-size: inherit;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html {
  height: 100%;
}

body {
  line-height: 1;

}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
}

// input[type="time"]::-webkit-datetime-edit,
// ::-webkit-datetime-edit-fields-wrapper,
// ::-webkit-datetime-edit-text,
// ::-webkit-datetime-edit-month-field,
// ::-webkit-datetime-edit-day-field,
// ::-webkit-datetime-edit-year-field,
// ::-webkit-inner-spin-button,
//  {
//   display: none;
// }

input[type='time'] ::-webkit-calendar-picker-indicator,
::-webkit-datetime-edit-fields-wrapper,
::-webkit-inner-spin-button {
  padding-left: 100%;
}
