.privacy-policy {
	* {
		@include adaptiv-value("font-size", $font-size-m, $font-size-xs, 1);
		@include adaptiv-value("line-height", 28, 20, 1);
	}

	@include adaptiv-value("margin-top", $offset-20, $offsetMobile-10, 1);

	// @include adaptiv-value("margin-bottom", $offset-20, $offsetMobile-10, 1);
	&__text {
		@include adaptiv-value("margin-top", $offset-5, $offsetMobile-5, 1);

		strong {
			font-weight: 700;
		}
	}

	&__title {}

	&__wrapper {
		&--container {}

		&--max-width {}
	}

	&__list {
		list-style-type: decimal;
		@include adaptiv-value("margin-left", $offset-6, $offsetMobile-8, 1);
	}
}