:root {
	--swiper-theme-color: var(--color-a);
}
.swiper-container-horizontal > .swiper-pagination-bullets {
	// width: 130px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	@include adaptiv-value('margin-top', $offset-2, $offsetMobile-2, 1);
}
.swiper-pagination {
	text-align: center;
}
.swiper-container-horizontal > .swiper-scrollbar {
	height: 4px;
	width: 305px;

	left: 50%;
	transform: translateX(-50%);

	// bottom: 45px;
}
.swiper-scrollbar {
	border-radius: unset;
}
.swiper-scrollbar-drag {
	border-radius: unset;
	background: var(--color-a);
}
.swiper-button-next {
	left: auto;
	@include adaptiv-value('right', 40, 5, 1);
}
.swiper-button-prev {
	right: auto;
	@include adaptiv-value('left', 40, 5, 1);
}
.swiper-button-prev::after,
.swiper-button-next::after {
	@include adaptiv-value('font-size', 50, 25, 1);
}
