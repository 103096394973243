.popup-write-us {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 501;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100vw;
	height: 100vh;
	padding: 0;
	background: var(--color-d);
	transition: opacity 1s, transform 1s;

	&__wrapper {
		position: relative;
		@include adaptiv-value('padding-top', 60, 40, 1);
		@include adaptiv-value('padding-bottom', 60, 40, 1);
		@include adaptiv-value('padding-left', 40, 20, 1);
		@include adaptiv-value('padding-right', 40, 20, 1);
		border-radius: 5px;
		max-width: 500px;
	}

	&--disable {
		opacity: 0;
		pointer-events: none;
		transform: translateY(-100%);
	}

	&--enable {
		opacity: 1;
		transform: translateY(0);
	}
	&--contacts {
		position: static;
		width: 100%;
		height: 100%;
		z-index: 1;
		background-color: transparent;

		.popup-write-us {
			&__download {
				color: var(--color-b);
			}
			&__btns {
				justify-content: flex-start;
				width: 100%;
				align-items: flex-start;


				.Button--full {
					width: max-content;
					border-color: var(--color-b);
					border-style: solid;
					padding: 10px;
					transition: 0.3s border-color;
					@media(max-width: 733px) {
						width: 100%;
					}
					&:hover {
						border-color: var(--color-a);
						border-style: solid;
					}
				}
				.Button--full > .Button__text {
					color: var(--color-b);
				}
			}
			&__wrapper {
				padding: 0;
				max-width: 100%;
				border-radius: unset;
			}
		}
		.Input {
			&__inp {
				background-color: transparent !important;
				color: var(--color-b) !important;
			}
			&__label {
				border-bottom: 1px solid var(--color-a);
			}
			&__label-text {
				color: var(--color-b) !important;
			}
		}
		.Action-button__content * {
			color: var(--color-b) !important;
		}
	}
	.Button__icon-svg {
		&--close {
			cursor: pointer;
			position: absolute;
			top: 20px;
			@include adaptiv-value('width', 40, 20, 1);
			@include adaptiv-value('height', 40, 20, 1);
			@include adaptiv-value('right', -150, 20, 1);
		}
	}

	&__block-input-form {
		// @include adaptiv-value("height", 423, 372, 1);
	}

	&__block-inputs {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		width: 100%;
	}

	&__bg-img {
		position: relative;

		.Picture {
			width: 150px;
			height: 100px;
			position: absolute;
			bottom: -30px;
			left: -20px;
			pointer-events: none;

			&__block-img {
				padding-bottom: 65%;
			}
		}
	}

	&__btns {
		@include adaptiv-value('margin-top', $offset-4, $offsetMobile-4, 1);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.Button--full {
			width: 200px;
			background: none;
			transition: border 0.3s;
			border-width: 1px;
			border-style: solid;
			border-color: var(--color-d);

			& > .Button {
				&__text {
					color: var(--color-c);
				}
			}

			&:hover {
				border-color: var(--color-c);
				border-radius: 5px;
			}
		}
	}

	&__status {
		margin-top: 10px;
	}

	&__form {
		display: flex;
		flex-direction: column;
		align-items: center;
		@include adaptiv-value('margin-top', $offset-3, $offsetMobile-3, 1);
	}

	&__subtitle {
		@include adaptiv-value('font-size', 24, 16, 1);
		text-align: center;
		font-weight: 400;
		@include adaptiv-value('margin-top', $offset-1, $offsetMobile-1, 1);

		&-text {
			display: flex;
			position: relative;
			align-items: center;
			justify-content: center;
			width: 100%;

			&::after,
			&::before {
				width: 10%;
				content: '';
				height: 1px;
				display: block;
				background: var(--color-b);
				margin: 0 10px;
			}
		}
	}

	.Action-button {
		&__content {
			& * {
				color: var(--color-c);
			}
		}
	}

	&__heading {
	}

	&__title {
		@include adaptiv-value('font-size', 30, 20, 1);
		font-weight: 700;
		text-align: center;

		* {
			color: var(--color-c);
		}
	}

	.Action-button {
		margin-top: 20px;
	}

	.Button {
		// @include adaptiv-value('margin-top', 50, 40, 1);

		&__text {
		}

		&--blue {
		}

		&--theme-s {
		}
	}

	.Input {
		width: 100%;
		position: relative;

		&__inp {
			width: 100%;
			background-color: var(--color-d);
			max-height: 1px;

			min-height: 1px;
			padding: 0;
			display: flex;
			flex-shrink: 0;
			transition: max-height 0.5s, min-height 0.5s;
			color: var(--color-c);
			border: unset;
			border-radius: 0;
			border-bottom: 2px solid var(--color-c);

			&--file {
				border: 2px solid var(--color-a);
				max-width: 58px;
				pointer-events: none;
			}

			&--open {
				max-height: 40px;
			}
		}

		&__label {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			margin-bottom: 20px;
		}

		&__label-wrapper {
			display: flex;
		}

		[for='file'] {
			width: fit-content;
		}

		&__label-file {
			position: absolute;
			z-index: 510;
			display: flex;
			justify-content: center;
			align-items: center;
			left: 40%;
			top: 0;
			transform: translateY(-25%);
			border-bottom: 2px solid var(--color-c);
			margin-left: 10px;
			width: 40px;
			height: 40px;
			transition: opacity 0.3s;

			& > .Button__icon-svg {
				margin: 0;

				&--delete {
					position: absolute;
					top: 0;
					right: 0;
					width: 6px;
					height: 6px;
				}
			}
		}

		&__label-text {
			@include adaptiv-value('font-size', 16, 14, 1);
			font-weight: 400;
			margin-bottom: 10px;
			color: var(--color-c);
		}
	}

	.Title {
		font-weight: 600;
	}

	.checkbox {
		@include adaptiv-value('margin-top', 20, 35, 1);
	}

	.input-validation {
		&__message {
		}

		&__message-mod {
		}
	}

	.Action-button input:not(:checked) + .Action-button__content::before {
		border-color: var(--color-c);
		background-color: var(--color-d);
	}

	.Action-button input[type='checkbox'] + .Action-button__content::before {
		border: 1px solid var(--color-c);
	}

	&__status {
		&--error {
			color: var(--color-a);
		}

		&--success {
			color: var(--color-c);
		}
	}

	&__download {
		color: var(--color-c);
	}

	&__link {
		color: var(--color-f);
		position: relative;
		font-weight: 600;

		&::before {
			content: '';
			transition: max-width 0.3s, background-color 1s;
			position: absolute;
			width: 100%;
			max-width: 1px;
			height: 2px;
			bottom: 0;
			background-color: transparent;
		}

		&:hover {
			color: var(--color-f);

			&::before {
				content: '';
				transition: max-width 0.3s;
				position: absolute;
				width: 100%;
				max-width: 100px;
				height: 2px;
				bottom: 0;
				background-color: var(--color-a);
			}
		}
	}

	&__pp {
		text-decoration: none !important;

		&:hover {
			color: var(--color-f) !important;
		}
	}

	.invalid {
		border-color: red !important;
	}
}
