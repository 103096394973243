.partners {
	&__item {
		width: 100%;
		height: 100%;
	}
	&__list {
		@include adaptiv-value('margin-top', $offset-13, $offsetMobile-13, 1);

		&--grid-g {
			@include adaptiv-value('gap', $offset-6, $offsetMobile-6, 1);

		}
	}
	&__title {
	}
	&__wrapper {
		@include adaptiv-value('margin-top', $offset-24, $offsetMobile-24, 1);
	}
}
