.Text {
	$blockName: &;
	//! Общие стили для всех кнопок (для каждого проекта свои стили)
	font-weight: 400;
	// 
	&--dec {
		&::before {
			content: '/';
			font-size: 60px;
			font-weight: 900;
		}
	}

	// !Color
	&--color-a {
		color: var(--color-a);
	}
	&--color-b {
		color: var(--color-b);
	}
	&--color-c {
		color: var(--color-c);
	}
	&--color-d {
		color: var(--color-d);
	}
	&--color-e {
		color: var(--color-e);
	}
	&--color-f {
		color: var(--color-f);
	}
	// !Size
	&--xs {
		font-size: $font-size-xs + px;
	}
	&--s {
		font-size: $font-size-s + px;
	}
	&--m {
		@include adaptiv-value('font-size', $font-size-m, $font-size-s, 1);
	}
	&--l {
		@include adaptiv-value('font-size', $font-size-l, $font-size-xs, 1);
	}
	&--xl {
		@include adaptiv-value('font-size', $font-size-xl, $font-size-m, 1);
	}
	&--xxl {
		@include adaptiv-value('font-size', $font-size-xxl, $font-size-m, 1);
	}
	&--xxxl {
		@include adaptiv-value('font-size', $font-size-xxxl, $font-size-l, 1);
	}
	&--bxl {
		@include adaptiv-value('font-size', $font-size-bxl, $font-size-l, 1);
	}
}
