.section-advantages {
	@include adaptiv-value('margin-top', 130, 40, 1);
	&__wrapper {
		padding-bottom: 40px;
	}
	&__button {
		justify-content: center;
		display: flex;
		align-items: center;
		@include adaptiv-value("margin-top", $offset-6, $offsetMobile-6, 1);
	}
	%transition_all_03s {
		transition: all 0.3s ease;
	}
	%backface_visibility_hidden {
		backface-visibility: hidden;
		-webkit-backface-visibility: hidden;
	}
	&__swiper-container {
		margin-top: 40px;
		max-width: 900px;
	}
	&__text {
		max-width: 1033px;
		margin: 0 auto;
		text-align: center;
	}
	.swiper-container {
		width: 100%;
		// height: 28vw;
		height: 100%;
		transition: opacity 0.6s ease;
		&.swiper-container-coverflow {
			@include adaptiv-value('padding-top', $offset-8, $offsetMobile-8, 1);
		}
		&.loading {
			opacity: 0;
			visibility: hidden;
		}
		&:hover {
			.swiper-button-prev,
			.swiper-button-next {
				transform: translateX(0);
				opacity: 1;
				visibility: visible;
			}
		}
	}
	.swiper-wrapper {
	}
	.swiper-slide {
		background-position: center;
		background-size: cover;
		.slide-inner {
		}
		.entity-img {
			display: none;
		}
		.content {
			position: absolute;
			top: 40%;
			left: 0;
			width: 50%;
			padding-left: 5%;
			color: #fff;
			.title {
				font-size: 2.6em;
				font-weight: bold;
				margin-bottom: 30px;
			}
			.caption {
				display: block;
				font-size: 13px;
				line-height: 1.4;
			}
		}
	}
	[class^='swiper-button-'] {
		width: 44px;
		opacity: 0;
		visibility: hidden;
		@extend %transition_all_03s;
	}
	.swiper-button-prev {
		transform: translateX(50px);
		color: var(--color-a);
	}
	.swiper-button-next {
		transform: translateX(-50px);
		color: var(--color-a);
	}
	.swiper-container-horizontal {
		> .swiper-pagination-bullets {
			.swiper-pagination-bullet {
				margin: 0 9px;
				position: relative;
				width: 12px;
				height: 12px;
				background-color: var(--color-a);
				opacity: 0.4;
				@extend %transition_all_03s;
				&::before {
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					width: 18px;
					height: 18px;
					transform: translate(-50%, -50%);
					border: 0px solid #fff;
					border-radius: 50%;
					@extend %transition_all_03s;
				}
				&:hover,
				&.swiper-pagination-bullet-active {
					opacity: 1;
				}
				&.swiper-pagination-bullet-active {
					&::before {
						border-width: 1px;
					}
				}
			}
		}
	}

	// Mediaqueries
	@media (max-width: 1180px) {
		.swiper-slide {
			.content {
				.title {
					font-size: 25px;
				}
				.caption {
					font-size: 12px;
				}
			}
		}
	}
	@media (max-width: 1023px) {
		.swiper-container {
			&.swiper-container-coverflow {
				padding-top: 0;
			}
		}
	}

	.Title {
		@include adaptiv-value('margin-bottom', 25, 20, 1);
	}
}
.section-advantages {
	&__wrapper {
		// backdrop-filter: brightness(0.5);
		// width: 386px;
	}
	&-swiper-slide {
		@include adaptiv-value('height', 480, 230, 1);

		position: relative;
	}
	.section-advantages-swiper-slide::before {
		transition: 0.3s all;
		content: ' ';
		position: absolute;
		width: 100%;
		height: 70%;
		bottom: 0;
		z-index: 1;
		background: linear-gradient(
			180deg,
			rgba(0, 0, 0, 0.86) 0%,
			rgba(0, 0, 0, 0.692708) 30.73%,
			rgba(0, 0, 0, 0.57) 59.91%,
			rgba(0, 0, 0, 0) 100%
		);
		pointer-events: none;
		transform: rotate(-180deg);
	}

	.slide-inner {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		background-size: cover;
		background-position: center;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: left;
	}
	.slide-bg-image {
		& .container {
		}
	}
}
.section-advantages-slider {
	&__wrapper {
		height: 100%;
		@include adaptiv-value('padding-top', 100, 30, 1);
		@include adaptiv-value('padding-bottom', 100, 30, 1);
		@include adaptiv-value('padding-left', 40, 15, 1);
		@include adaptiv-value('padding-right', 40, 15, 1);
		padding: 100px 40px 100px 40px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}
	&__container {
		padding: 0;
		position: relative;
		min-width: 100% !important;
	}
	&__title {
		font-weight: 500;
		@include adaptiv-value('font-size', 28, 12, 1);
		line-height: 125%;
		color: #ffffff;
		z-index: 3;
		position: relative;
		// display: block;
		// position: absolute;
	}
	&__title {
		transition: 0.3s all;
	}
}

.section-advantages-swiper-slide {
	&.swiper-slide {
		&-prev,
		&-next {
			&::before {
				content: '';
				position: absolute !important;
				width: 100% !important;
				height: 100% !important;
				bottom: 0 !important;
				z-index: 1 !important;
				background: #ffffff !important;
				opacity: 0.7 !important;
			}
		}
	}
	&:not(.swiper-slide-active) {
		&::before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			bottom: 0;
			z-index: 1;
			background: #d9d9d9;
			opacity: 0.5;
		}
	}
}
