.Card-c {
	// width: 100%;
	a {
		display: block;
	}
	transition: 0.3s all;
	height: 100%;
	display: grid;
	align-items: center;
	justify-items: center;
	grid-template-areas: 'main';

	overflow: hidden;
	cursor: pointer;
	text-decoration: none;
	& > * {
		grid-area: main;
	}
	& > .Card-c__img {
		width: 100%;
		// aspect-ratio: 1 / 0.58;
		object-fit: cover;
		z-index: -1;
		position: relative;

		transition: 0.3s all;
	}
	& > .Card-c__title {
		opacity: 0;
		@include adaptiv-value('font-size', $font-size-xxl, $font-size-m, 1);
		transition: opacity 400ms, transform 400ms;
		text-align: center;
		z-index: -1;
		position: relative;
	}
	& > .Card-c__text {
		margin-top: auto;
		text-align: left;
		margin-bottom: 20px;
		transition: opacity 400ms, transform 400ms;
		@include adaptiv-value('font-size', $font-size-l, $font-size-xs, 1);
		// text-align: justify;
		opacity: 0;
		z-index: -1;
		position: relative;
	}
	&:is(:hover, :focus) > .Card-c__img {
		transform: scale(1);
		filter: brightness(0.7);
	}
	&:is(:hover, :focus) > .Card-c__title,
	&:is(:hover, :focus) > .Card-c__text {
		transform: scale(0.9);
		opacity: 1;
	}
	.Title,
	.Text {
		&--color-e {
			color: var(--color-c);
		}
	}
}
