.blog {
	&__btns {
		@include adaptiv-value("margin-top", $offset-8, $offsetMobile-8, 1);
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__item {
	}
	&__list {
		.item-blog {
			display: grid;
			grid-template-columns: 200px 1fr;
			border-top: 1px solid var(--color-h);

			align-items: center;
			min-height: 180px;
			&:last-child {
				border-bottom: 1px solid var(--color-h);
			}
			&__content {
				@include adaptiv-value('padding-left', $offset-6, $offsetMobile-6, 1);
				@include adaptiv-value('padding-right', $offset-6, $offsetMobile-6, 1);
				@include adaptiv-value('padding-top', $offset-6, $offsetMobile-6, 1);
				@include adaptiv-value('padding-bottom', $offset-6, $offsetMobile-6, 1);
				cursor: pointer;
				position: relative;
				height: 100%;
				display: flex;
				align-items: center;

				&:hover > .item-blog__content-picture {
					transition: 0.3s opacity;

					opacity: 1;
				}
				&:hover > .item-blog__content-text {
					color: var(--color-a);
				}
			}
			&__content-text {
				@include adaptiv-value('max-width', 585, 400, 1);
				display: block;
			}
			&__content-picture {
				transition: 0.3s opacity;
				@include adaptiv-value('width', 460, 0, 1);
				width: 460px;
				// height: 276px;
				position: absolute;
				right: 20px;
				z-index: -1;
				top: 50%;
				transform: translateY(-50%);
				opacity: 0;

				.Picture {
					&__block-img {
						padding-bottom: 60%;
					}
				}
			}
			&__date {
				@include adaptiv-value('padding-left', $offset-7, $offsetMobile-7, 1);
				@include adaptiv-value('padding-right', $offset-7, $offsetMobile-7, 1);
				height: 100%;
				display: flex;
				justify-content: center;
				flex-direction: column;
				border-right: 1px solid var(--color-h);
			}
			&__date-text {
				font-weight: 500;
			}

			&__month-text {
				margin-left: -11px;
				font-weight: 500;
			}
		}
	}
	&__wrapper {
		@include adaptiv-value('margin-top', $offset-20, $offsetMobile-20, 1);
		&--container {
		}
		&--max-width {
		}
	}
}
