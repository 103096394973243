@import '_fonts';
@import '_settings';
@import '_vars';
@import '_mixins';

* {
	box-sizing: border-box;
	outline: none;
	color: var(--color-b);
	letter-spacing: 0;
	// transition: 0.3s all;
	::selection {
		background: #acff05bb;
	}
}

html,
body {
	width: 100%;
	font-family: $font-family-default;
	font-weight: 400;
	@include adaptiv-value('font-size', $font-size-s, $font-size-xs, 1);
	line-height: 1.18;
	letter-spacing: 0.1em;
	color: var(--color-b);
	position: relative;
	// overflow-x: hidden;// ! если включить fancyBox будет прокручиваться вверх страницы

	// scroll-behavior: smooth; // ! Плавная прокрутка без JS поддержка не полная
}
.overflow-h {
	overflow-y: hidden;
}
//! Fonts Size Global
h1 {
	@include adaptiv-value('font-size', 40, 24, 1);
	font-weight: 600;
}
h2 {
	@include adaptiv-value('font-size', 38, 20, 1);
	font-weight: 500;
}
h3 {
	@include adaptiv-value('font-size', 32, 18, 1);
	font-weight: 400;
}
p {
	@include adaptiv-value('font-size', 20, 14, 1);
	font-weight: 400;
}
hr {
	margin: 0;
	@include adaptiv-value('margin-top', $offset-4, $offsetMobile-4, 1);
	@include adaptiv-value('margin-bottom', $offset-4, $offsetMobile-4, 1);
	padding: 0;
	height: 0;
	border: none;
	border-top: 1px solid var(--color-g);
}

//! Btn Global
.Button {
	$blockName: &;
	//?? Общие стили для всех кнопок (для каждого проекта свои стили)
	@include adaptiv-value('font-size', $font-size-m, $font-size-s, 1);
	padding: 5px 5px;
	font-weight: 500;
	@include adaptiv-value('min-height', 46, 44, 1);
	border-radius: 5px;
	// box-shadow: 0px 5px 15px rgba(75, 81, 104, 0.5);
	// text-transform: uppercase;
	&__icon-svg {
		margin-left: 10px;
		width: 30px;
		height: 30px;
	}
}
.Input__label {
	textarea.Input__inp--open {
		min-height: 70px;
	}
}
textarea {
	resize: none;
}
textarea {
}
//! Scroll
/* ползунок скроллбара */
::-webkit-scrollbar-thumb {
	background-color: var(--color-a);
	// border-radius: 9em;
	// box-shadow: inset 1px 1px 6px #f3faf7;
}

::-webkit-scrollbar-thumb:hover {
	background-color: var(--color-d);
	cursor: pointer;
}
//! Link
a {
	color: var(--color-b);

	&:hover {
		color: var(--color-d);
	}
}
//! Js стили глобальные

.input-validation--invalid {
	color: var(--color-a);
}

// !Модификаторы для страниц
.up {
	position: fixed;
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	transition: 0.3s opacity;

	right: 50px;
	bottom: 77px;
	width: 42px !important;
	min-height: 42px !important;
	&--active {
		opacity: 1;
		visibility: visible;
		pointer-events: auto;
	}
	.Button__icon-svg {
		margin-right: 0;
	}
}
._box-shadow,
._bs {
	box-shadow: 0px 5px 15px rgba(143, 150, 176, 0.3);
}
// TODO: Отключение скролла для страницы
body.noscroll {
	overflow: hidden;
}
// TODO: Затемнение для страницы
.blackout {
	position: fixed;
	z-index: 550;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0);
	pointer-events: none;
	transition: $transition-time;

	&--active {
		background-color: rgba(0, 0, 0, 0.5);
	}
}
// ! Позиционирование страниц

.container {
	position: relative;
	overflow: hidden;
	&.open {
		padding-right: 10px;
	}
}

[class*='--container'] {
	margin: 0 auto;
}
[class*='--max-width'] {
	min-width: 320px;
	width: 100%;
	max-width: $maxWidthContainer + px;

	@media screen and (max-width: $md1 + px) {
		padding: 0 20px;
	}

	@media screen and (max-width: $md2 + px) {
		padding: 0 15px;
	}
}
// TODO: Grid layouts
[class*='--grid-a'] {
	display: grid;
	grid-template-columns: 1fr;
}
[class*='--grid-b'] {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}
[class*='--grid-c'] {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
}
[class*='--grid-d'] {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
}
[class*='--grid-e'] {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
}
[class*='--grid-f'] {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
}
[class*='--grid-g'] {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
}
[class*='--grid-i'] {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}
// !Fancybox
.fancybox-close-small {
	width: 52px;
	height: 52px;
}
.fancybox-container {
	.popup-discount {
		opacity: 1;
		visibility: visible;
		// top: 100%;
		// left: 100%;
		transform: translate(-50%, 15%);
	}
}

.fancybox-active {
	overflow: hidden;
	height: 100vh;

	padding-right: 10px !important;
	// padding-left: 5px !important;
	.container {
		.header {
			padding-right: 10px !important;
		}
		.up {
			margin-right: 10px !important;
		}
	}
}

// !Scroll decor
[class*='--scroll-h'] {
	overflow-x: auto;
	padding-bottom: 9px;

	&::-webkit-scrollbar {
		width: 5px; /* ширина для вертикального скролла */
		height: 5px; /* высота для горизонтального скролла */
		background-color: transparent;
	}
}

::-webkit-scrollbar {
	width: 10px; /* ширина для вертикального скролла */
	height: 10px; /* высота для горизонтального скролла */
	background-color: transparent;
}

/* Стрелки */

::-webkit-scrollbar-button:vertical:start:decrement {
	background: transparent;
	background-color: transparent;
	display: none;
}

::-webkit-scrollbar-button:vertical:end:increment {
	background: transparent;
	background-color: transparent;
	display: none;
}

::-webkit-scrollbar-button:horizontal:start:decrement {
	background: transparent;
	background-color: transparent;
	display: none;
}

::-webkit-scrollbar-button:horizontal:end:increment {
	background: transparent;
	background-color: transparent;
	display: none;
}

// Изменения скролла для FireFox
* {
	scrollbar-color: var(--color-b) transparent; /* «цвет ползунка» «цвет полосы скроллбара» */
	scrollbar-width: thin; /* толщина */
}
