.Nav {
	&__link {
	}
	&__nav {
		display: flex;
		justify-content: center;
	}

	&__list {
		display: flex;
		justify-content: space-between;
		.item-nav {
			border-left: 1px solid #4f4f4f;
			&__link {
				text-transform: none;
				&--active {
					color: var(--color-a);
					// text-shadow: 0 0 0.45px var(--color-a), 0 0 0.45px var(--color-a);
				}
			}
			&--mob {
				display: none;
			}
			font-weight: 500;
			&:not(:last-child) {
				// @include adaptiv-value('margin-right', $offset-3, $offsetMobile-3, 1);
			}
		}
	}
	&__item {
	}
	&__wrapper {
	}
	&__button {
		opacity: 0;
		visibility: hidden;
	}
	&__main {
		position: fixed;
		// @include adaptiv-value('top', 150, 130, 1);
		left: 0;
		@include adaptiv-value("top", 86, 81, 1);
		width: 100%;
		opacity: 0;
		pointer-events: none;
		// height: calc(100vh - 93px);
		height: 100vh;
		background-color: var(--color-d);
		z-index: 100;
		display: flex;
		// transition: 0.3s left;
		& ul {
			overflow-x: hidden;
		}
		flex-direction: column;
		&-wrapper {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 20px 15px 40px 15px;
		}
		&-social-networks {
			width: 100%;
			margin-top: 25px;
		}
		&.open {
			border-top: 1px solid rgba(255, 255, 255, 0.25);
			opacity: 1;
			pointer-events: auto;
			padding-right: 10px;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
		}
		.Nav__list {
			order: -1;
			width: 100%;
			max-height: 100vh;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: flex-start;
			padding: 15px;
			& > li {
				width: 170px;
				padding-left: 20px;
				& > a {
					width: 100%;
					display: flex;
					padding: 20px 0;
					font-size: 20px;
					font-weight: 400;
					color: var(--color-c);
					position: relative;
					&:hover {
						&::before {
							transition: 0.3s all;

							content: '';
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							left: -20px;
							height: 50%;
							border-left: 2px solid var(--color-a);
						}
						// background-color: rgba(122, 82, 179, 0.1);
					}
				}
			}
		}
	}
}
