.info {
	&--a {
		.info {
			&__bottom {
				margin-top: auto;
				// position: absolute;
				// bottom: 0;
				// width: 85%;
				position: relative;
			}
			&__bottom-line {
				background-color: var(--color-a);
				width: 50vw;
				height: 100%;
				position: absolute;
				left: -50vw;
				z-index: -1;
			}
			&__bottom-wrapper {
				display: flex;
				background-color: var(--color-a);
				justify-content: space-between;
				// @include adaptiv-value('gap', $offset-4, $offsetMobile-4, 1);
				// flex-wrap: wrap;
			}
			&__nav-list-btns {
				@include adaptiv-value('width', 180, 60, 1);
				display: flex;
				justify-content: center;

				align-items: center;
				background-color: var(--color-c);
				.Icon {
					&__svg {
						stroke: var(--color-a);
					}
				}
			}
			&__nav-list-inner {
				display: flex;
				border-bottom: 1px solid #dbdbdb;
			}
			&__filter {
				width: 100vw;
				height: 100vh;
			}
			&__heading {
				@include adaptiv-value('margin-top', $offset-7, $offsetMobile-7, 1);
			}
			&__inner {
				padding-top: 20vh;
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;
			}

			&__nav {
			}
			&__nav-item {
				&:not(:last-child) {
					// @include adaptiv-value('margin-right', $offset-6, $offsetMobile-6, 1);
					@include adaptiv-value('padding-right', $offset-6, $offsetMobile-6, 1);
					border-right: 1px solid #ac5f64;
				}
				&:last-child {
					// margin-left: auto;
					// width: 180px;
				}
			}
			&__nav-list {
				@include adaptiv-value('gap', $offset-6, $offsetMobile-6, 1);

				display: flex;
				flex-wrap: wrap;

				@include adaptiv-value('padding-top', $offset-4, $offsetMobile-4, 1);
				@include adaptiv-value('padding-bottom', $offset-10, $offsetMobile-10, 1);
			}

			&__wrapper {
				width: 100vw;
				height: 100vh;
				backdrop-filter: brightness(0.5);
				position: relative;
			}
		}
		.item-nav-info {
			&__content {
				@include adaptiv-value('font-size', $font-size-l, $font-size-xs, 1);
				color: var(--color-c);
				display: flex;
				flex-direction: column;
				&:last-child {
					@include adaptiv-value('margin-top', $offset-3, $offsetMobile-3, 1);
				}
			}
		}
	}
	&--b {
		.info {
			&__bottom {
				margin-top: auto;
				// position: absolute;
				// bottom: 0;
				// width: 85%;
				height: 28px;
				position: relative;
			}
			&__bottom-dec {
				// height: 28px;
				display: flex;
				justify-content: flex-end;
				width: 100%;
				height: 100%;
			}
			&__bottom-line {
				background-color: var(--color-a);
				width: 139.2vw;
				height: 100%;
				position: absolute;
				left: -100vw;
				z-index: -1;
			}
			&__bottom-wrapper {
				display: flex;
				background-color: var(--color-a);
				height: 100%;
				justify-content: space-between;
				// @include adaptiv-value('gap', $offset-4, $offsetMobile-4, 1);
				// flex-wrap: wrap;
			}
		}
	}
	&__bottom {
		margin-top: auto;
		// position: absolute;
		// bottom: 0;
		// width: 85%;
		position: relative;
	}
	&__bottom-line {
		background-color: var(--color-a);
		width: 50vw;
		height: 100%;
		position: absolute;
		left: -50vw;
		z-index: -1;
	}
	&__bottom-wrapper {
		display: flex;
		background-color: var(--color-a);
		justify-content: space-between;
		// @include adaptiv-value('gap', $offset-4, $offsetMobile-4, 1);
		// flex-wrap: wrap;
	}
	&__bottom-dec {
		// height: 28px;
		display: flex;
		justify-content: flex-end;
		width: 100%;
	}
	&__subtitle {
		@include adaptiv-value('margin-top', $offset-1, $offsetMobile-1, 1);
	}
	&__nav-list-btns {
		@include adaptiv-value('width', 180, 60, 1);
		display: flex;
		justify-content: center;

		align-items: center;
		background-color: var(--color-c);
		.Icon {
			&__svg {
				stroke: var(--color-a);
			}
		}
	}
	&__nav-list-inner {
		display: flex;
		border-bottom: 1px solid #dbdbdb;
		cursor: pointer;
	}
	&__filter {
		width: 100vw;
		height: 100vh;
	}
	&__heading {
		@include adaptiv-value('margin-top', $offset-10, $offsetMobile-10, 1);
	}
	&__inner {
		@include adaptiv-value('padding-top', $offset-28, 165px, 1);
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		@media (max-width: 733px) {
			// padding-top: 18vh;
		}
	}
	&__desc {
		@include adaptiv-value('padding-top', $offset-4, $offsetMobile-4, 1);
		@include adaptiv-value('padding-bottom', $offset-6, $offsetMobile-6, 1);
		.Title {
			justify-content: flex-start;
			font-weight: 500;
			@include adaptiv-value('margin-bottom', $offset-1, $offsetMobile-1, 1);
		}
	}
	&__nav {
	}
	&__nav-item {
		&:not(:last-child) {
			// @include adaptiv-value('margin-right', $offset-6, $offsetMobile-6, 1);
			@include adaptiv-value('padding-right', $offset-6, $offsetMobile-6, 1);
			border-right: 1px solid #ac5f64;
		}
		&:last-child {
			// margin-left: auto;
			// width: 180px;
		}
	}
	&__nav-list {
		@include adaptiv-value('gap', $offset-6, $offsetMobile-6, 1);

		display: flex;
		flex-wrap: wrap;

		@include adaptiv-value('padding-top', $offset-4, $offsetMobile-4, 1);
		@include adaptiv-value('padding-bottom', $offset-10, $offsetMobile-10, 1);
	}

	&__wrapper {
		width: 100%;
		height: 340px;
		backdrop-filter: brightness(0.5);
		position: relative;
	}
}
