.detail {
	&__info {
		@include adaptiv-value('margin-top', $offset-10, $offsetMobile-10, 1);
	}
	&__info-wrapper {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		@include adaptiv-value('margin-top', $offset-3, $offsetMobile-3, 1);
	}
	&__info-desc {
		flex: 1 1 635px;
	}
	&__info-desc-text {
	}
	&__info-status {
		display: flex;
		flex-direction: column;
		flex: 1 1 310px;
	}
	&__info-status-item {
	}
	&__info {
		.Title {
			font-style: normal;
			font-weight: 700;
			line-height: 115.8%;
			
		}
	}
	&__info-status-list {
		// display: flex;
		.item-info-status {
			display: flex;
			&:not(:first-child) {
				@include adaptiv-value('margin-top', $offset-1, $offsetMobile-1, 1);
			}
			strong {
				font-weight: 600;
				@include adaptiv-value('margin-right', $offset-05, $offsetMobile-05, 1);
				flex-shrink: 0;
				// width: 140px;
			}
			&__text {
			}
		}
		&__info-status-title 
		&__wrapper {
			amt &--container {
			}
			&--max-width {
			}
		}
	}
	&__slide {
		cursor: pointer;
	}
	&__swiper-container {
		position: relative;
	}
	&__swiper-container-icon {
		position: absolute;
		bottom: -1px;
		right: 0;
		z-index: 12;
		pointer-events: none;
	}
	&__nav {
		display: flex;
		justify-content: space-between;
		width: 100%;
		@include adaptiv-value('margin-top', $offset-8, $offsetMobile-8, 1);
	}
	&__nav-right,
	&__nav-left {
		&:hover {
			color: var(--color-a);
			.detail {
				&__nav-right-link-text,
				&__nav-left-link-text {
					color: var(--color-a);
				}
			}
			.Icon--color-b {
				.Icon {
					&__svg {
						fill: var(--color-a);
					}
				}
			}
		}
	}
	&__nav-left-link {
		.Icon {
			transform: rotate(180deg);
		}
	}
	&__nav-left-link-text {
		@include adaptiv-value('width', 200, 140, 1);
		padding-left: 10px;
		@include adaptiv-value('font-size', 16, 14, 1);
		transition: 0.3s all;
		// color: var(--color-b);
	}
	&__nav-right-link-text {
		@include adaptiv-value('width', 200, 140, 1);
		text-align: right;
		padding-right: 10px;
		@include adaptiv-value('font-size', 16, 14, 1);
		transition: 0.3s all;
		// color: var(--color-b);
	}
	&__nav-right-link-icon {
		width: 100%;
		.Icon {
			&__content {
				width: 100%;
				justify-content: flex-end;
			}
		}
	}
	&__wrapper {
		@include adaptiv-value('margin-top', $offset-3, $offsetMobile-3, 1);
	}
	.Icon--xl-w .Icon__svg {
		width: 125px;
		height: 13px;
	}
}
