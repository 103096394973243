.Action-button {
  $blockName: &;

  $mainColor: var(--color-b);
  $secondColor: var(--color-e);
  $tickColor: fill= 'white' !default;

  $boxWidth: 12.8px !default;
  $boxHeight: 12.8px !default;
  $tickWidth: 10px !default;
  $tickHeight: 7px !default;
  $spaceText: 26px !default;
  $borderColor: $mainColor !default;
  $borderColorHover: #dedede !default;
  $borderColorChecked: $mainColor !default;
  $borderColorCheckedHover: $mainColor !default;
  $borderColorDisabled: #e5e5e5 !default;
  $borderColorDisabledChecked: #cccccc !default;
  $bgColor: $secondColor !default;
  $bgColorHover: $secondColor !default;
  $bgColorChecked: $mainColor !default;
  $bgColorCheckedHover: $mainColor !default;
  $bgColorDisabled: #f7f6fa !default;
  $bgColorDisabledChecked: #cccccc !default;

  position: relative;
  display: inline-flex;
  flex-direction: column;
  touch-action: manipulation;
  width: 100%;

  input {
    left: $boxWidth;
    top: $boxHeight;
    position: absolute;

    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    appearance: none;

    &[type='checkbox'] {
      & + #{$blockName}__content::before {
        content: '';
        position: absolute;
        top: 0;
        margin-top: 3px;
        flex: 0 0 auto;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: $boxWidth;
        height: $boxWidth;
        border: 1px solid $borderColorChecked;
        user-select: none;
        background: $bgColorChecked
          url('data:image/svg+xml,%3Csvg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M9.4046 0.693385L9.10652 0.395304C9.01286 0.301567 8.88798 0.25 8.75466 0.25C8.62134 0.25 8.49638 0.301567 8.40272 0.395304L4.21427 4.58368L1.59739 1.96672C1.50372 1.87313 1.37877 1.82149 1.24545 1.82149C1.1122 1.82149 0.987318 1.87313 0.89358 1.96672L0.595426 2.26473C0.501688 2.35861 0.450195 2.48365 0.450195 2.61682C0.450195 2.75006 0.501688 2.87502 0.595426 2.96876L3.55004 5.92323C3.55396 5.92878 3.55811 5.93396 3.56292 5.93869L3.86107 6.23189C3.95473 6.32526 4.07969 6.37194 4.21405 6.37194H4.2156C4.34899 6.37194 4.47395 6.32526 4.56747 6.23189L4.8657 5.9361C4.87051 5.93137 4.87458 5.92744 4.87702 5.92345L9.40453 1.39638C9.59874 1.20262 9.59874 0.887371 9.4046 0.693385Z" #{$tickColor}/%3E%3C/svg%3E%0A')
          no-repeat center / $tickWidth $tickHeight;
      }
    }
    &[type='radio'] {
      & + #{$blockName}__content::before {
        content: '';
        position: absolute;
        top: 0;
        margin-top: 3px;
        flex: 0 0 auto;
        display: inline-flex;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        width: $boxWidth;
        height: $boxWidth;
        border: 1px solid $borderColorChecked;
        user-select: none;
        background: $bgColorChecked
          url('data:image/svg+xml,%3Csvg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M9.4046 0.693385L9.10652 0.395304C9.01286 0.301567 8.88798 0.25 8.75466 0.25C8.62134 0.25 8.49638 0.301567 8.40272 0.395304L4.21427 4.58368L1.59739 1.96672C1.50372 1.87313 1.37877 1.82149 1.24545 1.82149C1.1122 1.82149 0.987318 1.87313 0.89358 1.96672L0.595426 2.26473C0.501688 2.35861 0.450195 2.48365 0.450195 2.61682C0.450195 2.75006 0.501688 2.87502 0.595426 2.96876L3.55004 5.92323C3.55396 5.92878 3.55811 5.93396 3.56292 5.93869L3.86107 6.23189C3.95473 6.32526 4.07969 6.37194 4.21405 6.37194H4.2156C4.34899 6.37194 4.47395 6.32526 4.56747 6.23189L4.8657 5.9361C4.87051 5.93137 4.87458 5.92744 4.87702 5.92345L9.40453 1.39638C9.59874 1.20262 9.59874 0.887371 9.4046 0.693385Z" #{$tickColor}/%3E%3C/svg%3E%0A')
          no-repeat center / $tickWidth $tickHeight;
      }
    }

    & + #{$blockName}__content:hover::before {
      border-color: $borderColorCheckedHover;
      background-color: $bgColorCheckedHover;
    }
    &:not(:checked) + #{$blockName}__content::before {
      border-color: $borderColor;
      background-color: $bgColor;
      background-image: none;
    }
    &:not(:checked) + #{$blockName}__content:hover::before {
      border-color: $borderColorHover;
      background-color: $bgColorHover;
    }
    &:not(:checked):disabled + #{$blockName}__content::before {
      border-color: $borderColorDisabled !important;
      background-color: $bgColorDisabled !important;
    }
    &:disabled + #{$blockName}__content::before {
      border-color: $borderColorDisabledChecked !important;
      background-color: $bgColorDisabledChecked !important;
    }
    &:disabled + #{$blockName}__content {
      cursor: initial;
    }
    &:focus + #{$blockName}__content::before {
      outline: none;
    }
  }

  &__content {
    display: inline-flex;
    cursor: pointer;
  }

  &__text {
    // line-height: 19px;
		font-size: 15px;
		a {
			text-decoration: underline;
		}
  }

  &__text--policy {
    color: var(--color-b);
    padding-left: $spaceText;
    a {
      color: $mainColor;
      // border-bottom: 1px solid $lime;
      transition: 0.3s all;
      &:hover,
      &:focus {
        color: $mainColor;
        opacity: 0.6;
        // border-bottom: 1px solid rgba(0, 0, 0, 0);
      }
    }
  }
}
