//Адаптивное свойство
@mixin adaptiv-value($property,/* width or hieght */ $startSize, $minSize, $type) {
  $addSize: $startSize - $minSize;

  @if $type==1 {
    //Только если меньше контейнера
    #{$property}: $startSize + px;

    @media (max-width: #{$maxWidthContainer + px}) {
      #{$property}: calc(#{$minSize + px} + #{$addSize} * ((100vw - 375px) / #{$maxWidthContainer - 320}));
    }
  } @else if $type==2 {
    //Только если больше контейнера
    #{$property}: $startSize + px;

    @media (min-width: #{$maxWidthContainer + px}) {
      #{$property}: calc(#{$minSize + px} + #{$addSize} * ((100vw - 375px) / #{$maxWidth - 320}));
    }
  } @else {
    //Всегда
    #{$property}: calc(#{$minSize + px} + #{$addSize} * ((100vw - 375px) / #{$maxWidth - 320}));
  }
}

//Адаптивное свойство
@mixin adaptiv-valuePx($property,/* width or hieght */ $startSize, $minSize, $type) {
  $addSize: $startSize - $minSize;

  @if $type==1 {
    //Только если меньше контейнера
    #{$property}: $startSize + px;

    @media (max-width: #{$maxWidthContainer + px}) {
      #{$property}: calc(#{$minSize + px} + #{$addSize} * ((100vw - 375px) / #{$maxWidthContainer - 320}));
    }
  } @else if $type==2 {
    //Только если больше контейнера
    #{$property}: $startSize + px;

    @media (min-width: #{$maxWidthContainer + px}) {
      #{$property}: calc(#{$minSize + px} + #{$addSize} * ((100vw - 375px) / #{$maxWidth - 320}));
    }
  } @else {
    //Всегда
    #{$property}: calc(#{$minSize + px} + #{$addSize} * ((100vw - 375px) / #{$maxWidth - 320}));
  }
}