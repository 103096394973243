.contacts {
	&__wrapper {
		display: flex;
		justify-content: space-between;
		@include adaptiv-value('margin-top', $offset-20, $offsetMobile-20, 1);
	}

	&__list-info {
		display: flex;
		flex-direction: column;
	}
	&__inner {
		height: 100%;
		max-width: 40%;
	}

	.Item-list {
		display: flex;
		flex-direction: column;

		&:not(:last-child) {
			@include adaptiv-value('margin-bottom', $offset-5, $offsetMobile-5, 1);
		}

		&__place {
			@include adaptiv-value('font-size', $font-size-xl, $font-size-s, 1);
			color: var(--color-a);
		}

		&__work-time,
		&__contacts {
			@include adaptiv-value('font-size', $font-size-l, $font-size-s, 1);

			&:not(:last-child) {
				margin-bottom: 8px;
			}
		}

		&__link,
		&__desc {
			@include adaptiv-value('font-size', $font-size-m, $font-size-xs, 1);
		}

		&__link {
			width: max-content;
			padding: 0 10px;
			border-bottom: 1px solid var(--color-a);
		}
	}

	&__map {
		@include adaptiv-value('width', 690, 342, 1);
		@include adaptiv-value('height', 690, 342, 1);

		.ymaps-2-1-79-balloon__content {
			margin-right: 0 !important;
			padding: 10px 12px !important;
		}

		.ymaps-2-1-79-balloon__close {
			display: none;
		}

		.ymaps__balloon-content {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;

			& > svg {
				width: 80px;
				height: 80px;
				stroke: black;
			}
		}

		.ymaps-2-1-79-controls__toolbar_left,
		.ymaps-2-1-79-controls__toolbar_right,
		.ymaps-2-1-79-copyrights-pane {
			display: none;
		}
	}

	@media (max-width: 787px) {
		&__wrapper {
			flex-direction: column;
			align-items: center;
		}

		&__list-info {
			width: 100%;
			margin-bottom: 40px;
		}
	}
}
