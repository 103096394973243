.Picture {
	&--a {
		.Picture {
			&__block-img {
				padding-bottom: 60%;
			}
		}
	}
	&--b {
		.Picture {
			&__block-img {
				padding-bottom: 70%;
			}
			&__img {
				object-fit: contain;
			}
		}
	}
	&--full {
		.Picture {
			&__block-img {
				padding-bottom: 100%;
			}
		}
	}
	&--link {
		.Picture {
			&__block-img {
				padding-bottom: 64%;
			}
		}
	}
	&--theme {
		&-l {
		}
	}
	&__img-link {
		position: absolute;
		right: 20px;
		top: 50%;
		transform: translateY(-50%);
		&:hover {
			opacity: 0.7;
		}
	}
	&__block-img {
		position: relative;
		padding-bottom: 53%;
	}

	&__img,
	&__img-source {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
}

picture {
	display: block;
	width: 100%;
	height: auto;
}
