.popup-status {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 502;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100vw;
	height: 100vh;
	padding: 0;
	background: var(--color-d);
	transition: opacity 1s, transform 1s;

	&__wrapper {
		position: relative;
		@include adaptiv-value('padding-top', 60, 40, 1);
		@include adaptiv-value('padding-bottom', 60, 40, 1);
		@include adaptiv-value('padding-left', 40, 20, 1);
		@include adaptiv-value('padding-right', 40, 20, 1);
		border-radius: 5px;
		max-width: 500px;
	}

	&--disable {
		opacity: 0;
		pointer-events: none;
	}

	&--enable {
		opacity: 1;
	}

	.Button__icon-svg {
		&--close {
			cursor: pointer;
			position: absolute;
			top: 20px;
			@include adaptiv-value('width', 40, 20, 1);
			@include adaptiv-value('height', 40, 20, 1);
			@include adaptiv-value('right', -150, 0, 1);
		}
	}

	&__btns {
		@include adaptiv-value('margin-top', $offset-4, $offsetMobile-4, 1);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.Button--full {
			max-width: 270px;
			width: 100%;
			padding: 10px;
			background: none;
			transition: border 0.3s;
			border-width: 1px;
			border-style: solid;
			border-color: var(--color-d);

			& > .Button {
				&__text {
					display: flex;
					margin-left: 10px;
					order: 1;
					color: var(--color-c);
				}

				&__icon {
					transform: rotate(180deg);
				}
			}

			&:hover {
				border-color: var(--color-c);
				border-radius: 5px;
			}
		}
	}

	&__subtitle {
		@include adaptiv-value('font-size', 24, 16, 1);
		text-align: center;
		font-weight: 400;
		@include adaptiv-value('margin-top', $offset-4, $offsetMobile-4, 1);

		&-text {
			display: flex;
			position: relative;
			align-items: center;
			justify-content: center;
			width: 100%;

			&::after,
			&::before {
				width: 10%;
				content: '';
				height: 1px;
				display: block;
				background: var(--color-b);
				margin: 0 10px;
			}
		}
	}

	&__title {
		@include adaptiv-value('font-size', 30, 20, 1);
		font-weight: 700;
		text-align: center;

		* {
			color: var(--color-c);
		}
	}

	&__title-text {
		@include adaptiv-value('font-size', 30, 20, 1);
	}
}
