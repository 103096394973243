@charset "UTF-8";
:root {
  --color-a: #891a22;
  --color-b: #000000;
  --color-c: #ffffff;
  --color-d: #232323;
  --color-e: #bdbdbd;
  --color-f: #d13843;
  --color-g: #646464;
  --color-h: #e2e2e2;
  --color-i: #222631; }

[class*='--center'] {
  display: flex;
  justify-content: center;
  align-items: center; }

[class*='--theme-a'] {
  line-height: 112%;
  font-weight: 600;
  font-size: 18px; }

@font-face {
  src: url("../fonts/Gilroy/Gilroy-Bold.woff2") format("woff2"), url("../fonts/Gilroy/Gilroy-Bold.ttf") format("ttf"), url("../fonts/Gilroy/Gilroy-Bold.woff") format("woff");
  font-family: 'Gilroy';
  font-weight: 700;
  font-style: bold; }

@font-face {
  src: url("../fonts/Gilroy/Gilroy-Medium.woff2") format("woff2"), url("../fonts/Gilroy/Gilroy-Medium.ttf") format("ttf"), url("../fonts/Gilroy/Gilroy-Medium.woff") format("woff");
  font-family: 'Gilroy';
  font-weight: 500;
  font-style: medium; }

@font-face {
  src: url("../fonts/Gilroy/Gilroy-ExtraBold.woff2") format("woff2"), url("../fonts/Gilroy/Gilroy-ExtraBold.ttf") format("ttf"), url("../fonts/Gilroy/Gilroy-ExtraBold.woff") format("woff");
  font-family: 'Gilroy';
  font-weight: 800;
  font-style: extrabold; }

@font-face {
  src: url("../fonts/Gilroy/Gilroy-Light.woff2") format("woff2"), url("../fonts/Gilroy/Gilroy-Light.ttf") format("ttf"), url("../fonts/Gilroy/Gilroy-Light.woff") format("woff");
  font-family: 'Gilroy';
  font-weight: 300;
  font-style: light; }

@font-face {
  src: url("../fonts/Gilroy/Gilroy-Regular.woff2") format("woff2"), url("../fonts/Gilroy/Gilroy-Regular.ttf") format("ttf"), url("../fonts/Gilroy/Gilroy-Regular.woff") format("woff");
  font-family: 'Gilroy';
  font-weight: 300;
  font-style: regular; }

@font-face {
  src: url("../fonts/Gilroy/Gilroy-SemiBold.woff2") format("woff2"), url("../fonts/Gilroy/Gilroy-SemiBold.ttf") format("ttf"), url("../fonts/Gilroy/Gilroy-SemiBold.woff") format("woff");
  font-family: 'Gilroy';
  font-weight: 600;
  font-style: semibold; }

@font-face {
  src: url("../fonts/Roboto/Roboto-Regular.woff2") format("woff2"), url("../fonts/Roboto/Roboto-Regular.ttf") format("ttf"), url("../fonts/Roboto/Roboto-Regular.woff") format("woff");
  font-family: 'Roboto';
  font-weight: 400;
  font-style: regular; }

@font-face {
  src: url("../fonts/PFBeauSansPro/PFBeauSansPro-Regular.woff2") format("woff2"), url("../fonts/PFBeauSansPro/PFBeauSansPro-Regular.ttf") format("ttf"), url("../fonts/PFBeauSansPro/PFBeauSansPro-Regular.woff") format("woff");
  font-family: 'PFBeauSansPro';
  font-weight: 400;
  font-style: regular; }

@font-face {
  src: url("../fonts/PFBeauSansPro/PFBeauSansPro-SemiBold.woff2") format("woff2"), url("../fonts/PFBeauSansPro/PFBeauSansPro-SemiBold.ttf") format("ttf"), url("../fonts/PFBeauSansPro/PFBeauSansPro-SemiBold.woff") format("woff");
  font-family: 'PFBeauSansPro';
  font-weight: 400;
  font-style: semibold; }

@font-face {
  src: url("../fonts/PTSansNarrow/PTSansNarrow.woff2") format("woff2"), url("../fonts/PTSansNarrow/PTSansNarrow.ttf") format("ttf"), url("../fonts/PTSansNarrow/PTSansNarrow.woff") format("woff");
  font-family: 'PTSansNarrow';
  font-weight: 400;
  font-style: regular; }

@font-face {
  src: url("../fonts/PTSansNarrow/PTSansNarrowBold.woff2") format("woff2"), url("../fonts/PTSansNarrow/PTSansNarrowBold.ttf") format("ttf"), url("../fonts/PTSansNarrow/PTSansNarrowBold.woff") format("woff");
  font-family: 'PTSansNarrow';
  font-weight: 700;
  font-style: bold; }

@font-face {
  src: url("../fonts/HelveticaNeueCyr/HelveticaNeueCyr-Bold.woff2") format("woff2"), url("../fonts/HelveticaNeueCyr/HelveticaNeueCyr-Bold.ttf") format("ttf");
  font-family: 'HelveticaNeueCyr';
  font-weight: 700;
  font-style: bold; }

@font-face {
  src: url("../fonts/HelveticaNeueCyr/HelveticaNeueCyr-Medium.woff2") format("woff2"), url("../fonts/HelveticaNeueCyr/HelveticaNeueCyr-Medium.ttf") format("ttf");
  font-family: 'HelveticaNeueCyr';
  font-weight: 500;
  font-style: medium; }

@font-face {
  src: url("../fonts/HelveticaNeueCyr/HelveticaNeueCyr-Regular.woff2") format("woff2"), url("../fonts/HelveticaNeueCyr/HelveticaNeueCyr-Regular.ttf") format("ttf");
  font-family: 'HelveticaNeueCyr';
  font-weight: 400;
  font-style: regular; }

@font-face {
  src: url("../fonts/Jost/Jost-SemiBold.woff2") format("woff2"), url("../fonts/Jost/Jost-SemiBold.ttf") format("ttf");
  font-family: 'Jost';
  font-weight: 600;
  font-style: 'semi-bold'; }

@font-face {
  src: url("../fonts/Jost/Jost-Medium.woff2") format("woff2"), url("../fonts/Jost/Jost-Medium.ttf") format("ttf");
  font-family: 'Jost';
  font-weight: 500;
  font-style: medium; }

@font-face {
  src: url("../fonts/Jost/Jost-Regular.woff2") format("woff2"), url("../fonts/Jost/Jost-Regular.ttf") format("ttf");
  font-family: 'Jost';
  font-weight: 400;
  font-style: regular; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  vertical-align: baseline;
  margin: 0;
  border: 0;
  padding: 0;
  font-weight: inherit;
  font-size: inherit; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

html {
  height: 100%; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: ''; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a {
  text-decoration: none; }

input[type='time'] ::-webkit-calendar-picker-indicator,
::-webkit-datetime-edit-fields-wrapper,
::-webkit-inner-spin-button {
  padding-left: 100%; }

:root {
  --color-a: #891a22;
  --color-b: #000000;
  --color-c: #ffffff;
  --color-d: #232323;
  --color-e: #bdbdbd;
  --color-f: #d13843;
  --color-g: #646464;
  --color-h: #e2e2e2;
  --color-i: #222631; }

[class*='--center'] {
  display: flex;
  justify-content: center;
  align-items: center; }

[class*='--theme-a'] {
  line-height: 112%;
  font-weight: 600;
  font-size: 18px; }

* {
  box-sizing: border-box;
  outline: none;
  color: var(--color-b);
  letter-spacing: 0; }
  * ::selection {
    background: #acff05bb; }

html,
body {
  width: 100%;
  font-family: "Jost";
  font-weight: 400;
  font-size: 16px;
  line-height: 1.18;
  letter-spacing: 0.1em;
  color: var(--color-b);
  position: relative; }
  @media (max-width: 1366px) {
    html,
    body {
      font-size: calc(14px + 2 * ((100vw - 375px) / 1046)); } }

.overflow-h {
  overflow-y: hidden; }

h1 {
  font-size: 40px;
  font-weight: 600; }
  @media (max-width: 1366px) {
    h1 {
      font-size: calc(24px + 16 * ((100vw - 375px) / 1046)); } }

h2 {
  font-size: 38px;
  font-weight: 500; }
  @media (max-width: 1366px) {
    h2 {
      font-size: calc(20px + 18 * ((100vw - 375px) / 1046)); } }

h3 {
  font-size: 32px;
  font-weight: 400; }
  @media (max-width: 1366px) {
    h3 {
      font-size: calc(18px + 14 * ((100vw - 375px) / 1046)); } }

p {
  font-size: 20px;
  font-weight: 400; }
  @media (max-width: 1366px) {
    p {
      font-size: calc(14px + 6 * ((100vw - 375px) / 1046)); } }

hr {
  margin: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0;
  height: 0;
  border: none;
  border-top: 1px solid var(--color-g); }
  @media (max-width: 1366px) {
    hr {
      margin-top: calc(10px + 10 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    hr {
      margin-bottom: calc(10px + 10 * ((100vw - 375px) / 1046)); } }

.Button {
  font-size: 20px;
  padding: 5px 5px;
  font-weight: 500;
  min-height: 46px;
  border-radius: 5px; }
  @media (max-width: 1366px) {
    .Button {
      font-size: calc(16px + 4 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    .Button {
      min-height: calc(44px + 2 * ((100vw - 375px) / 1046)); } }
  .Button__icon-svg {
    margin-left: 10px;
    width: 30px;
    height: 30px; }

.Input__label textarea.Input__inp--open {
  min-height: 70px; }

textarea {
  resize: none; }

/* ползунок скроллбара */
::-webkit-scrollbar-thumb {
  background-color: var(--color-a); }

::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-d);
  cursor: pointer; }

a {
  color: var(--color-b); }
  a:hover {
    color: var(--color-d); }

.input-validation--invalid {
  color: var(--color-a); }

.up {
  position: fixed;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 0.3s opacity;
  right: 50px;
  bottom: 77px;
  width: 42px !important;
  min-height: 42px !important; }
  .up--active {
    opacity: 1;
    visibility: visible;
    pointer-events: auto; }
  .up .Button__icon-svg {
    margin-right: 0; }

._box-shadow,
._bs {
  box-shadow: 0px 5px 15px rgba(143, 150, 176, 0.3); }

body.noscroll {
  overflow: hidden; }

.blackout {
  position: fixed;
  z-index: 550;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  pointer-events: none;
  transition: 0.3s; }
  .blackout--active {
    background-color: rgba(0, 0, 0, 0.5); }

.container {
  position: relative;
  overflow: hidden; }
  .container.open {
    padding-right: 10px; }

[class*='--container'] {
  margin: 0 auto; }

[class*='--max-width'] {
  min-width: 320px;
  width: 100%;
  max-width: 1366px; }
  @media screen and (max-width: 1406px) {
    [class*='--max-width'] {
      padding: 0 20px; } }
  @media screen and (max-width: 991.98px) {
    [class*='--max-width'] {
      padding: 0 15px; } }

[class*='--grid-a'] {
  display: grid;
  grid-template-columns: 1fr; }

[class*='--grid-b'] {
  display: grid;
  grid-template-columns: repeat(2, 1fr); }

[class*='--grid-c'] {
  display: grid;
  grid-template-columns: repeat(3, 1fr); }

[class*='--grid-d'] {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

[class*='--grid-e'] {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

[class*='--grid-f'] {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

[class*='--grid-g'] {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

[class*='--grid-i'] {
  display: grid;
  grid-template-columns: repeat(2, 1fr); }

.fancybox-close-small {
  width: 52px;
  height: 52px; }

.fancybox-container .popup-discount {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, 15%); }

.fancybox-active {
  overflow: hidden;
  height: 100vh;
  padding-right: 10px !important; }
  .fancybox-active .container .header {
    padding-right: 10px !important; }
  .fancybox-active .container .up {
    margin-right: 10px !important; }

[class*='--scroll-h'] {
  overflow-x: auto;
  padding-bottom: 9px; }
  [class*='--scroll-h']::-webkit-scrollbar {
    width: 5px;
    /* ширина для вертикального скролла */
    height: 5px;
    /* высота для горизонтального скролла */
    background-color: transparent; }

::-webkit-scrollbar {
  width: 10px;
  /* ширина для вертикального скролла */
  height: 10px;
  /* высота для горизонтального скролла */
  background-color: transparent; }

/* Стрелки */
::-webkit-scrollbar-button:vertical:start:decrement {
  background: transparent;
  background-color: transparent;
  display: none; }

::-webkit-scrollbar-button:vertical:end:increment {
  background: transparent;
  background-color: transparent;
  display: none; }

::-webkit-scrollbar-button:horizontal:start:decrement {
  background: transparent;
  background-color: transparent;
  display: none; }

::-webkit-scrollbar-button:horizontal:end:increment {
  background: transparent;
  background-color: transparent;
  display: none; }

* {
  scrollbar-color: var(--color-b) transparent;
  /* «цвет ползунка» «цвет полосы скроллбара» */
  scrollbar-width: thin;
  /* толщина */ }

.header {
  position: fixed;
  z-index: 500;
  background-color: #fff;
  left: 0;
  right: 0;
  top: 0;
  background-color: transparent;
  border-width: 1px 0px 1px 0;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.25);
  transition: background-color 0.3s, backdrop-filter 0.3s; }
  .header.open {
    background: var(--color-d);
    padding-right: 10px; }
    .header.open .Hamb__bar {
      background-color: var(--color-c); }
    .header.open .header__logo {
      opacity: 0;
      max-height: 0;
      pointer-events: none; }
    .header.open .header__logo-open {
      opacity: 1;
      max-height: 100px;
      pointer-events: auto;
      animation: openLogo 1s forwards; }
  .header:not(.open) .header__logo-open {
    opacity: 0;
    max-height: 0;
    pointer-events: none; }
  .header:not(.open) .header__logo {
    opacity: 1;
    animation: defLogo 1s forwards;
    pointer-events: auto; }
  .header--main .header__nav {
    display: none; }
  .header--main .header__wrapper {
    padding-bottom: 0;
    align-items: center; }
  .header--main .item-nav:hover .Nav-link {
    opacity: 1;
    visibility: visible; }
  .header--main .Nav-link {
    opacity: 0;
    visibility: hidden; }
  .header--main-active {
    background-color: var(--color-e);
    box-shadow: 0 5px 15px rgba(143, 150, 176, 0.3); }
  .header--active {
    background-color: var(--color-e);
    box-shadow: 0 5px 15px rgba(143, 150, 176, 0.3); }
    .header--active .header__wrapper {
      transition: 0.3s padding; }
  .header__btn {
    display: flex;
    justify-content: flex-end; }
    .header__btn > *:not(:last-child) {
      margin-right: 20px; }
      @media (max-width: 1366px) {
        .header__btn > *:not(:last-child) {
          margin-right: calc(10px + 10 * ((100vw - 375px) / 1046)); } }
    .header__btn .Link--color-c-decor:hover::before {
      top: 54px; }
  .header__action {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
  .header__logo {
    cursor: pointer; }
  .header__logo-open {
    opacity: 0;
    max-height: 0;
    pointer-events: none; }
  .header__logo-picture {
    width: 85px; }
    @media (max-width: 1366px) {
      .header__logo-picture {
        width: calc(85px + 0 * ((100vw - 375px) / 1046)); } }
    .header__logo-picture .Picture__block-img {
      padding-bottom: 71%; }
  .header__navigation {
    display: flex;
    align-items: center;
    height: 100%; }
  .header__nav-link {
    margin-top: 10px; }
    @media (max-width: 1366px) {
      .header__nav-link {
        margin-top: calc(5px + 5 * ((100vw - 375px) / 1046)); } }
  .header__wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    min-height: 80px;
    align-items: center; }

@keyframes openLogo {
  0% {
    transform: rotateY(90deg); }
  100% {
    transform: rotateY(0deg);
    opacity: 1; } }

@keyframes defLogo {
  0% {
    transform: rotateY(90deg); }
  100% {
    transform: rotateY(0deg); } }

[header-dec] {
  transition: background-color 0.3s, backdrop-filter 0.3s;
  background-color: rgba(0, 0, 0, 0.7); }

.footer {
  min-height: 350px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--color-d);
  margin-top: 150px;
  padding-top: 30px;
  padding-bottom: 35px; }
  @media (max-width: 1366px) {
    .footer {
      margin-top: calc(75px + 75 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    .footer {
      padding-top: calc(15px + 15 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    .footer {
      padding-bottom: calc(17.5px + 17.5 * ((100vw - 375px) / 1046)); } }
  .footer__content {
    display: flex;
    justify-content: space-between; }
  .footer__copyright {
    display: flex;
    justify-content: space-between; }
    .footer__copyright span {
      color: var(--color-c);
      font-size: 16px; }
      @media (max-width: 1366px) {
        .footer__copyright span {
          font-size: calc(14px + 2 * ((100vw - 375px) / 1046)); } }
  .footer__copyright-left {
    display: flex;
    flex-direction: column; }
  .footer__copyright-right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end; }
  .footer__nav {
    margin-top: 45px; }
    @media (max-width: 1366px) {
      .footer__nav {
        margin-top: calc(22.5px + 22.5 * ((100vw - 375px) / 1046)); } }
  .footer__info {
    max-width: 167px; }
  .footer__link-wrap {
    margin-top: 15px; }
    @media (max-width: 1366px) {
      .footer__link-wrap {
        margin-top: calc(7.5px + 7.5 * ((100vw - 375px) / 1046)); } }
  .footer__logo .Icon--xxl .Icon__svg {
    width: 88px;
    height: 88px; }
  .footer__logo-creators {
    margin-top: 5px;
    display: flex;
    justify-content: flex-end; }
    @media (max-width: 1366px) {
      .footer__logo-creators {
        margin-top: calc(2.5px + 2.5 * ((100vw - 375px) / 1046)); } }

.detail__info {
  margin-top: 50px; }
  @media (max-width: 1366px) {
    .detail__info {
      margin-top: calc(25px + 25 * ((100vw - 375px) / 1046)); } }

.detail__info-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 15px; }
  @media (max-width: 1366px) {
    .detail__info-wrapper {
      margin-top: calc(7.5px + 7.5 * ((100vw - 375px) / 1046)); } }

.detail__info-desc {
  flex: 1 1 635px; }

.detail__info-status {
  display: flex;
  flex-direction: column;
  flex: 1 1 310px; }

.detail__info .Title {
  font-style: normal;
  font-weight: 700;
  line-height: 115.8%; }

.detail__info-status-list .item-info-status {
  display: flex; }
  .detail__info-status-list .item-info-status:not(:first-child) {
    margin-top: 5px; }
    @media (max-width: 1366px) {
      .detail__info-status-list .item-info-status:not(:first-child) {
        margin-top: calc(2.5px + 2.5 * ((100vw - 375px) / 1046)); } }
  .detail__info-status-list .item-info-status strong {
    font-weight: 600;
    margin-right: 2.5px;
    flex-shrink: 0; }
    @media (max-width: 1366px) {
      .detail__info-status-list .item-info-status strong {
        margin-right: calc(2.5px + 0 * ((100vw - 375px) / 1046)); } }

.detail__slide {
  cursor: pointer; }

.detail__swiper-container {
  position: relative; }

.detail__swiper-container-icon {
  position: absolute;
  bottom: -1px;
  right: 0;
  z-index: 12;
  pointer-events: none; }

.detail__nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px; }
  @media (max-width: 1366px) {
    .detail__nav {
      margin-top: calc(20px + 20 * ((100vw - 375px) / 1046)); } }

.detail__nav-right:hover, .detail__nav-left:hover {
  color: var(--color-a); }
  .detail__nav-right:hover .detail__nav-right-link-text, .detail__nav-right:hover .detail__nav-left-link-text, .detail__nav-left:hover .detail__nav-right-link-text, .detail__nav-left:hover .detail__nav-left-link-text {
    color: var(--color-a); }
  .detail__nav-right:hover .Icon--color-b .Icon__svg, .detail__nav-left:hover .Icon--color-b .Icon__svg {
    fill: var(--color-a); }

.detail__nav-left-link .Icon {
  transform: rotate(180deg); }

.detail__nav-left-link-text {
  width: 200px;
  padding-left: 10px;
  font-size: 16px;
  transition: 0.3s all; }
  @media (max-width: 1366px) {
    .detail__nav-left-link-text {
      width: calc(140px + 60 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    .detail__nav-left-link-text {
      font-size: calc(14px + 2 * ((100vw - 375px) / 1046)); } }

.detail__nav-right-link-text {
  width: 200px;
  text-align: right;
  padding-right: 10px;
  font-size: 16px;
  transition: 0.3s all; }
  @media (max-width: 1366px) {
    .detail__nav-right-link-text {
      width: calc(140px + 60 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    .detail__nav-right-link-text {
      font-size: calc(14px + 2 * ((100vw - 375px) / 1046)); } }

.detail__nav-right-link-icon {
  width: 100%; }
  .detail__nav-right-link-icon .Icon__content {
    width: 100%;
    justify-content: flex-end; }

.detail__wrapper {
  margin-top: 15px; }
  @media (max-width: 1366px) {
    .detail__wrapper {
      margin-top: calc(7.5px + 7.5 * ((100vw - 375px) / 1046)); } }

.detail .Icon--xl-w .Icon__svg {
  width: 125px;
  height: 13px; }

.gui-page {
  padding: 20px;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: #fff; }
  .gui-page--popups {
    padding: 50px 0; }
    .gui-page--popups .gui-page__block-element--center {
      padding: 0 10px 20px; }
  .gui-page .Map-site-gui__list {
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px; }
  .gui-page .Map-site-gui__item {
    font-weight: 600;
    text-transform: uppercase; }
    .gui-page .Map-site-gui__item a {
      color: currentColor; }
    .gui-page .Map-site-gui__item--final {
      color: var(--color-d);
      order: 1; }
    .gui-page .Map-site-gui__item--need-help {
      color: var(--color-a); }
    .gui-page .Map-site-gui__item a {
      transition: 0.3s; }
    .gui-page .Map-site-gui__item:hover > a {
      color: blue; }
  .gui-page__title {
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 35px;
    text-align: center;
    text-transform: uppercase; }
  .gui-page__container-elements {
    margin: 0 -15px 30px; }
  .gui-page__section-title {
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
    color: blue; }
  .gui-page__block-theme {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    justify-content: center; }
  .gui-page__block-element {
    position: relative;
    padding: 0 15px 20px;
    margin-top: 30px; }
    .gui-page__block-element .Img {
      padding-bottom: 30%;
      width: 200px;
      height: 200px; }
    .gui-page__block-element--center {
      display: flex;
      align-items: center;
      flex-direction: column; }
  .gui-page__block-element-text {
    display: flex;
    justify-content: center;
    color: black;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px; }
  .gui-page__block-element-map {
    display: grid;
    padding: 0 15px 20px;
    width: 100%; }
  .gui-page__section {
    display: grid;
    grid-template-columns: repeat(1, 100%);
    justify-content: center; }
    .gui-page__section--full-screen {
      grid-template-columns: repeat(1, 100%); }

.contacts__info-city {
  font-weight: 700; }
  .contacts__info-city .Title {
    font-style: normal;
    font-weight: 700;
    font-size: 26.851px;
    line-height: 115.8%;
    text-transform: unset;
    letter-spacing: 0.1em; }

.contacts__info-city-time {
  margin-top: 10px; }
  @media (max-width: 1366px) {
    .contacts__info-city-time {
      margin-top: calc(5px + 5 * ((100vw - 375px) / 1046)); } }

.contacts__info-city-time-text {
  font-weight: 700; }

.contacts__top {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 25px; }
  @media (max-width: 1366px) {
    .contacts__top {
      margin-top: calc(12.5px + 12.5 * ((100vw - 375px) / 1046)); } }

.contacts__top-left {
  flex: 1 1 45%;
  margin-right: 10px;
  display: flex;
  flex-direction: column; }
  @media (max-width: 1366px) {
    .contacts__top-left {
      margin-right: calc(5px + 5 * ((100vw - 375px) / 1046)); } }

.contacts__top-right {
  flex: 1 1 45%;
  display: flex;
  flex-direction: column;
  align-items: flex-end; }

.contacts__feedback-mail {
  font-weight: 700; }

.contacts__feedback-phone {
  font-weight: 700; }

.contacts__social-networks {
  margin-top: auto; }

.contacts__main {
  margin-top: 20px;
  display: flex; }
  @media (max-width: 1366px) {
    .contacts__main {
      margin-top: calc(10px + 10 * ((100vw - 375px) / 1046)); } }

.contacts__main-actions {
  flex: 1 1 20%;
  margin-right: 45px;
  display: flex;
  flex-direction: column; }
  @media (max-width: 1366px) {
    .contacts__main-actions {
      margin-right: calc(22.5px + 22.5 * ((100vw - 375px) / 1046)); } }
  .contacts__main-actions .Button:last-child {
    margin-top: auto; }

.contacts__main-map {
  height: 450px;
  flex: 1 1 60%; }
  @media (max-width: 1366px) {
    .contacts__main-map {
      height: calc(200px + 250 * ((100vw - 375px) / 1046)); } }

.contacts__main-actions-text {
  margin-top: 20px; }
  @media (max-width: 1366px) {
    .contacts__main-actions-text {
      margin-top: calc(10px + 10 * ((100vw - 375px) / 1046)); } }

.contacts .fancybox-is-open .fancybox-bg {
  opacity: 0; }

#contacts-page ~ .fancybox-is-open .fancybox-bg {
  opacity: 0; }

.hero {
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: black;
  padding-top: 25px;
  padding-bottom: 25px; }
  .hero .container {
    overflow: unset; }
  .hero__bottom {
    margin-top: auto;
    height: 28px;
    position: relative; }
  .hero__bottom-dec {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 100%; }
  .hero__bottom-line {
    background-color: var(--color-a);
    width: 139.2vw;
    height: 100%;
    position: absolute;
    left: -100vw;
    z-index: -1; }
  .hero__bottom-wrapper {
    display: flex;
    background-color: var(--color-a);
    height: 100%;
    justify-content: space-between; }
  @media (max-width: 1366px) {
    .hero {
      padding-top: calc(12.5px + 12.5 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    .hero {
      padding-bottom: calc(12.5px + 12.5 * ((100vw - 375px) / 1046)); } }
  .hero__desc {
    width: 50%;
    margin-right: 50px; }
    @media (max-width: 1366px) {
      .hero__desc {
        margin-right: calc(25px + 25 * ((100vw - 375px) / 1046)); } }
    .hero__desc .Button {
      margin-top: 25px; }
      @media (max-width: 1366px) {
        .hero__desc .Button {
          margin-top: calc(12.5px + 12.5 * ((100vw - 375px) / 1046)); } }
  .hero__desc-subtitle {
    font-size: 24px;
    margin-top: 10px; }
    @media (max-width: 1366px) {
      .hero__desc-subtitle {
        font-size: calc(14px + 10 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .hero__desc-subtitle {
        margin-top: calc(5px + 5 * ((100vw - 375px) / 1046)); } }
  .hero__desc-title {
    font-size: 56px;
    font-weight: 700; }
    @media (max-width: 1366px) {
      .hero__desc-title {
        font-size: calc(32px + 24 * ((100vw - 375px) / 1046)); } }
  .hero__footnote {
    font-size: 16px;
    margin-top: 10px; }
    @media (max-width: 1366px) {
      .hero__footnote {
        font-size: calc(14px + 2 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .hero__footnote {
        margin-top: calc(5px + 5 * ((100vw - 375px) / 1046)); } }
  .hero__img {
    width: 50%; }
  .hero__logo-picture .Picture__block-img {
    padding-bottom: 84%; }
  .hero__wrapper {
    display: flex;
    border-width: 0 1px 0 1px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.25); }

.hero-slider {
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  z-index: 0; }
  .hero-slider__container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center; }
  .hero-slider__wrapper {
    width: 100%;
    height: 100%;
    backdrop-filter: brightness(0.5);
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    position: relative; }
  .hero-slider__title {
    color: var(--color-c);
    font-size: 40px;
    font-weight: 600; }
    @media (max-width: 1366px) {
      .hero-slider__title {
        font-size: calc(20px + 20 * ((100vw - 375px) / 1046)); } }
  .hero-slider .swiper-slide {
    overflow: hidden;
    color: #fff; }
  .hero-slider .swiper-container {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; }
  .hero-slider .slide-inner {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left; }
  .hero-slider .slide-bg-image .container {
    backdrop-filter: brightness(0.7); }
  .hero-slider__pagination {
    position: absolute;
    z-index: 21;
    right: 20px;
    width: 11px !important;
    text-align: center;
    left: auto !important;
    top: 50%;
    bottom: auto !important;
    transform: translateY(-50%); }
    .hero-slider__pagination.swiper-pagination-bullets .swiper-pagination-bullet {
      margin: 8px 0; }
    .hero-slider__pagination .swiper-pagination-bullet {
      width: 15px;
      margin-top: 5px;
      height: 15px;
      display: block;
      border-radius: 10px;
      background: #2f3030a0;
      opacity: 1;
      transition: all 0.3s; }
      .hero-slider__pagination .swiper-pagination-bullet-active {
        filter: brightness(1.5);
        opacity: 1;
        background: var(--color-a);
        height: 30px;
        box-shadow: 0px 0px 20px rgba(252, 56, 56, 0.3); }

/*--------------------------------------------------------------
	#hero-style
--------------------------------------------------------------*/
.hero-style {
  height: 100vh;
  transition: all 0.4s ease; }
  .hero-style .slide-title h2 {
    font-size: 100px;
    font-weight: 600;
    line-height: 1;
    color: #ffffff;
    margin: 0 0 40px;
    text-transform: capitalize;
    transition: all 0.4s ease; }
  .hero-style .slide-text p {
    opacity: 0.8;
    font-family: Rajdhani;
    font-size: 32px;
    font-weight: 500;
    line-height: 1.25;
    color: #ffffff;
    margin: 0 0 40px;
    transition: all 0.4s ease; }
  .hero-style .slide-btns > a:first-child {
    margin-right: 10px; }
  @media (max-width: 991px) {
    .hero-style .hero-style {
      height: 600px; } }
  @media (max-width: 767px) {
    .hero-style .hero-style {
      height: 500px; } }
  @media screen and (min-width: 992px) {
    .hero-style .hero-style .container {
      padding-top: 95px; } }
  @media (max-width: 1199px) {
    .hero-style .hero-style .slide-title h2 {
      font-size: 75px; } }
  @media (max-width: 991px) {
    .hero-style .hero-style .slide-title h2 {
      font-size: 50px;
      margin: 0 0 35px; } }
  @media (max-width: 767px) {
    .hero-style .hero-style .slide-title h2 {
      font-size: 35px;
      margin: 0 0 30px; } }
  @media (max-width: 767px) {
    .hero-style .hero-style .slide-text p {
      font-size: 16px;
      font-size: 1rem;
      font-weight: normal;
      margin: 0 0 30px; } }

.about-us__img {
  margin-top: 50px; }
  @media (max-width: 1366px) {
    .about-us__img {
      margin-top: calc(25px + 25 * ((100vw - 375px) / 1046)); } }
  .about-us__img .Picture__block-img {
    padding-bottom: 71%; }

.about-us__left .Title {
  justify-content: flex-start; }

.about-us__list {
  max-width: 343px;
  margin-top: 20px;
  margin-bottom: 20px; }
  @media (max-width: 1366px) {
    .about-us__list {
      max-width: calc(250px + 93 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    .about-us__list {
      margin-top: calc(10px + 10 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    .about-us__list {
      margin-bottom: calc(10px + 10 * ((100vw - 375px) / 1046)); } }
  .about-us__list .item-about-us {
    display: flex;
    justify-content: space-between;
    font-size: 24px;
    border-bottom: 1px solid var(--color-b); }
    @media (max-width: 1366px) {
      .about-us__list .item-about-us {
        font-size: calc(14px + 10 * ((100vw - 375px) / 1046)); } }
    .about-us__list .item-about-us:not(:first-child) {
      margin-top: 20px; }
      @media (max-width: 1366px) {
        .about-us__list .item-about-us:not(:first-child) {
          margin-top: calc(10px + 10 * ((100vw - 375px) / 1046)); } }
    .about-us__list .item-about-us__count {
      font-size: 72px;
      display: flex;
      color: var(--color-a);
      line-height: 0.93; }
      @media (max-width: 1366px) {
        .about-us__list .item-about-us__count {
          font-size: calc(50px + 22 * ((100vw - 375px) / 1046)); } }
      .about-us__list .item-about-us__count span {
        color: var(--color-a); }
    .about-us__list .item-about-us__text {
      display: flex;
      align-items: flex-end;
      max-width: 145px; }
      @media (max-width: 1366px) {
        .about-us__list .item-about-us__text {
          max-width: calc(100px + 45 * ((100vw - 375px) / 1046)); } }

.about-us__right {
  max-width: 475px; }

.about-us__text {
  margin-top: 20px; }
  @media (max-width: 1366px) {
    .about-us__text {
      margin-top: calc(10px + 10 * ((100vw - 375px) / 1046)); } }

.about-us__wrapper {
  margin-top: 100px;
  padding-bottom: 60px;
  border-bottom: 1px solid var(--color-e);
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 80px; }
  @media (max-width: 1366px) {
    .about-us__wrapper {
      margin-top: calc(50px + 50 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    .about-us__wrapper {
      padding-bottom: calc(30px + 30 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    .about-us__wrapper {
      gap: calc(40px + 40 * ((100vw - 375px) / 1046)); } }

.portfolio {
  /* ====================================
General Styles
==================================== */
  /* ====================================
Home Slider
==================================== */
  /* Swiper css file. Only thing changed down here is the pagination styling */
  /**
 * Swiper 3.4.1
 * Most modern mobile touch slider and framework with hardware accelerated transitions
 * 
 * http://www.idangero.us/swiper/
 * 
 * Copyright 2016, Vladimir Kharlampidi
 * The iDangero.us
 * http://www.idangero.us/
 * 
 * Licensed under MIT
 * 
 * Released on: December 13, 2016
 */
  /* Auto Height */
  /* a11y */
  /* IE10 Windows Phone 8 Fixes */
  /* Arrows */
  /* Pagination Styles */
  /* Common Styles */
  /* Bullets */
  /* Progress */
  /* 3D Container */
  /* Coverflow */
  /* Cube + Flip */
  /* Cube */
  /* Fade */
  /* Scrollbar */
  /* Preloader */ }
  .portfolio .page-wrap {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to right bottom, #2a1f30 50%, #383d44 50%); }
  .portfolio #home-slider {
    height: 100vh;
    width: 100vw;
    position: relative; }
  .portfolio .swiper-container {
    height: 100%;
    width: 100%; }
  .portfolio .swiper-image-one {
    background-image: url(https://source.unsplash.com/GXOGGENgHnQ/1600x900); }
  .portfolio .swiper-image-two {
    background-image: url(https://source.unsplash.com/Jy6luiLBsrk/1600x900); }
  .portfolio .swiper-image-three {
    background-image: url(https://source.unsplash.com/sFLVTqNzG2I/1600x900); }
  .portfolio .swiper-image-four {
    background-image: url(https://source.unsplash.com/pj1jt4vBGZY/1600x900); }
  .portfolio .swiper-image-five {
    background-image: url(https://source.unsplash.com/Jy6luiLBsrk/1600x900); }
  .portfolio .swiper-image-six {
    background-image: url(https://source.unsplash.com/bxCgan0J1Sg/1600x900); }
  .portfolio .swiper-image {
    width: 50%;
    height: 100%; }
  .portfolio .swiper-image-inner {
    background-size: cover;
    background-position: center center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    background-blend-mode: overlay; }
  .portfolio .swiper-image-inner.swiper-image-left {
    padding-top: 85px;
    padding-bottom: 85px;
    padding-left: 40px;
    padding-right: 40px; }
    @media (max-width: 1366px) {
      .portfolio .swiper-image-inner.swiper-image-left {
        padding-top: calc(42.5px + 42.5 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .portfolio .swiper-image-inner.swiper-image-left {
        padding-bottom: calc(42.5px + 42.5 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .portfolio .swiper-image-inner.swiper-image-left {
        padding-left: calc(20px + 20 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .portfolio .swiper-image-inner.swiper-image-left {
        padding-right: calc(20px + 20 * ((100vw - 375px) / 1046)); } }
  .portfolio .swiper-image-left {
    filter: sepia(100%);
    transition: all 1s linear;
    transition-delay: 1s; }
  .portfolio .swiper-slide.swiper-slide-active .swiper-image-left {
    filter: sepia(0%);
    transition: all 1s linear;
    transition-delay: 1s;
    background-color: rgba(0, 0, 0, 0.75);
    background-blend-mode: multiply; }
  .portfolio .swiper-image-inner.swiper-image-right {
    transition: all 1s linear;
    transition-delay: 1s;
    background-color: rgba(0, 0, 0, 0.5);
    background-blend-mode: multiply; }
  .portfolio .portfolio__title-slide {
    font-weight: 400;
    color: #fff;
    text-align: left;
    align-self: flex-start;
    font-size: 4.5rem;
    line-height: 1;
    transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) 1.1s;
    transform: translate3d(-20%, 0, 0);
    opacity: 0; }
  .portfolio .portfolio__title-slide span.emphasis {
    font-weight: 400;
    font-style: italic;
    opacity: 0;
    transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) 1.2s;
    transform: translate3d(-20%, 0, 0);
    display: inline-block; }
  .portfolio .portfolio__title-slide span:not(.emphasis) {
    font-size: 3.5rem; }
  .portfolio .portfolio__title-slide + p {
    font-family: 'Cormorand SC';
    font-size: 14px;
    letter-spacing: 2px;
    margin: 0;
    line-height: 1;
    margin-bottom: auto;
    align-self: flex-end;
    text-transform: uppercase;
    transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s;
    transform: translate3d(-20%, 0, 0);
    opacity: 0;
    font-weight: 500;
    color: #fff;
    padding-right: 8rem; }
  .portfolio p.paragraph {
    margin: 0;
    color: var(--color-c);
    width: 100%;
    max-width: 415px;
    line-height: 140%;
    font-size: 20px;
    font-weight: 400;
    opacity: 0;
    transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) 1.4s;
    transform: translate3d(-20%, 0, 0); }
    @media (max-width: 1366px) {
      .portfolio p.paragraph {
        max-width: calc(155px + 260 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .portfolio p.paragraph {
        font-size: calc(14px + 6 * ((100vw - 375px) / 1046)); } }
  .portfolio .swiper-slide.swiper-slide-active h2,
  .portfolio .swiper-slide.swiper-slide-active p.paragraph,
  .portfolio .swiper-slide.swiper-slide-active .portfolio__title-slide span.emphasis,
  .portfolio .swiper-slide.swiper-slide-active .portfolio__title-slide + p,
  .portfolio .swiper-slide.swiper-slide-active .portfolio__list {
    transform: translate3d(0, 0, 0);
    opacity: 1; }
  .portfolio .swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    /* Fix of Webkit flickering */
    z-index: 1; }
  .portfolio .swiper-container-no-flexbox .swiper-slide {
    float: left; }
  .portfolio .swiper-container-vertical > .swiper-wrapper {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column; }
  .portfolio .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
    -ms-transition-property: -ms-transform;
    transition-property: transform;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box; }
  .portfolio .swiper-container-android .swiper-slide,
  .portfolio .swiper-wrapper {
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    -o-transform: translate(0px, 0px);
    -ms-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0); }
  .portfolio .swiper-container-multirow > .swiper-wrapper {
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap; }
  .portfolio .swiper-container-free-mode > .swiper-wrapper {
    -webkit-transition-timing-function: ease-out;
    -moz-transition-timing-function: ease-out;
    -ms-transition-timing-function: ease-out;
    -o-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    margin: 0 auto; }
  .portfolio .swiper-slide {
    -webkit-flex-shrink: 0;
    -ms-flex: 0 0 auto;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #fff;
    overflow: hidden;
    display: flex; }
  .portfolio .swiper-container-autoheight,
  .portfolio .swiper-container-autoheight .swiper-slide {
    height: auto; }
  .portfolio .swiper-container-autoheight .swiper-wrapper {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-transition-property: -webkit-transform, height;
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
    -ms-transition-property: -ms-transform;
    transition-property: transform, height; }
  .portfolio .swiper-container .swiper-notification {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    opacity: 0;
    z-index: -1000; }
  .portfolio .swiper-wp8-horizontal {
    -ms-touch-action: pan-y;
    touch-action: pan-y; }
  .portfolio .swiper-wp8-vertical {
    -ms-touch-action: pan-x;
    touch-action: pan-x; }
  .portfolio .swiper-button-prev,
  .portfolio .swiper-button-next {
    position: absolute;
    top: 50%;
    width: 27px;
    height: 44px;
    margin-top: -22px;
    z-index: 10;
    cursor: pointer;
    -moz-background-size: 27px 44px;
    -webkit-background-size: 27px 44px;
    background-size: 27px 44px;
    background-position: center;
    background-repeat: no-repeat; }
  .portfolio .swiper-button-prev.swiper-button-disabled,
  .portfolio .swiper-button-next.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none; }
  .portfolio .swiper-button-prev,
  .portfolio .swiper-container-rtl .swiper-button-next {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
    left: 10px;
    right: auto; }
  .portfolio .swiper-button-prev.swiper-button-black,
  .portfolio .swiper-container-rtl .swiper-button-next.swiper-button-black {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); }
  .portfolio .swiper-button-prev.swiper-button-white,
  .portfolio .swiper-container-rtl .swiper-button-next.swiper-button-white {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); }
  .portfolio .swiper-button-next,
  .portfolio .swiper-container-rtl .swiper-button-prev {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
    right: 10px;
    left: auto; }
  .portfolio .swiper-button-next.swiper-button-black,
  .portfolio .swiper-container-rtl .swiper-button-prev.swiper-button-black {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); }
  .portfolio .swiper-button-next.swiper-button-white,
  .portfolio .swiper-container-rtl .swiper-button-prev.swiper-button-white {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); }
  .portfolio .swiper-pagination {
    position: absolute;
    text-align: center;
    -webkit-transition: 300ms;
    -moz-transition: 300ms;
    -o-transition: 300ms;
    transition: 300ms;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    z-index: 10; }
  .portfolio .swiper-pagination.swiper-pagination-hidden {
    opacity: 0; }
  .portfolio .swiper-pagination-fraction,
  .portfolio .swiper-pagination-custom,
  .portfolio .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 10px;
    left: 0;
    width: 100%; }
  .portfolio .swiper-pagination-bullet {
    width: 14px;
    height: 14px;
    display: inline-block;
    background: #fff;
    opacity: 0.4; }
  .portfolio button.swiper-pagination-bullet {
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    appearance: none; }
  .portfolio .swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer; }
  .portfolio .swiper-pagination-white .swiper-pagination-bullet {
    background: #fff; }
  .portfolio .swiper-pagination-bullet-active {
    opacity: 1;
    background: #fff; }
  .portfolio .swiper-pagination-white .swiper-pagination-bullet-active {
    background: #fff; }
  .portfolio .swiper-pagination-black .swiper-pagination-bullet-active {
    background: #000; }
  .portfolio .swiper-container-vertical > .swiper-pagination-bullets {
    right: 15px;
    bottom: 15px; }
  .portfolio .swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 5px 0;
    display: block; }
  .portfolio .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 5px; }
  .portfolio .swiper-pagination-progress {
    background: rgba(0, 0, 0, 0.25);
    position: absolute; }
  .portfolio .swiper-pagination-progress .swiper-pagination-progressbar {
    background: #007aff;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: left top;
    -moz-transform-origin: left top;
    -ms-transform-origin: left top;
    -o-transform-origin: left top;
    transform-origin: left top; }
  .portfolio .swiper-container-rtl .swiper-pagination-progress .swiper-pagination-progressbar {
    -webkit-transform-origin: right top;
    -moz-transform-origin: right top;
    -ms-transform-origin: right top;
    -o-transform-origin: right top;
    transform-origin: right top; }
  .portfolio .swiper-container-horizontal > .swiper-pagination-progress {
    width: 100%;
    height: 4px;
    left: 0;
    top: 0; }
  .portfolio .swiper-container-vertical > .swiper-pagination-progress {
    width: 4px;
    height: 100%;
    left: 0;
    top: 0; }
  .portfolio .swiper-pagination-progress.swiper-pagination-white {
    background: rgba(255, 255, 255, 0.5); }
  .portfolio .swiper-pagination-progress.swiper-pagination-white .swiper-pagination-progressbar {
    background: #fff; }
  .portfolio .swiper-pagination-progress.swiper-pagination-black .swiper-pagination-progressbar {
    background: #000; }
  .portfolio .swiper-container-3d {
    -webkit-perspective: 1200px;
    -moz-perspective: 1200px;
    -o-perspective: 1200px;
    perspective: 1200px; }
  .portfolio .swiper-container-3d .swiper-wrapper,
  .portfolio .swiper-container-3d .swiper-slide,
  .portfolio .swiper-container-3d .swiper-slide-shadow-left,
  .portfolio .swiper-container-3d .swiper-slide-shadow-right,
  .portfolio .swiper-container-3d .swiper-slide-shadow-top,
  .portfolio .swiper-container-3d .swiper-slide-shadow-bottom,
  .portfolio .swiper-container-3d .swiper-cube-shadow {
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d; }
  .portfolio .swiper-container-3d .swiper-slide-shadow-left,
  .portfolio .swiper-container-3d .swiper-slide-shadow-right,
  .portfolio .swiper-container-3d .swiper-slide-shadow-top,
  .portfolio .swiper-container-3d .swiper-slide-shadow-bottom {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 10; }
  .portfolio .swiper-container-3d .swiper-slide-shadow-left {
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
    /* Safari 4+, Chrome */
    background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Chrome 10+, Safari 5.1+, iOS 5+ */
    background-image: -moz-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Firefox 3.6-15 */
    background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Opera 11.10-12.00 */
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Firefox 16+, IE10, Opera 12.50+ */ }
  .portfolio .swiper-container-3d .swiper-slide-shadow-right {
    background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
    /* Safari 4+, Chrome */
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Chrome 10+, Safari 5.1+, iOS 5+ */
    background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Firefox 3.6-15 */
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Opera 11.10-12.00 */
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Firefox 16+, IE10, Opera 12.50+ */ }
  .portfolio .swiper-container-3d .swiper-slide-shadow-top {
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
    /* Safari 4+, Chrome */
    background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Chrome 10+, Safari 5.1+, iOS 5+ */
    background-image: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Firefox 3.6-15 */
    background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Opera 11.10-12.00 */
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Firefox 16+, IE10, Opera 12.50+ */ }
  .portfolio .swiper-container-3d .swiper-slide-shadow-bottom {
    background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
    /* Safari 4+, Chrome */
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Chrome 10+, Safari 5.1+, iOS 5+ */
    background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Firefox 3.6-15 */
    background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Opera 11.10-12.00 */
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Firefox 16+, IE10, Opera 12.50+ */ }
  .portfolio .swiper-container-coverflow .swiper-wrapper,
  .portfolio .swiper-container-flip .swiper-wrapper {
    /* Windows 8 IE 10 fix */
    -ms-perspective: 1200px; }
  .portfolio .swiper-container-cube,
  .portfolio .swiper-container-flip {
    overflow: visible; }
  .portfolio .swiper-container-cube .swiper-slide,
  .portfolio .swiper-container-flip .swiper-slide {
    pointer-events: none;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 1; }
  .portfolio .swiper-container-cube .swiper-slide .swiper-slide,
  .portfolio .swiper-container-flip .swiper-slide .swiper-slide {
    pointer-events: none; }
  .portfolio .swiper-container-cube .swiper-slide-active,
  .portfolio .swiper-container-flip .swiper-slide-active,
  .portfolio .swiper-container-cube .swiper-slide-active .swiper-slide-active,
  .portfolio .swiper-container-flip .swiper-slide-active .swiper-slide-active {
    pointer-events: auto; }
  .portfolio .swiper-container-cube .swiper-slide-shadow-top,
  .portfolio .swiper-container-flip .swiper-slide-shadow-top,
  .portfolio .swiper-container-cube .swiper-slide-shadow-bottom,
  .portfolio .swiper-container-flip .swiper-slide-shadow-bottom,
  .portfolio .swiper-container-cube .swiper-slide-shadow-left,
  .portfolio .swiper-container-flip .swiper-slide-shadow-left,
  .portfolio .swiper-container-cube .swiper-slide-shadow-right,
  .portfolio .swiper-container-flip .swiper-slide-shadow-right {
    z-index: 0;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden; }
  .portfolio .swiper-container-cube .swiper-slide {
    visibility: hidden;
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    width: 100%;
    height: 100%; }
  .portfolio .swiper-container-cube.swiper-container-rtl .swiper-slide {
    -webkit-transform-origin: 100% 0;
    -moz-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0; }
  .portfolio .swiper-container-cube .swiper-slide-active,
  .portfolio .swiper-container-cube .swiper-slide-next,
  .portfolio .swiper-container-cube .swiper-slide-prev,
  .portfolio .swiper-container-cube .swiper-slide-next + .swiper-slide {
    pointer-events: auto;
    visibility: visible; }
  .portfolio .swiper-container-cube .swiper-cube-shadow {
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.6;
    -webkit-filter: blur(50px);
    filter: blur(50px);
    z-index: 0; }
  .portfolio .swiper-container-fade.swiper-container-free-mode .swiper-slide {
    -webkit-transition-timing-function: ease-out;
    -moz-transition-timing-function: ease-out;
    -ms-transition-timing-function: ease-out;
    -o-transition-timing-function: ease-out;
    transition-timing-function: ease-out; }
  .portfolio .swiper-container-fade .swiper-slide {
    pointer-events: none;
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity; }
  .portfolio .swiper-container-fade .swiper-slide .swiper-slide {
    pointer-events: none; }
  .portfolio .swiper-container-fade .swiper-slide-active,
  .portfolio .swiper-container-fade .swiper-slide-active .swiper-slide-active {
    pointer-events: auto; }
  .portfolio .swiper-zoom-container {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    text-align: center; }
  .portfolio .swiper-zoom-container > img,
  .portfolio .swiper-zoom-container > svg,
  .portfolio .swiper-zoom-container > canvas {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; }
  .portfolio .swiper-scrollbar {
    border-radius: 10px;
    position: relative;
    -ms-touch-action: none;
    background: rgba(0, 0, 0, 0.1); }
  .portfolio .swiper-container-horizontal > .swiper-scrollbar {
    position: absolute;
    left: 1%;
    bottom: 3px;
    z-index: 50;
    height: 5px;
    width: 98%; }
  .portfolio .swiper-container-vertical > .swiper-scrollbar {
    position: absolute;
    right: 3px;
    top: 1%;
    z-index: 50;
    width: 5px;
    height: 98%; }
  .portfolio .swiper-scrollbar-drag {
    height: 100%;
    width: 100%;
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    left: 0;
    top: 0; }
  .portfolio .swiper-scrollbar-cursor-drag {
    cursor: move; }
  .portfolio .swiper-lazy-preloader {
    width: 42px;
    height: 42px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -21px;
    margin-top: -21px;
    z-index: 10;
    -webkit-transform-origin: 50%;
    -moz-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
    -moz-animation: swiper-preloader-spin 1s steps(12, end) infinite;
    animation: swiper-preloader-spin 1s steps(12, end) infinite; }
  .portfolio .swiper-lazy-preloader:after {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
    background-position: 50%;
    -webkit-background-size: 100%;
    background-size: 100%;
    background-repeat: no-repeat; }
  .portfolio .swiper-lazy-preloader-white:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); }

@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg); } }

.portfolio {
  margin-top: 60px; }
  @media (max-width: 1366px) {
    .portfolio {
      margin-top: calc(30px + 30 * ((100vw - 375px) / 1046)); } }
  .portfolio__list {
    transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) 1.1s;
    transform: translate3d(-20%, 0, 0);
    opacity: 0; }
  .portfolio__item {
    position: relative; }
    .portfolio__item:not(:first-child) {
      margin-top: 10px; }
      @media (max-width: 1366px) {
        .portfolio__item:not(:first-child) {
          margin-top: calc(5px + 5 * ((100vw - 375px) / 1046)); } }
    .portfolio__item::after {
      content: '·';
      position: absolute;
      color: var(--color-c);
      left: -20px;
      top: 0;
      width: 20px;
      height: 20px; }
  .portfolio__title {
    margin-bottom: 40px; }
    @media (max-width: 1366px) {
      .portfolio__title {
        margin-bottom: calc(20px + 20 * ((100vw - 375px) / 1046)); } }
  .portfolio__title-slide {
    align-self: flex-start;
    color: var(--color-c);
    font-size: 38px !important; }
  .portfolio .swiper-image-left {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end; }

.projects {
  margin-top: 120px; }
  @media (max-width: 1366px) {
    .projects {
      margin-top: calc(60px + 60 * ((100vw - 375px) / 1046)); } }
  .projects__button {
    margin-top: 20px; }
    @media (max-width: 1366px) {
      .projects__button {
        margin-top: calc(10px + 10 * ((100vw - 375px) / 1046)); } }
  .projects__right {
    max-width: 645px;
    width: 100%;
    align-self: flex-end; }
  .projects__right-subtitle {
    margin-bottom: 20px; }
    @media (max-width: 1366px) {
      .projects__right-subtitle {
        margin-bottom: calc(10px + 10 * ((100vw - 375px) / 1046)); } }
  .projects__title {
    margin-top: 80px; }
    @media (max-width: 1366px) {
      .projects__title {
        margin-top: calc(40px + 40 * ((100vw - 375px) / 1046)); } }
  .projects__wrapper {
    display: flex;
    justify-content: space-between;
    min-height: 820px; }

.advantages {
  background-color: var(--color-d); }
  .advantages__button {
    display: flex;
    justify-content: center;
    margin-top: 75px; }
    @media (max-width: 1366px) {
      .advantages__button {
        margin-top: calc(37.5px + 37.5 * ((100vw - 375px) / 1046)); } }
    .advantages__button .Button--color-clear .Button__text {
      color: var(--color-c);
      max-width: 144px; }
      @media (max-width: 1366px) {
        .advantages__button .Button--color-clear .Button__text {
          max-width: calc(124px + 20 * ((100vw - 375px) / 1046)); } }
  .advantages__contacts {
    display: flex;
    justify-content: center; }
  .advantages__wrapper {
    padding-top: 65px;
    padding-bottom: 45px;
    margin-top: 115px; }
    @media (max-width: 1366px) {
      .advantages__wrapper {
        padding-top: calc(32.5px + 32.5 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .advantages__wrapper {
        padding-bottom: calc(22.5px + 22.5 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .advantages__wrapper {
        margin-top: calc(57.5px + 57.5 * ((100vw - 375px) / 1046)); } }
    .advantages__wrapper .Title {
      justify-content: flex-start; }
  .advantages .dropdown {
    margin-top: 25px; }
    @media (max-width: 1366px) {
      .advantages .dropdown {
        margin-top: calc(12.5px + 12.5 * ((100vw - 375px) / 1046)); } }

.partners__item {
  width: 100%;
  height: 100%; }

.partners__list {
  margin-top: 65px; }
  @media (max-width: 1366px) {
    .partners__list {
      margin-top: calc(32.5px + 32.5 * ((100vw - 375px) / 1046)); } }
  .partners__list--grid-g {
    gap: 30px; }
    @media (max-width: 1366px) {
      .partners__list--grid-g {
        gap: calc(15px + 15 * ((100vw - 375px) / 1046)); } }

.partners__wrapper {
  margin-top: 120px; }
  @media (max-width: 1366px) {
    .partners__wrapper {
      margin-top: calc(60px + 60 * ((100vw - 375px) / 1046)); } }

.info--a .info__bottom {
  margin-top: auto;
  position: relative; }

.info--a .info__bottom-line {
  background-color: var(--color-a);
  width: 50vw;
  height: 100%;
  position: absolute;
  left: -50vw;
  z-index: -1; }

.info--a .info__bottom-wrapper {
  display: flex;
  background-color: var(--color-a);
  justify-content: space-between; }

.info--a .info__nav-list-btns {
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-c); }
  @media (max-width: 1366px) {
    .info--a .info__nav-list-btns {
      width: calc(60px + 120 * ((100vw - 375px) / 1046)); } }
  .info--a .info__nav-list-btns .Icon__svg {
    stroke: var(--color-a); }

.info--a .info__nav-list-inner {
  display: flex;
  border-bottom: 1px solid #dbdbdb; }

.info--a .info__filter {
  width: 100vw;
  height: 100vh; }

.info--a .info__heading {
  margin-top: 35px; }
  @media (max-width: 1366px) {
    .info--a .info__heading {
      margin-top: calc(17.5px + 17.5 * ((100vw - 375px) / 1046)); } }

.info--a .info__inner {
  padding-top: 20vh;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column; }

.info--a .info__nav-item:not(:last-child) {
  padding-right: 30px;
  border-right: 1px solid #ac5f64; }
  @media (max-width: 1366px) {
    .info--a .info__nav-item:not(:last-child) {
      padding-right: calc(15px + 15 * ((100vw - 375px) / 1046)); } }

.info--a .info__nav-list {
  gap: 30px;
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  padding-bottom: 50px; }
  @media (max-width: 1366px) {
    .info--a .info__nav-list {
      gap: calc(15px + 15 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    .info--a .info__nav-list {
      padding-top: calc(10px + 10 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    .info--a .info__nav-list {
      padding-bottom: calc(25px + 25 * ((100vw - 375px) / 1046)); } }

.info--a .info__wrapper {
  width: 100vw;
  height: 100vh;
  backdrop-filter: brightness(0.5);
  position: relative; }

.info--a .item-nav-info__content {
  font-size: 24px;
  color: var(--color-c);
  display: flex;
  flex-direction: column; }
  @media (max-width: 1366px) {
    .info--a .item-nav-info__content {
      font-size: calc(14px + 10 * ((100vw - 375px) / 1046)); } }
  .info--a .item-nav-info__content:last-child {
    margin-top: 15px; }
    @media (max-width: 1366px) {
      .info--a .item-nav-info__content:last-child {
        margin-top: calc(7.5px + 7.5 * ((100vw - 375px) / 1046)); } }

.info--b .info__bottom {
  margin-top: auto;
  height: 28px;
  position: relative; }

.info--b .info__bottom-dec {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%; }

.info--b .info__bottom-line {
  background-color: var(--color-a);
  width: 139.2vw;
  height: 100%;
  position: absolute;
  left: -100vw;
  z-index: -1; }

.info--b .info__bottom-wrapper {
  display: flex;
  background-color: var(--color-a);
  height: 100%;
  justify-content: space-between; }

.info__bottom {
  margin-top: auto;
  position: relative; }

.info__bottom-line {
  background-color: var(--color-a);
  width: 50vw;
  height: 100%;
  position: absolute;
  left: -50vw;
  z-index: -1; }

.info__bottom-wrapper {
  display: flex;
  background-color: var(--color-a);
  justify-content: space-between; }

.info__bottom-dec {
  display: flex;
  justify-content: flex-end;
  width: 100%; }

.info__subtitle {
  margin-top: 5px; }
  @media (max-width: 1366px) {
    .info__subtitle {
      margin-top: calc(2.5px + 2.5 * ((100vw - 375px) / 1046)); } }

.info__nav-list-btns {
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-c); }
  @media (max-width: 1366px) {
    .info__nav-list-btns {
      width: calc(60px + 120 * ((100vw - 375px) / 1046)); } }
  .info__nav-list-btns .Icon__svg {
    stroke: var(--color-a); }

.info__nav-list-inner {
  display: flex;
  border-bottom: 1px solid #dbdbdb;
  cursor: pointer; }

.info__filter {
  width: 100vw;
  height: 100vh; }

.info__heading {
  margin-top: 50px; }
  @media (max-width: 1366px) {
    .info__heading {
      margin-top: calc(25px + 25 * ((100vw - 375px) / 1046)); } }

.info__inner {
  padding-top: 140px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column; }
  @media (max-width: 1366px) {
    .info__inner {
      padding-top: calc(165pxpx + -25px * ((100vw - 375px) / 1046)); } }

.info__desc {
  padding-top: 20px;
  padding-bottom: 30px; }
  @media (max-width: 1366px) {
    .info__desc {
      padding-top: calc(10px + 10 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    .info__desc {
      padding-bottom: calc(15px + 15 * ((100vw - 375px) / 1046)); } }
  .info__desc .Title {
    justify-content: flex-start;
    font-weight: 500;
    margin-bottom: 5px; }
    @media (max-width: 1366px) {
      .info__desc .Title {
        margin-bottom: calc(2.5px + 2.5 * ((100vw - 375px) / 1046)); } }

.info__nav-item:not(:last-child) {
  padding-right: 30px;
  border-right: 1px solid #ac5f64; }
  @media (max-width: 1366px) {
    .info__nav-item:not(:last-child) {
      padding-right: calc(15px + 15 * ((100vw - 375px) / 1046)); } }

.info__nav-list {
  gap: 30px;
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  padding-bottom: 50px; }
  @media (max-width: 1366px) {
    .info__nav-list {
      gap: calc(15px + 15 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    .info__nav-list {
      padding-top: calc(10px + 10 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    .info__nav-list {
      padding-bottom: calc(25px + 25 * ((100vw - 375px) / 1046)); } }

.info__wrapper {
  width: 100%;
  height: 340px;
  backdrop-filter: brightness(0.5);
  position: relative; }

.detailed__desc {
  flex: 1 1;
  flex-basis: calc(657 / 1366 * 100%); }
  .detailed__desc .detailed__title {
    justify-content: flex-start; }

.detailed__item {
  margin-top: 20px; }
  @media (max-width: 1366px) {
    .detailed__item {
      margin-top: calc(10px + 10 * ((100vw - 375px) / 1046)); } }

.detailed__item-text {
  line-height: 1.4; }

.detailed__picture {
  flex: 1 1;
  flex-basis: calc(575 / 1366 * 100%);
  position: relative;
  z-index: -1; }
  .detailed__picture .Picture__block-img {
    padding-bottom: unset; }
  .detailed__picture .Picture__img {
    position: unset;
    pointer-events: none;
    max-height: 730px; }
    @media (max-width: 1366px) {
      .detailed__picture .Picture__img {
        max-height: calc(436px + 294 * ((100vw - 375px) / 1046)); } }

.detailed__wrapper {
  margin-top: 100px;
  display: flex;
  gap: 130px; }
  @media (max-width: 1366px) {
    .detailed__wrapper {
      margin-top: calc(50px + 50 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    .detailed__wrapper {
      gap: calc(30px + 100 * ((100vw - 375px) / 1046)); } }

.gallery--project .gallery__list-area {
  gap: 30px;
  margin-top: 95px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 315px) repeat(2, 400px); }
  @media (max-width: 1366px) {
    .gallery--project .gallery__list-area {
      gap: calc(8px + 22 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    .gallery--project .gallery__list-area {
      margin-top: calc(47.5px + 47.5 * ((100vw - 375px) / 1046)); } }

.gallery--project .gallery__item-area:nth-child(1) {
  grid-area: 1 / 1 / 3 / 2; }

.gallery--project .gallery__item-area:nth-child(2) {
  grid-area: 1 / 2 / 2 / 3; }

.gallery--project .gallery__item-area:nth-child(3) {
  grid-area: 2 / 2 / 3 / 3; }

.gallery--project .gallery__item-area:nth-child(4) {
  grid-area: 3 / 1 / 4 / 3; }

.gallery--project .gallery__item-area:nth-child(5) {
  grid-area: 4 / 1 / 5 / 3; }

.gallery--project .gallery__item-area:nth-child(6) {
  grid-area: 5 / 1 / 6 / 2; }

.gallery--project .gallery__item-area:nth-child(7) {
  grid-area: 4 / 2 / 6 / 3; }

.gallery--project .gallery__item-area:nth-child(8) {
  grid-area: 6 / 1 / 7 / 3; }

.gallery--project .gallery__item-area img {
  object-fit: cover;
  width: 100%;
  height: 100%; }

.gallery--projects {
  margin-top: 100px; }
  @media (max-width: 1366px) {
    .gallery--projects {
      margin-top: calc(50px + 50 * ((100vw - 375px) / 1046)); } }
  .gallery--projects .gallery__list-area {
    display: grid;
    gap: 30px; }
    .gallery--projects .gallery__list-area:not(:first-child) {
      margin-top: 30px; }
      @media (max-width: 1366px) {
        .gallery--projects .gallery__list-area:not(:first-child) {
          margin-top: calc(15px + 15 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .gallery--projects .gallery__list-area {
        gap: calc(8px + 22 * ((100vw - 375px) / 1046)); } }
  .gallery--projects .gallery__item-area:last-child {
    aspect-ratio: 1/1 !important; }
  .gallery--projects .gallery__item-area {
    max-height: 669px; }
    .gallery--projects .gallery__item-area img {
      width: 100%;
      height: 100%;
      aspect-ratio: 2.4/1;
      object-fit: cover; }
    .gallery--projects .gallery__item-area:nth-child(1) {
      grid-area: 1 / 1 / 2 / 3; }
      .gallery--projects .gallery__item-area:nth-child(1) img {
        width: 100%;
        height: 100%;
        aspect-ratio: 16/5.5;
        object-fit: cover; }
    .gallery--projects .gallery__item-area:nth-child(2) {
      grid-area: 2 / 1 / 4 / 2; }
      .gallery--projects .gallery__item-area:nth-child(2) img {
        height: 100%;
        aspect-ratio: 1/1;
        width: 100%;
        object-fit: cover; }
    .gallery--projects .gallery__item-area:nth-child(3) {
      grid-area: 2 / 2 / 3 / 3; }
      .gallery--projects .gallery__item-area:nth-child(3) img {
        width: 100%;
        height: 100%;
        aspect-ratio: 16/7.55;
        object-fit: cover; }
    .gallery--projects .gallery__item-area:nth-child(4) {
      grid-area: 3 / 2 / 4 / 3; }
      .gallery--projects .gallery__item-area:nth-child(4) img {
        width: 100%;
        height: 100%;
        aspect-ratio: 16/7.55;
        object-fit: cover; }
    .gallery--projects .gallery__item-area:nth-child(5) {
      grid-area: 4 / 1 / 5 / 2; }
      .gallery--projects .gallery__item-area:nth-child(5) img {
        width: 100%;
        height: 100%;
        aspect-ratio: 16/7.55;
        object-fit: cover; }
    .gallery--projects .gallery__item-area:nth-child(6) {
      grid-area: 5 / 1 / 6 / 2; }
      .gallery--projects .gallery__item-area:nth-child(6) img {
        width: 100%;
        height: 100%;
        aspect-ratio: 16/7.55;
        object-fit: cover; }
    .gallery--projects .gallery__item-area:nth-child(7) {
      grid-area: 4 / 2 / 6 / 3; }
      .gallery--projects .gallery__item-area:nth-child(7) img {
        object-fit: cover;
        height: 100%;
        aspect-ratio: 1/1;
        width: 100%; }
    .gallery--projects .gallery__item-area img {
      object-fit: cover; }
  .gallery--projects .gallery__wrapper {
    min-height: 50vh; }
  .gallery--projects .gallery__button {
    display: flex;
    justify-content: center;
    margin-top: 50px; }
    @media (max-width: 1366px) {
      .gallery--projects .gallery__button {
        margin-top: calc(25px + 25 * ((100vw - 375px) / 1046)); } }
  .gallery--projects .gallery__list-area--filter-error {
    height: 40vh;
    width: 100%; }
  .gallery--projects .gallery__list-area .error__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%; }
  .gallery--projects .gallery__list-area .error__text {
    font-size: 20px; }
    @media (max-width: 1366px) {
      .gallery--projects .gallery__list-area .error__text {
        font-size: calc(14px + 6 * ((100vw - 375px) / 1046)); } }
    .gallery--projects .gallery__list-area .error__text .text-error__type {
      font-weight: 600; }

@media (max-width: 500px) {
  .gallery--projects .gallery__item-area:last-child {
    aspect-ratio: unset !important; }
  .gallery--projects .gallery__list-area {
    grid-template-columns: 1fr; }
  .gallery--projects .gallery__item-area:nth-child(1) {
    grid-area: unset; }
    .gallery--projects .gallery__item-area:nth-child(1) img {
      aspect-ratio: unset; }
  .gallery--projects .gallery__item-area:nth-child(2) {
    grid-area: unset; }
    .gallery--projects .gallery__item-area:nth-child(2) img {
      aspect-ratio: unset; }
  .gallery--projects .gallery__item-area:nth-child(3) {
    grid-area: unset; }
    .gallery--projects .gallery__item-area:nth-child(3) img {
      aspect-ratio: unset; }
  .gallery--projects .gallery__item-area:nth-child(4) {
    grid-area: unset; }
    .gallery--projects .gallery__item-area:nth-child(4) img {
      aspect-ratio: unset; }
  .gallery--projects .gallery__item-area:nth-child(5) {
    grid-area: unset; }
    .gallery--projects .gallery__item-area:nth-child(5) img {
      aspect-ratio: unset; }
  .gallery--projects .gallery__item-area:nth-child(6) {
    grid-area: unset; }
    .gallery--projects .gallery__item-area:nth-child(6) img {
      aspect-ratio: unset; }
  .gallery--projects .gallery__item-area:nth-child(7) {
    grid-area: unset; }
    .gallery--projects .gallery__item-area:nth-child(7) img {
      aspect-ratio: unset; }
  .gallery--project .gallery__list-area {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: unset; }
  .gallery--project .gallery__item-area:nth-child(1) {
    grid-area: unset; }
    .gallery--project .gallery__item-area:nth-child(1) img {
      aspect-ratio: unset; }
  .gallery--project .gallery__item-area:nth-child(2) {
    grid-area: unset; }
    .gallery--project .gallery__item-area:nth-child(2) img {
      aspect-ratio: unset; }
  .gallery--project .gallery__item-area:nth-child(3) {
    grid-area: unset; }
    .gallery--project .gallery__item-area:nth-child(3) img {
      aspect-ratio: unset; }
  .gallery--project .gallery__item-area:nth-child(4) {
    grid-area: unset; }
    .gallery--project .gallery__item-area:nth-child(4) img {
      aspect-ratio: unset; }
  .gallery--project .gallery__item-area:nth-child(5) {
    grid-area: unset; }
    .gallery--project .gallery__item-area:nth-child(5) img {
      aspect-ratio: unset; }
  .gallery--project .gallery__item-area:nth-child(6) {
    grid-area: unset; }
    .gallery--project .gallery__item-area:nth-child(6) img {
      aspect-ratio: unset; }
  .gallery--project .gallery__item-area:nth-child(7) {
    grid-area: unset; }
    .gallery--project .gallery__item-area:nth-child(7) img {
      aspect-ratio: unset; } }

.services__item .Picture__block-img {
  padding-bottom: 100%; }

.services__list {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(315px, 1fr));
  gap: 30px; }
  @media (max-width: 1366px) {
    .services__list {
      margin-top: calc(20px + 20 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    .services__list {
      gap: calc(15px + 15 * ((100vw - 375px) / 1046)); } }

.services__text {
  max-width: 1004px; }

.services__wrapper {
  margin-top: 50px; }
  @media (max-width: 1366px) {
    .services__wrapper {
      margin-top: calc(25px + 25 * ((100vw - 375px) / 1046)); } }

.section-advantages {
  margin-top: 130px; }
  @media (max-width: 1366px) {
    .section-advantages {
      margin-top: calc(40px + 90 * ((100vw - 375px) / 1046)); } }
  .section-advantages__wrapper {
    padding-bottom: 40px; }
  .section-advantages__button {
    justify-content: center;
    display: flex;
    align-items: center;
    margin-top: 30px; }
    @media (max-width: 1366px) {
      .section-advantages__button {
        margin-top: calc(15px + 15 * ((100vw - 375px) / 1046)); } }
  .section-advantages [class^='swiper-button-'], .section-advantages .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .section-advantages .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet::before {
    transition: all 0.3s ease; }
  .section-advantages__swiper-container {
    margin-top: 40px;
    max-width: 900px; }
  .section-advantages__text {
    max-width: 1033px;
    margin: 0 auto;
    text-align: center; }
  .section-advantages .swiper-container {
    width: 100%;
    height: 100%;
    transition: opacity 0.6s ease; }
    .section-advantages .swiper-container.swiper-container-coverflow {
      padding-top: 40px; }
      @media (max-width: 1366px) {
        .section-advantages .swiper-container.swiper-container-coverflow {
          padding-top: calc(20px + 20 * ((100vw - 375px) / 1046)); } }
    .section-advantages .swiper-container.loading {
      opacity: 0;
      visibility: hidden; }
    .section-advantages .swiper-container:hover .swiper-button-prev,
    .section-advantages .swiper-container:hover .swiper-button-next {
      transform: translateX(0);
      opacity: 1;
      visibility: visible; }
  .section-advantages .swiper-slide {
    background-position: center;
    background-size: cover; }
    .section-advantages .swiper-slide .entity-img {
      display: none; }
    .section-advantages .swiper-slide .content {
      position: absolute;
      top: 40%;
      left: 0;
      width: 50%;
      padding-left: 5%;
      color: #fff; }
      .section-advantages .swiper-slide .content .title {
        font-size: 2.6em;
        font-weight: bold;
        margin-bottom: 30px; }
      .section-advantages .swiper-slide .content .caption {
        display: block;
        font-size: 13px;
        line-height: 1.4; }
  .section-advantages [class^='swiper-button-'] {
    width: 44px;
    opacity: 0;
    visibility: hidden; }
  .section-advantages .swiper-button-prev {
    transform: translateX(50px);
    color: var(--color-a); }
  .section-advantages .swiper-button-next {
    transform: translateX(-50px);
    color: var(--color-a); }
  .section-advantages .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 9px;
    position: relative;
    width: 12px;
    height: 12px;
    background-color: var(--color-a);
    opacity: 0.4; }
    .section-advantages .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 18px;
      height: 18px;
      transform: translate(-50%, -50%);
      border: 0px solid #fff;
      border-radius: 50%; }
    .section-advantages .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet:hover, .section-advantages .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
      opacity: 1; }
    .section-advantages .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active::before {
      border-width: 1px; }
  @media (max-width: 1180px) {
    .section-advantages .swiper-slide .content .title {
      font-size: 25px; }
    .section-advantages .swiper-slide .content .caption {
      font-size: 12px; } }
  @media (max-width: 1023px) {
    .section-advantages .swiper-container.swiper-container-coverflow {
      padding-top: 0; } }
  .section-advantages .Title {
    margin-bottom: 25px; }
    @media (max-width: 1366px) {
      .section-advantages .Title {
        margin-bottom: calc(20px + 5 * ((100vw - 375px) / 1046)); } }

.section-advantages-swiper-slide {
  height: 480px;
  position: relative; }
  @media (max-width: 1366px) {
    .section-advantages-swiper-slide {
      height: calc(230px + 250 * ((100vw - 375px) / 1046)); } }

.section-advantages .section-advantages-swiper-slide::before {
  transition: 0.3s all;
  content: ' ';
  position: absolute;
  width: 100%;
  height: 70%;
  bottom: 0;
  z-index: 1;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.86) 0%, rgba(0, 0, 0, 0.692708) 30.73%, rgba(0, 0, 0, 0.57) 59.91%, rgba(0, 0, 0, 0) 100%);
  pointer-events: none;
  transform: rotate(-180deg); }

.section-advantages .slide-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left; }

.section-advantages-slider__wrapper {
  height: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 40px;
  padding-right: 40px;
  padding: 100px 40px 100px 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; }
  @media (max-width: 1366px) {
    .section-advantages-slider__wrapper {
      padding-top: calc(30px + 70 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    .section-advantages-slider__wrapper {
      padding-bottom: calc(30px + 70 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    .section-advantages-slider__wrapper {
      padding-left: calc(15px + 25 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    .section-advantages-slider__wrapper {
      padding-right: calc(15px + 25 * ((100vw - 375px) / 1046)); } }

.section-advantages-slider__container {
  padding: 0;
  position: relative;
  min-width: 100% !important; }

.section-advantages-slider__title {
  font-weight: 500;
  font-size: 28px;
  line-height: 125%;
  color: #ffffff;
  z-index: 3;
  position: relative; }
  @media (max-width: 1366px) {
    .section-advantages-slider__title {
      font-size: calc(12px + 16 * ((100vw - 375px) / 1046)); } }

.section-advantages-slider__title {
  transition: 0.3s all; }

.section-advantages-swiper-slide.swiper-slide-prev::before, .section-advantages-swiper-slide.swiper-slide-next::before {
  content: '';
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  bottom: 0 !important;
  z-index: 1 !important;
  background: #ffffff !important;
  opacity: 0.7 !important; }

.section-advantages-swiper-slide:not(.swiper-slide-active)::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  z-index: 1;
  background: #d9d9d9;
  opacity: 0.5; }

.vacancy__text {
  margin-top: 100px; }
  @media (max-width: 1366px) {
    .vacancy__text {
      margin-top: calc(50px + 50 * ((100vw - 375px) / 1046)); } }

.vacancy__dropdown {
  margin-top: 130px; }
  @media (max-width: 1366px) {
    .vacancy__dropdown {
      margin-top: calc(65px + 65 * ((100vw - 375px) / 1046)); } }

.privacy-policy {
  margin-top: 100px; }
  .privacy-policy * {
    font-size: 20px;
    line-height: 28px; }
    @media (max-width: 1366px) {
      .privacy-policy * {
        font-size: calc(14px + 6 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .privacy-policy * {
        line-height: calc(20px + 8 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    .privacy-policy {
      margin-top: calc(25px + 75 * ((100vw - 375px) / 1046)); } }
  .privacy-policy__text {
    margin-top: 25px; }
    @media (max-width: 1366px) {
      .privacy-policy__text {
        margin-top: calc(12.5px + 12.5 * ((100vw - 375px) / 1046)); } }
    .privacy-policy__text strong {
      font-weight: 700; }
  .privacy-policy__list {
    list-style-type: decimal;
    margin-left: 30px; }
    @media (max-width: 1366px) {
      .privacy-policy__list {
        margin-left: calc(20px + 10 * ((100vw - 375px) / 1046)); } }

.introduction__item .Picture__block-img {
  padding-bottom: 100%; }

.introduction__list {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(315px, 1fr));
  gap: 30px; }
  @media (max-width: 1366px) {
    .introduction__list {
      margin-top: calc(20px + 20 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    .introduction__list {
      gap: calc(15px + 15 * ((100vw - 375px) / 1046)); } }

.introduction__text {
  max-width: 1004px; }

.introduction__wrapper {
  margin-top: 100px; }
  @media (max-width: 1366px) {
    .introduction__wrapper {
      margin-top: calc(50px + 50 * ((100vw - 375px) / 1046)); } }

.administration {
  background-color: var(--color-d); }
  .administration__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(430px, 1fr));
    justify-content: center;
    gap: 260px; }
    @media (max-width: 1366px) {
      .administration__list {
        gap: calc(130px + 130 * ((100vw - 375px) / 1046)); } }
    .administration__list .item-administration__fio {
      font-size: 20px;
      color: var(--color-c); }
      @media (max-width: 1366px) {
        .administration__list .item-administration__fio {
          font-size: calc(16px + 4 * ((100vw - 375px) / 1046)); } }
    .administration__list .item-administration__heading {
      margin-top: 30px;
      margin-bottom: 60px; }
      @media (max-width: 1366px) {
        .administration__list .item-administration__heading {
          margin-top: calc(15px + 15 * ((100vw - 375px) / 1046)); } }
      @media (max-width: 1366px) {
        .administration__list .item-administration__heading {
          margin-bottom: calc(30px + 30 * ((100vw - 375px) / 1046)); } }
    .administration__list .item-administration__img .Picture__block-img {
      padding-bottom: 100%; }
    .administration__list .item-administration__text {
      margin-top: 12px; }
      @media (max-width: 1366px) {
        .administration__list .item-administration__text {
          margin-top: calc(10px + 2 * ((100vw - 375px) / 1046)); } }
    .administration__list .item-administration__title {
      color: var(--color-c);
      font-size: 24px; }
      @media (max-width: 1366px) {
        .administration__list .item-administration__title {
          font-size: calc(20px + 4 * ((100vw - 375px) / 1046)); } }
  .administration__wrapper {
    margin-top: 60px;
    padding-top: 65px;
    padding-bottom: 65px; }
    @media (max-width: 1366px) {
      .administration__wrapper {
        margin-top: calc(30px + 30 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .administration__wrapper {
        padding-top: calc(32.5px + 32.5 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .administration__wrapper {
        padding-bottom: calc(32.5px + 32.5 * ((100vw - 375px) / 1046)); } }

.contacts__wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 100px; }
  @media (max-width: 1366px) {
    .contacts__wrapper {
      margin-top: calc(50px + 50 * ((100vw - 375px) / 1046)); } }

.contacts__list-info {
  display: flex;
  flex-direction: column; }

.contacts__inner {
  height: 100%;
  max-width: 40%; }

.contacts .Item-list {
  display: flex;
  flex-direction: column; }
  .contacts .Item-list:not(:last-child) {
    margin-bottom: 25px; }
    @media (max-width: 1366px) {
      .contacts .Item-list:not(:last-child) {
        margin-bottom: calc(12.5px + 12.5 * ((100vw - 375px) / 1046)); } }
  .contacts .Item-list__place {
    font-size: 32px;
    color: var(--color-a); }
    @media (max-width: 1366px) {
      .contacts .Item-list__place {
        font-size: calc(16px + 16 * ((100vw - 375px) / 1046)); } }
  .contacts .Item-list__work-time, .contacts .Item-list__contacts {
    font-size: 24px; }
    @media (max-width: 1366px) {
      .contacts .Item-list__work-time, .contacts .Item-list__contacts {
        font-size: calc(16px + 8 * ((100vw - 375px) / 1046)); } }
    .contacts .Item-list__work-time:not(:last-child), .contacts .Item-list__contacts:not(:last-child) {
      margin-bottom: 8px; }
  .contacts .Item-list__link, .contacts .Item-list__desc {
    font-size: 20px; }
    @media (max-width: 1366px) {
      .contacts .Item-list__link, .contacts .Item-list__desc {
        font-size: calc(14px + 6 * ((100vw - 375px) / 1046)); } }
  .contacts .Item-list__link {
    width: max-content;
    padding: 0 10px;
    border-bottom: 1px solid var(--color-a); }

.contacts__map {
  width: 690px;
  height: 690px; }
  @media (max-width: 1366px) {
    .contacts__map {
      width: calc(342px + 348 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    .contacts__map {
      height: calc(342px + 348 * ((100vw - 375px) / 1046)); } }
  .contacts__map .ymaps-2-1-79-balloon__content {
    margin-right: 0 !important;
    padding: 10px 12px !important; }
  .contacts__map .ymaps-2-1-79-balloon__close {
    display: none; }
  .contacts__map .ymaps__balloon-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%; }
    .contacts__map .ymaps__balloon-content > svg {
      width: 80px;
      height: 80px;
      stroke: black; }
  .contacts__map .ymaps-2-1-79-controls__toolbar_left,
  .contacts__map .ymaps-2-1-79-controls__toolbar_right,
  .contacts__map .ymaps-2-1-79-copyrights-pane {
    display: none; }

@media (max-width: 787px) {
  .contacts__wrapper {
    flex-direction: column;
    align-items: center; }
  .contacts__list-info {
    width: 100%;
    margin-bottom: 40px; } }

.process--b .process__list {
  margin-top: 50px; }
  @media (max-width: 1366px) {
    .process--b .process__list {
      margin-top: calc(25px + 25 * ((100vw - 375px) / 1046)); } }
  .process--b .process__list .item-process {
    grid-template-columns: 1fr;
    padding-top: 60px;
    padding-bottom: 60px; }
    @media (max-width: 1366px) {
      .process--b .process__list .item-process {
        padding-top: calc(30px + 30 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .process--b .process__list .item-process {
        padding-bottom: calc(30px + 30 * ((100vw - 375px) / 1046)); } }
    .process--b .process__list .item-process__title, .process--b .process__list .item-process__text {
      text-align: left;
      justify-content: flex-start;
      line-height: 1.4; }
    .process--b .process__list .item-process__title {
      margin-bottom: 20px; }
      @media (max-width: 1366px) {
        .process--b .process__list .item-process__title {
          margin-bottom: calc(10px + 10 * ((100vw - 375px) / 1046)); } }
    .process--b .process__list .item-process__wrap {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 470px;
      gap: 80px;
      align-items: start; }
      @media (max-width: 1366px) {
        .process--b .process__list .item-process__wrap {
          gap: calc(40px + 40 * ((100vw - 375px) / 1046)); } }
    .process--b .process__list .item-process:nth-child(odd) {
      background-color: var(--color-d); }
      .process--b .process__list .item-process:nth-child(odd) .item-process__heading .item-process__title,
      .process--b .process__list .item-process:nth-child(odd) .item-process__heading .item-process__text {
        color: var(--color-c); }
      .process--b .process__list .item-process:nth-child(odd) .Card-img {
        order: 1; }
    .process--b .process__list .item-process:nth-child(even) .item-process__heading .item-process__title {
      color: var(--color-a); }
  .process--b .process__list .Card-img {
    height: 100%; }
    .process--b .process__list .Card-img__bg-picture {
      height: 100%; }
  .process--b .process__list .Picture__block-img {
    padding-bottom: unset;
    height: 100%; }

.process--a .process__list {
  margin-top: 60px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px; }
  @media (max-width: 1366px) {
    .process--a .process__list {
      margin-top: calc(30px + 30 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    .process--a .process__list {
      gap: calc(15px + 15 * ((100vw - 375px) / 1046)); } }
  .process--a .process__list .item-process {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 400px;
    align-items: center;
    gap: 45px; }
    .process--a .process__list .item-process:nth-child(even) .Card-img {
      order: 1; }
    @media (max-width: 1366px) {
      .process--a .process__list .item-process {
        gap: calc(22.5px + 22.5 * ((100vw - 375px) / 1046)); } }
    .process--a .process__list .item-process .Card-img {
      height: 100%; }
      .process--a .process__list .item-process .Card-img__bg-picture {
        height: 100%; }
    .process--a .process__list .item-process .Picture__block-img {
      padding-bottom: unset;
      height: 100%; }

.process--a .process__wrapper {
  margin-top: 60px; }
  @media (max-width: 1366px) {
    .process--a .process__wrapper {
      margin-top: calc(30px + 30 * ((100vw - 375px) / 1046)); } }

.describe__text br {
  height: 10px; }

.describe__picture {
  margin-top: 40px;
  margin-bottom: 40px; }
  @media (max-width: 1366px) {
    .describe__picture {
      margin-top: calc(20px + 20 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    .describe__picture {
      margin-bottom: calc(20px + 20 * ((100vw - 375px) / 1046)); } }

.describe__wrapper {
  display: grid;
  grid-template-columns: 150px 1fr;
  padding-top: 40px;
  margin-top: 100px;
  border-top: 1px solid var(--color-h);
  gap: 115px; }
  @media (max-width: 1366px) {
    .describe__wrapper {
      padding-top: calc(20px + 20 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    .describe__wrapper {
      margin-top: calc(50px + 50 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    .describe__wrapper {
      gap: calc(57.5px + 57.5 * ((100vw - 375px) / 1046)); } }

.blog__btns {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (max-width: 1366px) {
    .blog__btns {
      margin-top: calc(20px + 20 * ((100vw - 375px) / 1046)); } }

.blog__list .item-blog {
  display: grid;
  grid-template-columns: 200px 1fr;
  border-top: 1px solid var(--color-h);
  align-items: center;
  min-height: 180px; }
  .blog__list .item-blog:last-child {
    border-bottom: 1px solid var(--color-h); }
  .blog__list .item-blog__content {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    cursor: pointer;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center; }
    @media (max-width: 1366px) {
      .blog__list .item-blog__content {
        padding-left: calc(15px + 15 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .blog__list .item-blog__content {
        padding-right: calc(15px + 15 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .blog__list .item-blog__content {
        padding-top: calc(15px + 15 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .blog__list .item-blog__content {
        padding-bottom: calc(15px + 15 * ((100vw - 375px) / 1046)); } }
    .blog__list .item-blog__content:hover > .item-blog__content-picture {
      transition: 0.3s opacity;
      opacity: 1; }
    .blog__list .item-blog__content:hover > .item-blog__content-text {
      color: var(--color-a); }
  .blog__list .item-blog__content-text {
    max-width: 585px;
    display: block; }
    @media (max-width: 1366px) {
      .blog__list .item-blog__content-text {
        max-width: calc(400px + 185 * ((100vw - 375px) / 1046)); } }
  .blog__list .item-blog__content-picture {
    transition: 0.3s opacity;
    width: 460px;
    width: 460px;
    position: absolute;
    right: 20px;
    z-index: -1;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0; }
    @media (max-width: 1366px) {
      .blog__list .item-blog__content-picture {
        width: calc(0px + 460 * ((100vw - 375px) / 1046)); } }
    .blog__list .item-blog__content-picture .Picture__block-img {
      padding-bottom: 60%; }
  .blog__list .item-blog__date {
    padding-left: 35px;
    padding-right: 35px;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-right: 1px solid var(--color-h); }
    @media (max-width: 1366px) {
      .blog__list .item-blog__date {
        padding-left: calc(17.5px + 17.5 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .blog__list .item-blog__date {
        padding-right: calc(17.5px + 17.5 * ((100vw - 375px) / 1046)); } }
  .blog__list .item-blog__date-text {
    font-weight: 500; }
  .blog__list .item-blog__month-text {
    margin-left: -11px;
    font-weight: 500; }

.blog__wrapper {
  margin-top: 100px; }
  @media (max-width: 1366px) {
    .blog__wrapper {
      margin-top: calc(50px + 50 * ((100vw - 375px) / 1046)); } }

.Hamb__bar {
  display: block;
  width: 40px;
  height: 4px;
  background-color: var(--color-c);
  transition: 0.2s; }
  .Hamb__bar:not(:first-child) {
    margin-top: 6px; }
  .Hamb__bar:nth-child(2) {
    width: 30px; }
  .Hamb__bar:nth-child(3) {
    width: 20px; }

.Hamb__field {
  cursor: pointer; }

.Hamb__field.active .Hamb__bar:nth-child(2) {
  opacity: 0;
  width: 40px; }

.Hamb__field.active .Hamb__bar:nth-child(1) {
  transform: translateY(12px) rotate(45deg);
  width: 40px; }

.Hamb__field.active .Hamb__bar:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
  width: 40px; }

.Feedback__address-title {
  font-size: 16px;
  color: var(--color-c);
  line-height: 1.6; }
  @media (max-width: 1366px) {
    .Feedback__address-title {
      font-size: calc(14px + 2 * ((100vw - 375px) / 1046)); } }

.Feedback__mail {
  margin-top: 5px; }
  @media (max-width: 1366px) {
    .Feedback__mail {
      margin-top: calc(2.5px + 2.5 * ((100vw - 375px) / 1046)); } }

:root {
  --swiper-theme-color: var(--color-a); }

.swiper-container-horizontal > .swiper-pagination-bullets {
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px; }
  @media (max-width: 1366px) {
    .swiper-container-horizontal > .swiper-pagination-bullets {
      margin-top: calc(5px + 5 * ((100vw - 375px) / 1046)); } }

.swiper-pagination {
  text-align: center; }

.swiper-container-horizontal > .swiper-scrollbar {
  height: 4px;
  width: 305px;
  left: 50%;
  transform: translateX(-50%); }

.swiper-scrollbar {
  border-radius: unset; }

.swiper-scrollbar-drag {
  border-radius: unset;
  background: var(--color-a); }

.swiper-button-next {
  left: auto;
  right: 40px; }
  @media (max-width: 1366px) {
    .swiper-button-next {
      right: calc(5px + 35 * ((100vw - 375px) / 1046)); } }

.swiper-button-prev {
  right: auto;
  left: 40px; }
  @media (max-width: 1366px) {
    .swiper-button-prev {
      left: calc(5px + 35 * ((100vw - 375px) / 1046)); } }

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 50px; }
  @media (max-width: 1366px) {
    .swiper-button-prev::after,
    .swiper-button-next::after {
      font-size: calc(25px + 25 * ((100vw - 375px) / 1046)); } }

.Nav__nav {
  display: flex;
  justify-content: center; }

.Nav__list {
  display: flex;
  justify-content: space-between; }
  .Nav__list .item-nav {
    border-left: 1px solid #4f4f4f;
    font-weight: 500; }
    .Nav__list .item-nav__link {
      text-transform: none; }
      .Nav__list .item-nav__link--active {
        color: var(--color-a); }
    .Nav__list .item-nav--mob {
      display: none; }

.Nav__button {
  opacity: 0;
  visibility: hidden; }

.Nav__main {
  position: fixed;
  left: 0;
  top: 86px;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  height: 100vh;
  background-color: var(--color-d);
  z-index: 100;
  display: flex;
  flex-direction: column; }
  @media (max-width: 1366px) {
    .Nav__main {
      top: calc(81px + 5 * ((100vw - 375px) / 1046)); } }
  .Nav__main ul {
    overflow-x: hidden; }
  .Nav__main-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 15px 40px 15px; }
  .Nav__main-social-networks {
    width: 100%;
    margin-top: 25px; }
  .Nav__main.open {
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    opacity: 1;
    pointer-events: auto;
    padding-right: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
  .Nav__main .Nav__list {
    order: -1;
    width: 100%;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 15px; }
    .Nav__main .Nav__list > li {
      width: 170px;
      padding-left: 20px; }
      .Nav__main .Nav__list > li > a {
        width: 100%;
        display: flex;
        padding: 20px 0;
        font-size: 20px;
        font-weight: 400;
        color: var(--color-c);
        position: relative; }
        .Nav__main .Nav__list > li > a:hover::before {
          transition: 0.3s all;
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: -20px;
          height: 50%;
          border-left: 2px solid var(--color-a); }

.Nav-link__nav-item {
  text-transform: uppercase; }
  .Nav-link__nav-item:not(:last-child) {
    margin-right: 60px; }
    @media (max-width: 1366px) {
      .Nav-link__nav-item:not(:last-child) {
        margin-right: calc(30px + 30 * ((100vw - 375px) / 1046)); } }

.Nav-link__nav-list {
  display: flex; }

.Page-not-found__btn {
  display: flex;
  justify-content: center;
  margin-top: 30px; }
  @media (max-width: 1366px) {
    .Page-not-found__btn {
      margin-top: calc(15px + 15 * ((100vw - 375px) / 1046)); } }

.Page-not-found__content-desc-btn {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  @media (max-width: 1366px) {
    .Page-not-found__content-desc-btn {
      margin-top: calc(12.5px + 12.5 * ((100vw - 375px) / 1046)); } }

.Page-not-found__desc {
  text-align: center;
  position: relative;
  padding-top: 30px;
  font-size: 24px;
  line-height: 117%;
  max-width: 600px;
  text-align: left;
  color: var(--color-a);
  padding: 10px;
  text-align: center; }
  @media (max-width: 1366px) {
    .Page-not-found__desc {
      padding-top: calc(15px + 15 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    .Page-not-found__desc {
      font-size: calc(16px + 8 * ((100vw - 375px) / 1046)); } }

.Page-not-found__heading {
  color: var(--color-a);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }

.Page-not-found__subtitle {
  font-size: 168px;
  font-family: 'HelveticaNeueCyr', sans-serif;
  font-weight: 700;
  line-height: 120%;
  color: transparent;
  -webkit-background-clip: text;
  filter: brightness(0.5); }
  @media (max-width: 1366px) {
    .Page-not-found__subtitle {
      font-size: calc(83px + 85 * ((100vw - 375px) / 1046)); } }

.Page-not-found__subtitle-text {
  color: transparent;
  -webkit-background-clip: text; }

.Page-not-found__title {
  font-weight: 500;
  font-size: 38px;
  line-height: 122%;
  text-shadow: 5px 10px 20px rgba(0, 0, 0, 0.15);
  margin-bottom: 40px; }
  @media (max-width: 1366px) {
    .Page-not-found__title {
      font-size: calc(18px + 20 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    .Page-not-found__title {
      margin-bottom: calc(20px + 20 * ((100vw - 375px) / 1046)); } }

.Page-not-found__title-text {
  color: var(--color-b); }

.Page-not-found__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 50px;
  padding-right: 50px; }
  @media (max-width: 1366px) {
    .Page-not-found__wrapper {
      padding-top: calc(30px + 70 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    .Page-not-found__wrapper {
      padding-bottom: calc(30px + 70 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    .Page-not-found__wrapper {
      padding-left: calc(20px + 30 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    .Page-not-found__wrapper {
      padding-right: calc(20px + 30 * ((100vw - 375px) / 1046)); } }

.Page-not-found__home-link {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (max-width: 1366px) {
    .Page-not-found__home-link {
      margin-top: calc(12.5px + 12.5 * ((100vw - 375px) / 1046)); } }

.Page-not-found__home-link-link {
  display: flex; }
  .Page-not-found__home-link-link .Link__text {
    margin-left: 10px; }
  .Page-not-found__home-link-link .Link__icon {
    display: flex;
    order: -1;
    align-items: center; }
    .Page-not-found__home-link-link .Link__icon * {
      width: 27px;
      height: 5px; }
  .Page-not-found__home-link-link .Link__icon-svg {
    transform: rotateY(-180deg); }

.dropdown--vacancy .dropdown__list .item-dropdown--active .item-dropdown:last-child {
  border-bottom: 1px solid #ebebeb; }

.dropdown--vacancy .dropdown__list .item-dropdown--active .item-dropdown__visible .Icon__svg {
  stroke: var(--color-a); }

.dropdown--vacancy .dropdown__list .item-dropdown:not(:last-child) {
  border-bottom: 1px solid #ebebeb; }

.dropdown--vacancy .dropdown__list .item-dropdown:last-child {
  border-bottom: 1px solid #ebebeb; }

.dropdown--vacancy .dropdown__list .item-dropdown__visible {
  margin-top: 45px;
  margin-bottom: 15px; }
  @media (max-width: 1366px) {
    .dropdown--vacancy .dropdown__list .item-dropdown__visible {
      margin-top: calc(22.5px + 22.5 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    .dropdown--vacancy .dropdown__list .item-dropdown__visible {
      margin-bottom: calc(7.5px + 7.5 * ((100vw - 375px) / 1046)); } }
  .dropdown--vacancy .dropdown__list .item-dropdown__visible .Icon__svg {
    stroke: var(--color-a); }

.dropdown__button {
  display: flex;
  justify-content: center;
  margin-top: 40px; }
  @media (max-width: 1366px) {
    .dropdown__button {
      margin-top: calc(20px + 20 * ((100vw - 375px) / 1046)); } }

.dropdown .dropdown__list .item-dropdown__visible .Title {
  font-weight: 400; }

.dropdown__list .item-dropdown {
  border-bottom: 1px solid var(--color-c); }
  .dropdown__list .item-dropdown--active .item-dropdown__visible .Icon {
    transform: rotate(-180deg); }
    .dropdown__list .item-dropdown--active .item-dropdown__visible .Icon__svg {
      stroke: var(--color-c); }
    .dropdown__list .item-dropdown--active .item-dropdown__visible .Icon--color-f .Icon__svg {
      fill: var(--color-a); }
  .dropdown__list .item-dropdown__icon {
    margin-right: 60px; }
    @media (max-width: 1366px) {
      .dropdown__list .item-dropdown__icon {
        margin-right: calc(30px + 30 * ((100vw - 375px) / 1046)); } }
  .dropdown__list .item-dropdown__hidden {
    display: none;
    margin-top: 35px;
    margin-bottom: 25px; }
    @media (max-width: 1366px) {
      .dropdown__list .item-dropdown__hidden {
        margin-top: calc(17.5px + 17.5 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .dropdown__list .item-dropdown__hidden {
        margin-bottom: calc(12.5px + 12.5 * ((100vw - 375px) / 1046)); } }
  .dropdown__list .item-dropdown__hidden-text {
    line-height: 140%;
    max-width: 1120px; }
  .dropdown__list .item-dropdown__visible {
    margin-top: 55px;
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    min-height: 72px; }
    @media (max-width: 1366px) {
      .dropdown__list .item-dropdown__visible {
        margin-top: calc(27.5px + 27.5 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .dropdown__list .item-dropdown__visible {
        margin-bottom: calc(12.5px + 12.5 * ((100vw - 375px) / 1046)); } }
    .dropdown__list .item-dropdown__visible .Icon {
      width: 25px;
      height: 25px;
      margin-left: 5px; }
      @media (max-width: 1366px) {
        .dropdown__list .item-dropdown__visible .Icon {
          margin-left: calc(2.5px + 2.5 * ((100vw - 375px) / 1046)); } }
      .dropdown__list .item-dropdown__visible .Icon__svg {
        stroke: var(--color-c); }
    .dropdown__list .item-dropdown__visible .Title {
      text-align: left; }
    .dropdown__list .item-dropdown__visible:hover .Title--color-f {
      color: var(--color-a); }
    .dropdown__list .item-dropdown__visible:hover .Icon--color-f .Icon__svg {
      fill: var(--color-a); }

.preloader--enabled {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-c);
  opacity: 1;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh; }
  .preloader--enabled .preloader__content {
    position: relative;
    width: 150px;
    height: 150px;
    margin: 30px auto;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: var(--color-f);
    animation: preloader-5-spin 2s linear infinite; }
    .preloader--enabled .preloader__content::before {
      content: "";
      position: absolute;
      top: 5px;
      left: 5px;
      right: 5px;
      bottom: 5px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: var(--color-a);
      animation: preloader-5-spin 3s linear infinite; }
    .preloader--enabled .preloader__content::after {
      content: "";
      position: absolute;
      top: 15px;
      left: 15px;
      right: 15px;
      bottom: 15px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: var(--color-f);
      animation: preloader-5-spin 1.5s linear infinite; }

.preloader--disabled {
  transition: opacity .3s;
  opacity: 0;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-c);
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh; }

@keyframes preloader-5-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.resume__button {
  margin-top: 55px; }
  @media (max-width: 1366px) {
    .resume__button {
      margin-top: calc(27.5px + 27.5 * ((100vw - 375px) / 1046)); } }

.resume__important {
  margin-top: 40px;
  font-weight: 500;
  color: var(--color-a);
  font-size: 24px; }
  @media (max-width: 1366px) {
    .resume__important {
      margin-top: calc(20px + 20 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    .resume__important {
      font-size: calc(16px + 8 * ((100vw - 375px) / 1046)); } }

.resume__requirements, .resume__responsibilities {
  flex: 1 1; }
  .resume__requirements .Title, .resume__responsibilities .Title {
    justify-content: flex-start; }

.resume__requirements {
  flex-basis: calc(515 / 1366 * 100%);
  border-right: 1px solid #ebebeb;
  padding-right: 50px;
  margin-right: 50px; }
  @media (max-width: 1366px) {
    .resume__requirements {
      padding-right: calc(25px + 25 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    .resume__requirements {
      margin-right: calc(25px + 25 * ((100vw - 375px) / 1046)); } }

.resume__responsibilities {
  flex-basis: calc(545 / 1366 * 100%); }

.resume__requirements-item:not(:first-child) {
  margin-top: 20px; }
  @media (max-width: 1366px) {
    .resume__requirements-item:not(:first-child) {
      margin-top: calc(10px + 10 * ((100vw - 375px) / 1046)); } }

.resume__requirements-list {
  margin-top: 20px; }
  @media (max-width: 1366px) {
    .resume__requirements-list {
      margin-top: calc(10px + 10 * ((100vw - 375px) / 1046)); } }

.resume__responsibilities-item, .resume__requirements-item {
  margin-top: 20px;
  position: relative;
  padding-left: 10px; }
  @media (max-width: 1366px) {
    .resume__responsibilities-item, .resume__requirements-item {
      margin-top: calc(10px + 10 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    .resume__responsibilities-item, .resume__requirements-item {
      padding-left: calc(5px + 5 * ((100vw - 375px) / 1046)); } }
  .resume__responsibilities-item::before, .resume__requirements-item::before {
    content: '-';
    position: absolute;
    left: 0; }

.resume__responsibilities-list {
  margin-top: 20px; }
  @media (max-width: 1366px) {
    .resume__responsibilities-list {
      margin-top: calc(10px + 10 * ((100vw - 375px) / 1046)); } }

.resume__wrapper {
  display: flex;
  margin-top: 35px; }
  @media (max-width: 1366px) {
    .resume__wrapper {
      margin-top: calc(17.5px + 17.5 * ((100vw - 375px) / 1046)); } }

.Card-d--teams .Card-d__wrapper .Picture__block-img {
  padding-bottom: 100%; }

.Card-d--teams .Card-d__wrapper .Picture__img {
  object-fit: cover; }

.Card-d__content {
  position: absolute;
  bottom: 0;
  background: var(--color-a);
  width: 100%;
  padding: 10px 20px 20px 20px;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transform: translateY(100%);
  transition: 0.3s all; }
  .Card-d__content .Subtitle,
  .Card-d__content .Title {
    font-style: normal;
    line-height: 112.3%;
    /* or 25px */
    margin-top: 5px;
    text-align: center;
    letter-spacing: 0.115em;
    text-transform: none;
    color: #ffffff; }
  .Card-d__content .Subtitle {
    font-weight: 400; }
  .Card-d__content .Title {
    font-weight: 700; }

.Card-d__content-title {
  justify-content: center; }

.Card-d__picture {
  filter: grayscale(100%);
  transition: 0.3s all; }

.Card-d .Picture__block-img {
  padding-bottom: 30.4%; }

.Card-d .Picture__img {
  object-fit: contain; }

.Card-d__wrapper {
  overflow: hidden;
  position: relative; }
  .Card-d__wrapper:hover > .Card-d__content {
    visibility: visible;
    opacity: 1;
    transform: translateY(0%); }
  .Card-d__wrapper:hover > .Card-d__picture {
    filter: unset; }

.Card-img--process {
  position: relative;
  overflow: hidden; }
  .Card-img--process::before {
    transition: 0.3s all;
    content: ' ';
    position: absolute;
    width: 100%;
    height: 70%;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.86) 0%, rgba(0, 0, 0, 0.692708) 30.73%, rgba(0, 0, 0, 0.57) 59.91%, rgba(0, 0, 0, 0) 100%);
    pointer-events: none;
    transform: rotate(-180deg); }
  .Card-img--process .Card-img__wrapper {
    height: 100%; }
  .Card-img--process .Card-img__inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 42px;
    padding-right: 42px; }
    @media (max-width: 1366px) {
      .Card-img--process .Card-img__inner {
        padding-top: calc(25px + 25 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .Card-img--process .Card-img__inner {
        padding-bottom: calc(25px + 25 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .Card-img--process .Card-img__inner {
        padding-left: calc(16px + 26 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .Card-img--process .Card-img__inner {
        padding-right: calc(16px + 26 * ((100vw - 375px) / 1046)); } }
  .Card-img--process .Card-img__desc {
    font-size: 24px;
    color: var(--color-c);
    position: relative;
    z-index: 3; }
    @media (max-width: 1366px) {
      .Card-img--process .Card-img__desc {
        font-size: calc(18px + 6 * ((100vw - 375px) / 1046)); } }

.Card-img__bg {
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none; }

.Card-img__bg-img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

.Card-img__desc {
  margin-top: 10px; }

.Card-img__desc-text:not(:first-child) {
  margin-top: 10px; }

.Card-img__heading {
  position: relative;
  display: flex; }

.Card-img__heading-icon {
  margin-right: 10px; }

.Card-img__title {
  font-size: 28px;
  color: var(--color-a); }

.Card-img__inner {
  padding: 45px 35px;
  height: 100%;
  width: 100%; }

.Card-img__wrapper {
  position: relative;
  background: var(--color-c); }

.Card-c {
  transition: 0.3s all;
  height: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-areas: 'main';
  overflow: hidden;
  cursor: pointer;
  text-decoration: none; }
  .Card-c a {
    display: block; }
  .Card-c > * {
    grid-area: main; }
  .Card-c > .Card-c__img {
    width: 100%;
    object-fit: cover;
    z-index: -1;
    position: relative;
    transition: 0.3s all; }
  .Card-c > .Card-c__title {
    opacity: 0;
    font-size: 38px;
    transition: opacity 400ms, transform 400ms;
    text-align: center;
    z-index: -1;
    position: relative; }
    @media (max-width: 1366px) {
      .Card-c > .Card-c__title {
        font-size: calc(20px + 18 * ((100vw - 375px) / 1046)); } }
  .Card-c > .Card-c__text {
    margin-top: auto;
    text-align: left;
    margin-bottom: 20px;
    transition: opacity 400ms, transform 400ms;
    font-size: 24px;
    opacity: 0;
    z-index: -1;
    position: relative; }
    @media (max-width: 1366px) {
      .Card-c > .Card-c__text {
        font-size: calc(14px + 10 * ((100vw - 375px) / 1046)); } }
  .Card-c:is(:hover, :focus) > .Card-c__img {
    transform: scale(1);
    filter: brightness(0.7); }
  .Card-c:is(:hover, :focus) > .Card-c__title,
  .Card-c:is(:hover, :focus) > .Card-c__text {
    transform: scale(0.9);
    opacity: 1; }
  .Card-c .Title--color-e,
  .Card-c .Text--color-e {
    color: var(--color-c); }

/*
// TODO: Cards
@import '../components/blocks/cards/card-a/card-a.scss';
@import '../components/blocks/cards/card-b/card-b.scss';
@import '../components/blocks/cards/card-c/card-c.scss';
@import '../components/blocks/cards/card-img/card-img.scss';
@import '../components/blocks/schedule/schedule.scss';
@import '../components/blocks/dropdown/dropdown.scss';
@import '../components/blocks/social-networks/social-networks.scss';
@import '../components/blocks/tabs/tabs.scss';

*/
.Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0;
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
  text-align: center;
  text-decoration-line: none;
  text-overflow: ellipsis;
  transition: 0.3s fill, 0.3s color, 0.3s background-color, 0.3s opacity;
  cursor: pointer;
  user-select: none; }
  .Button__icon {
    display: flex;
    transition: 0.3s fill, 0.3s color, 0.3s background-color, 0.3s opacity; }
  .Button__text {
    text-overflow: ellipsis;
    transition: 0.3s fill, 0.3s color, 0.3s background-color, 0.3s opacity; }
  .Button--decor {
    position: relative; }
    .Button--decor::before {
      content: '';
      position: absolute;
      top: 100%;
      display: block;
      width: 100%;
      height: 1px;
      background: var(--color-a);
      -webkit-transform: scale(0);
      -ms-transform: scale(0);
      transform: scale(0);
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s; }
    .Button--decor:hover::before {
      transform: scale(1);
      transition: 0.3s; }
  .Button--arrow {
    position: relative;
    width: 140px;
    justify-content: flex-end;
    position: relative; }
    @media (max-width: 1366px) {
      .Button--arrow {
        width: calc(70px + 70 * ((100vw - 375px) / 1046)); } }
    .Button--arrow::before {
      content: '';
      position: absolute;
      top: 100%;
      display: block;
      width: 100%;
      height: 1px;
      background: var(--color-a);
      -webkit-transform: scale(0);
      -ms-transform: scale(0);
      transform: scale(0);
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s; }
    .Button--arrow:hover::before {
      transform: scale(1);
      transition: 0.3s; }
    .Button--arrow .Button__icon-svg {
      margin-left: 40px;
      width: 15px;
      height: 30px; }
      @media (max-width: 1366px) {
        .Button--arrow .Button__icon-svg {
          margin-left: calc(12px + 28 * ((100vw - 375px) / 1046)); } }
    .Button--arrow .Button__icon .Button__icon-svg {
      fill: var(--color-b);
      stroke: var(--color-b); }
    .Button--arrow:hover > .Button__icon .Button__icon-svg {
      fill: var(--color-a);
      stroke: var(--color-a); }
  .Button--color-a {
    border: 1px solid var(--color-a);
    background-color: var(--color-a);
    transition: 0.3s fill, 0.3s color, 0.3s background-color, 0.3s opacity; }
    .Button--color-a .Button__icon-svg {
      fill: var(--color-e);
      transition: 0.3s fill, 0.3s color, 0.3s background-color, 0.3s opacity; }
    .Button--color-a .Button__text {
      color: var(--color-e); }
    .Button--color-a:hover {
      outline: none;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      color: var(--color-a);
      background: var(--color-e); }
    .Button--color-a:hover .Button__text {
      color: var(--color-a); }
    .Button--color-a:hover > .Button__icon .Button__icon-svg {
      fill: var(--color-a); }
    .Button--color-a:disabled {
      border-color: #e5e5e5;
      background-color: #f7f6fa;
      cursor: initial; }
      .Button--color-a:disabled .Button__text {
        color: #888; }
  .Button--color-b {
    border: 1px solid var(--color-b);
    background-color: var(--color-b);
    transition: 0.3s fill, 0.3s color, 0.3s background-color, 0.3s opacity; }
    .Button--color-b .Button__icon-svg {
      fill: var(--color-e);
      transition: 0.3s fill, 0.3s color, 0.3s background-color, 0.3s opacity; }
    .Button--color-b .Button__text {
      color: var(--color-e); }
    .Button--color-b:hover {
      outline: none;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      color: var(--color-b);
      background: var(--color-e); }
    .Button--color-b:hover .Button__text {
      color: var(--color-b); }
    .Button--color-b:hover > .Button__icon .Button__icon-svg {
      fill: var(--color-b); }
    .Button--color-b:disabled {
      border-color: #e5e5e5;
      background-color: #f7f6fa;
      cursor: initial; }
      .Button--color-b:disabled .Button__text {
        color: #888; }
  .Button--color-c {
    border: 1px solid var(--color-c);
    background-color: var(--color-c);
    transition: 0.3s fill, 0.3s color, 0.3s background-color, 0.3s opacity; }
    .Button--color-c .Button__icon-svg {
      fill: var(--color-e);
      transition: 0.3s fill, 0.3s color, 0.3s background-color, 0.3s opacity; }
    .Button--color-c .Button__text {
      color: var(--color-e); }
    .Button--color-c:hover {
      outline: none;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      color: var(--color-c);
      background: var(--color-e); }
    .Button--color-c:hover .Button__text {
      color: var(--color-c); }
    .Button--color-c:hover > .Button__icon .Button__icon-svg {
      fill: var(--color-c); }
    .Button--color-c:disabled {
      border-color: #e5e5e5;
      background-color: #f7f6fa;
      cursor: initial; }
      .Button--color-c:disabled .Button__text {
        color: #888; }
  .Button--color-d {
    border: 1px solid var(--color-d);
    background-color: var(--color-d);
    transition: 0.3s fill, 0.3s color, 0.3s background-color, 0.3s opacity; }
    .Button--color-d .Button__icon-svg {
      fill: var(--color-e);
      transition: 0.3s fill, 0.3s color, 0.3s background-color, 0.3s opacity; }
    .Button--color-d .Button__text {
      color: var(--color-e); }
    .Button--color-d:hover {
      outline: none;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      color: var(--color-d);
      background: var(--color-e); }
    .Button--color-d:hover .Button__text {
      color: var(--color-d); }
    .Button--color-d:hover > .Button__icon .Button__icon-svg {
      fill: var(--color-d); }
    .Button--color-d:disabled {
      border-color: #e5e5e5;
      background-color: #f7f6fa;
      cursor: initial; }
      .Button--color-d:disabled .Button__text {
        color: #888; }
  .Button--color-e {
    border: 1px solid var(--color-b);
    background-color: var(--color-b);
    transition: 0.3s fill, 0.3s color, 0.3s background-color, 0.3s opacity; }
    .Button--color-e .Button__icon-svg {
      fill: var(--color-e);
      transition: 0.3s fill, 0.3s color, 0.3s background-color, 0.3s opacity; }
    .Button--color-e .Button__text {
      color: var(--color-e); }
    .Button--color-e:hover {
      outline: none;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      color: var(--color-b);
      background: var(--color-e); }
    .Button--color-e:hover .Button__text {
      color: var(--color-b); }
    .Button--color-e:hover > .Button__icon .Button__icon-svg {
      fill: var(--color-b); }
    .Button--color-e:disabled {
      border-color: #e5e5e5;
      background-color: #f7f6fa;
      cursor: initial; }
      .Button--color-e:disabled .Button__text {
        color: #888; }
  .Button--color-f {
    border: 1px solid var(--color-f);
    background-color: var(--color-f);
    transition: 0.3s fill, 0.3s color, 0.3s background-color, 0.3s opacity; }
    .Button--color-f .Button__icon-svg {
      fill: var(--color-e);
      transition: 0.3s fill, 0.3s color, 0.3s background-color, 0.3s opacity; }
    .Button--color-f .Button__text {
      color: var(--color-e); }
    .Button--color-f:hover {
      outline: none;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      color: var(--color-f);
      background: var(--color-e); }
    .Button--color-f:hover .Button__text {
      color: var(--color-f); }
    .Button--color-f:hover > .Button__icon .Button__icon-svg {
      fill: var(--color-f); }
    .Button--color-f:disabled {
      border-color: #e5e5e5;
      background-color: #f7f6fa;
      cursor: initial; }
      .Button--color-f:disabled .Button__text {
        color: #888; }
  .Button--color-g {
    border: 1px solid var(--color-g);
    background: var(--color-g);
    transition: 0.3s fill, 0.3s color, 0.3s background-color, 0.3s opacity; }
    .Button--color-g .Button__icon-svg {
      fill: var(--color-e);
      transition: 0.3s fill, 0.3s color, 0.3s background-color, 0.3s opacity; }
    .Button--color-g .Button__text {
      color: var(--color-e); }
    .Button--color-g:hover {
      outline: none;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      color: var(--color-g);
      background: var(--color-g);
      opacity: 0.9; }
    .Button--color-g:hover .Button__text {
      color: var(--color-g); }
    .Button--color-g:hover > .Button__icon .Button__icon-svg {
      fill: var(--color-g); }
    .Button--color-g:disabled {
      border-color: #e5e5e5;
      background: #f7f6fa;
      cursor: initial; }
      .Button--color-g:disabled .Button__text {
        color: #888; }
  .Button--color-clear {
    border: none !important;
    transition: 0.3s all;
    padding: 0 !important; }
    .Button--color-clear .Button__text {
      color: var(--color-b); }
    .Button--color-clear .Button__icon {
      fill: var(--color-a); }
    .Button--color-clear:hover > .Button__text,
    .Button--color-clear:hover > .Button__icon {
      opacity: 0.8; }
  .Button--line {
    border-radius: 0px;
    border-bottom: 1px solid #b9b9b9;
    transition: 0.3s fill, 0.3s color, 0.3s background-color, 0.3s opacity; }
    .Button--line .Button__icon-svg {
      fill: var(--color-b);
      transition: 0.3s fill, 0.3s color, 0.3s background-color, 0.3s opacity; }
    .Button--line .Button__text {
      color: var(--color-b); }
    .Button--line:hover {
      outline: none;
      text-shadow: 0 0 0.45px #000, 0 0 0.45px #000;
      border-bottom: 1px solid var(--color-a); }
    .Button--line:hover > .Button__icon .Button__icon-svg {
      fill: #b9b9b9; }
    .Button--line:disabled {
      border-color: #e5e5e5;
      background-color: #f7f6fa;
      cursor: initial; }
      .Button--line:disabled .Button__text {
        color: #888; }
  .Button--color-a-t {
    border: 1px solid var(--color-a);
    background-color: var(--color-e);
    transition: 0.3s fill, 0.3s color, 0.3s background-color, 0.3s opacity; }
    .Button--color-a-t .Button__icon-svg {
      fill: var(--color-a);
      transition: 0.3s fill, 0.3s color, 0.3s background-color, 0.3s opacity; }
    .Button--color-a-t .Button__text {
      color: var(--color-a); }
    .Button--color-a-t:hover {
      outline: none;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      color: var(--color-e);
      background: var(--color-a); }
    .Button--color-a-t:hover .Button__text {
      color: var(--color-e); }
    .Button--color-a-t:hover > .Button__icon .Button__icon-svg {
      fill: var(--color-e); }
    .Button--color-a-t:disabled {
      border-color: #e5e5e5;
      background-color: #f7f6fa;
      cursor: initial; }
      .Button--color-a-t:disabled .Button__text {
        color: #888; }
  .Button--xs {
    width: 42px; }
  .Button--s {
    width: 200px; }
  .Button--m {
    width: 286px;
    width: 286px;
    min-height: 64px; }
    @media (max-width: 1366px) {
      .Button--m {
        width: calc(42px + 244 * ((100vw - 375px) / 1046)); } }
  .Button--l {
    width: 378px; }
    @media (max-width: 1366px) {
      .Button--l {
        width: calc(200px + 178 * ((100vw - 375px) / 1046)); } }
  .Button--full {
    width: 100%; }

.Link {
  text-decoration-line: none;
  transition: 0.02s;
  cursor: pointer;
  color: var(--color-a); }
  .Link:hover {
    color: var(--color-c);
    opacity: 0.8; }
  .Link:disabled {
    color: black;
    cursor: initial; }
  .Link--color-a {
    color: var(--color-a); }
    .Link--color-a:not(:disabled):hover, .Link--color-a:not(:disabled):focus {
      color: var(--color-a);
      opacity: 0.7; }
    .Link--color-a-decor {
      position: relative;
      color: var(--color-a); }
    .Link--color-a-decor::before {
      content: '';
      position: absolute;
      top: 100%;
      display: block;
      width: 105%;
      height: 1px;
      background: var(--color-a);
      transform: scale(0);
      transition: 0.02s; }
    .Link--color-a-decor:hover::before {
      transform: scale(1);
      transition: 0.02s; }
  .Link--color-b {
    color: var(--color-b); }
    .Link--color-b:not(:disabled):hover, .Link--color-b:not(:disabled):focus {
      color: var(--color-b);
      opacity: 0.7; }
    .Link--color-b-decor {
      position: relative;
      color: var(--color-b); }
    .Link--color-b-decor::before {
      content: '';
      position: absolute;
      top: 100%;
      display: block;
      width: 105%;
      height: 1px;
      background: var(--color-a);
      transform: scale(0);
      transition: 0.02s; }
    .Link--color-b-decor:hover::before {
      transform: scale(1);
      transition: 0.02s; }
  .Link--color-c {
    color: var(--color-c); }
    .Link--color-c:not(:disabled):hover, .Link--color-c:not(:disabled):focus {
      color: var(--color-c);
      opacity: 0.7; }
    .Link--color-c-decor {
      position: relative;
      color: var(--color-c); }
    .Link--color-c-decor::before {
      content: '';
      position: absolute;
      top: 100%;
      display: block;
      width: 105%;
      height: 1px;
      background: var(--color-a);
      transform: scale(0);
      transition: 0.02s; }
    .Link--color-c-decor:hover::before {
      transform: scale(1);
      transition: 0.02s; }
  .Link--color-d {
    color: var(--color-d); }
    .Link--color-d:not(:disabled):hover, .Link--color-d:not(:disabled):focus {
      color: var(--color-d);
      opacity: 0.7; }
    .Link--color-d-decor {
      position: relative;
      color: var(--color-d); }
    .Link--color-d-decor::before {
      content: '';
      position: absolute;
      top: 100%;
      display: block;
      width: 105%;
      height: 1px;
      background: var(--color-a);
      transform: scale(0);
      transition: 0.02s; }
    .Link--color-d-decor:hover::before {
      transform: scale(1);
      transition: 0.02s; }
  .Link--color-e {
    color: var(--color-e); }
    .Link--color-e:not(:disabled):hover, .Link--color-e:not(:disabled):focus {
      color: var(--color-e);
      opacity: 0.7; }
    .Link--color-e-decor {
      position: relative;
      color: var(--color-e); }
    .Link--color-e-decor::before {
      content: '';
      position: absolute;
      top: 100%;
      display: block;
      width: 105%;
      height: 1px;
      background: var(--color-a);
      transform: scale(0);
      transition: 0.02s; }
    .Link--color-e-decor:hover::before {
      transform: scale(1);
      transition: 0.02s; }
  .Link--color-header {
    color: var(--color-b); }
    .Link--color-header:not(:disabled):hover {
      color: var(--color-a); }
  .Link--xs {
    font-size: 14px; }
  .Link--s {
    font-size: 16px; }
    @media (max-width: 1366px) {
      .Link--s {
        font-size: calc(14px + 2 * ((100vw - 375px) / 1046)); } }
  .Link--m {
    font-size: 20px; }
    @media (max-width: 1366px) {
      .Link--m {
        font-size: calc(16px + 4 * ((100vw - 375px) / 1046)); } }
  .Link--l {
    font-size: 24px; }
    @media (max-width: 1366px) {
      .Link--l {
        font-size: calc(16px + 8 * ((100vw - 375px) / 1046)); } }
  .Link--xl {
    font-size: 32px; }
    @media (max-width: 1366px) {
      .Link--xl {
        font-size: calc(24px + 8 * ((100vw - 375px) / 1046)); } }
  .Link--xxl {
    font-size: 38px; }
    @media (max-width: 1366px) {
      .Link--xxl {
        font-size: calc(24px + 14 * ((100vw - 375px) / 1046)); } }

.Picture--a .Picture__block-img {
  padding-bottom: 60%; }

.Picture--b .Picture__block-img {
  padding-bottom: 70%; }

.Picture--b .Picture__img {
  object-fit: contain; }

.Picture--full .Picture__block-img {
  padding-bottom: 100%; }

.Picture--link .Picture__block-img {
  padding-bottom: 64%; }

.Picture__img-link {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%); }
  .Picture__img-link:hover {
    opacity: 0.7; }

.Picture__block-img {
  position: relative;
  padding-bottom: 53%; }

.Picture__img, .Picture__img-source {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center; }

picture {
  display: block;
  width: 100%;
  height: auto; }

.Icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0;
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
  text-align: center;
  text-decoration-line: none;
  fill: currentColor;
  background-color: transparent;
  transition: 0.3s;
  user-select: none; }
  .Icon__content {
    position: relative;
    display: flex;
    align-items: center; }
  .Icon__counter {
    position: absolute;
    right: -10px;
    top: -7px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    color: #fff;
    transition: 0.3s; }
  .Icon__icon {
    display: flex; }
  .Icon__text {
    text-overflow: ellipsis;
    transition: 0.3s; }
  .Icon:hover, .Icon:focus {
    text-decoration-line: none;
    color: inherit; }
  .Icon--xs .Icon__svg {
    width: 18px;
    height: 18px; }
    @media (max-width: 1366px) {
      .Icon--xs .Icon__svg {
        width: calc(18px + 0 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .Icon--xs .Icon__svg {
        height: calc(18px + 0 * ((100vw - 375px) / 1046)); } }
  .Icon--s .Icon__svg {
    width: 24px;
    height: 24px; }
    @media (max-width: 1366px) {
      .Icon--s .Icon__svg {
        width: calc(24px + 0 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .Icon--s .Icon__svg {
        height: calc(24px + 0 * ((100vw - 375px) / 1046)); } }
  .Icon--m .Icon__svg {
    width: 32px;
    height: 32px; }
    @media (max-width: 1366px) {
      .Icon--m .Icon__svg {
        width: calc(32px + 0 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .Icon--m .Icon__svg {
        height: calc(32px + 0 * ((100vw - 375px) / 1046)); } }
  .Icon--l .Icon__svg {
    width: 56px;
    height: 56px; }
    @media (max-width: 1366px) {
      .Icon--l .Icon__svg {
        width: calc(56px + 0 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .Icon--l .Icon__svg {
        height: calc(56px + 0 * ((100vw - 375px) / 1046)); } }
  .Icon--xl .Icon__svg {
    width: 60px;
    height: 60px; }
    @media (max-width: 1366px) {
      .Icon--xl .Icon__svg {
        width: calc(60px + 0 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .Icon--xl .Icon__svg {
        height: calc(60px + 0 * ((100vw - 375px) / 1046)); } }
  .Icon--xl-w .Icon__svg {
    width: 60px;
    height: 10px; }
    @media (max-width: 1366px) {
      .Icon--xl-w .Icon__svg {
        width: calc(60px + 0 * ((100vw - 375px) / 1046)); } }
  .Icon--xxl .Icon__svg {
    width: 80px;
    height: 80px; }
    @media (max-width: 1366px) {
      .Icon--xxl .Icon__svg {
        width: calc(56px + 24 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .Icon--xxl .Icon__svg {
        height: calc(56px + 24 * ((100vw - 375px) / 1046)); } }
  .Icon--xxxl .Icon__svg {
    width: 105px;
    height: 105px; }
    @media (max-width: 1366px) {
      .Icon--xxxl .Icon__svg {
        width: calc(32px + 73 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .Icon--xxxl .Icon__svg {
        height: calc(32px + 73 * ((100vw - 375px) / 1046)); } }
  .Icon--xxxl-w .Icon__svg {
    width: 300px;
    height: 30px; }
    @media (max-width: 1366px) {
      .Icon--xxxl-w .Icon__svg {
        width: calc(80px + 220 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .Icon--xxxl-w .Icon__svg {
        height: calc(30px + 0 * ((100vw - 375px) / 1046)); } }
  .Icon--bxl .Icon__svg {
    width: 152px;
    height: 152px; }
    @media (max-width: 1366px) {
      .Icon--bxl .Icon__svg {
        width: calc(80px + 72 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .Icon--bxl .Icon__svg {
        height: calc(80px + 72 * ((100vw - 375px) / 1046)); } }
  .Icon--bxxl .Icon__svg {
    width: 152px;
    height: 50px; }
    @media (max-width: 1366px) {
      .Icon--bxxl .Icon__svg {
        width: calc(80px + 72 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .Icon--bxxl .Icon__svg {
        height: calc(40px + 10 * ((100vw - 375px) / 1046)); } }
  .Icon--creators .Icon__svg {
    width: 152px;
    height: 52px; }
    @media (max-width: 1366px) {
      .Icon--creators .Icon__svg {
        width: calc(124px + 28 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .Icon--creators .Icon__svg {
        height: calc(42px + 10 * ((100vw - 375px) / 1046)); } }
  .Icon--color-a .Icon {
    display: none; }
    .Icon--color-a .Icon__svg {
      fill: var(--color-a);
      transition: 0.3s; }
    .Icon--color-a .Icon__counter {
      color: var(--color-e);
      transition: 0.3s;
      background-color: var(--color-a); }
    .Icon--color-a .Icon:hover .Icon--color-a .Icon__counter,
    .Icon--color-a .Icon:focus .Icon--color-a .Icon__counter {
      color: var(--color-e);
      transition: 0.3s; }
    .Icon--color-a .Icon:hover > .Icon--color-a .Icon__content > .Icon--color-a .Icon__counter {
      background-color: var(--color-a); }
    .Icon--color-a .Icon:hover > .Icon--color-a .Icon__content > .Icon--color-a .Icon__svg {
      fill: var(--color-d); }
  .Icon--color-b .Icon {
    display: none; }
    .Icon--color-b .Icon__svg {
      fill: var(--color-b);
      transition: 0.3s; }
    .Icon--color-b .Icon__counter {
      color: var(--color-e);
      transition: 0.3s;
      background-color: var(--color-b); }
    .Icon--color-b .Icon:hover .Icon--color-b .Icon__counter,
    .Icon--color-b .Icon:focus .Icon--color-b .Icon__counter {
      color: var(--color-e);
      transition: 0.3s; }
    .Icon--color-b .Icon:hover > .Icon--color-b .Icon__content > .Icon--color-b .Icon__counter {
      background-color: var(--color-a); }
    .Icon--color-b .Icon:hover > .Icon--color-b .Icon__content > .Icon--color-b .Icon__svg {
      fill: var(--color-d); }
  .Icon--color-c .Icon {
    display: none; }
    .Icon--color-c .Icon__svg {
      fill: var(--color-c);
      transition: 0.3s; }
    .Icon--color-c .Icon__counter {
      color: var(--color-e);
      transition: 0.3s;
      background-color: var(--color-c); }
    .Icon--color-c .Icon:hover .Icon--color-c .Icon__counter,
    .Icon--color-c .Icon:focus .Icon--color-c .Icon__counter {
      color: var(--color-e);
      transition: 0.3s; }
    .Icon--color-c .Icon:hover > .Icon--color-c .Icon__content > .Icon--color-c .Icon__counter {
      background-color: var(--color-a); }
    .Icon--color-c .Icon:hover > .Icon--color-c .Icon__content > .Icon--color-c .Icon__svg {
      fill: var(--color-d); }
  .Icon--color-d .Icon {
    display: none; }
    .Icon--color-d .Icon__svg {
      fill: var(--color-d);
      transition: 0.3s; }
    .Icon--color-d .Icon__counter {
      color: var(--color-e);
      transition: 0.3s;
      background-color: var(--color-d); }
    .Icon--color-d .Icon:hover .Icon--color-d .Icon__counter,
    .Icon--color-d .Icon:focus .Icon--color-d .Icon__counter {
      color: var(--color-e);
      transition: 0.3s; }
    .Icon--color-d .Icon:hover > .Icon--color-d .Icon__content > .Icon--color-d .Icon__counter {
      background-color: var(--color-a); }
    .Icon--color-d .Icon:hover > .Icon--color-d .Icon__content > .Icon--color-d .Icon__svg {
      fill: var(--color-d); }
  .Icon--color-e .Icon {
    display: none; }
    .Icon--color-e .Icon__svg {
      fill: var(--color-e);
      transition: 0.3s; }
    .Icon--color-e .Icon__counter {
      color: var(--color-e);
      transition: 0.3s;
      background-color: var(--color-e); }
    .Icon--color-e .Icon:hover .Icon--color-e .Icon__counter,
    .Icon--color-e .Icon:focus .Icon--color-e .Icon__counter {
      color: var(--color-e);
      transition: 0.3s; }
    .Icon--color-e .Icon:hover > .Icon--color-e .Icon__content > .Icon--color-e .Icon__counter {
      background-color: var(--color-a); }
    .Icon--color-e .Icon:hover > .Icon--color-e .Icon__content > .Icon--color-e .Icon__svg {
      fill: var(--color-d); }
  .Icon--color-f .Icon {
    display: none; }
    .Icon--color-f .Icon__svg {
      fill: var(--color-f);
      transition: 0.3s; }
    .Icon--color-f .Icon__counter {
      color: var(--color-e);
      transition: 0.3s;
      background-color: var(--color-f); }
    .Icon--color-f .Icon:hover .Icon--color-f .Icon__counter,
    .Icon--color-f .Icon:focus .Icon--color-f .Icon__counter {
      color: var(--color-f);
      transition: 0.3s; }
    .Icon--color-f .Icon:hover > .Icon--color-f .Icon__content > .Icon--color-f .Icon__counter {
      background-color: var(--color-a); }
    .Icon--color-f .Icon:hover > .Icon--color-f .Icon__content > .Icon--color-f .Icon__svg {
      fill: var(--color-d); }
  .Icon--animation-scale {
    /* ключевые кадры */ }
    .Icon--animation-scale .Icon__svg {
      animation-name: scale;
      animation-duration: 1.3s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-direction: alternate; }

@keyframes scale {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.3); }
  100% {
    transform: scale(1); } }

.Input {
  position: relative;
  display: inline-block; }
  .Input:not(:first-child) {
    margin-top: 10px; }
  .Input__label {
    display: flex;
    align-items: center;
    text-align: end;
    color: #000;
    cursor: pointer; }
  .Input .input-validation__message {
    font-size: 12px; }
  .Input__inp {
    width: 297px;
    min-height: 36px;
    border-radius: 5px;
    position: relative;
    outline: none;
    border: 1px solid transparent;
    border: 1px solid var(--color-h);
    padding: 7px;
    font-size: 16px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.44);
    background: var(--color-e); }
    @media (max-width: 1366px) {
      .Input__inp {
        width: calc(220px + 77 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .Input__inp {
        min-height: calc(30px + 6 * ((100vw - 375px) / 1046)); } }
    .Input__inp::placeholder {
      font-size: 14px;
      line-height: 19px;
      color: rgba(0, 0, 0, 0.44); }
    .Input__inp:focus::placeholder {
      opacity: 0;
      transform: 0.3s; }
  .Input__placeholder {
    font-size: calc( 16px + 1.6 * ((100vw - 375px) / 1545));
    position: absolute;
    left: 22%;
    top: 50%;
    line-height: 21px;
    color: #bdc0cf;
    transform: translateY(-50%);
    transition: 0.3s all;
    user-select: none; }
  .Input__placeholder--star-active {
    font-size: calc( 9px + 3.6 * ((100vw - 375px) / 1545));
    top: -6px;
    color: rgba(0, 0, 0, 0.44); }
  .Input__inp:required + .Input__placeholder--star::after {
    content: "*";
    color: var(--color-a); }
  .Input__label-text {
    font-size: 18px; }
    @media (max-width: 1366px) {
      .Input__label-text {
        font-size: calc(14px + 4 * ((100vw - 375px) / 1046)); } }

.fio {
  display: block; }

.Action-button {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  touch-action: manipulation;
  width: 100%; }
  .Action-button input {
    left: 12.8px;
    top: 12.8px;
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    appearance: none; }
    .Action-button input[type='checkbox'] + .Action-button__content::before {
      content: '';
      position: absolute;
      top: 0;
      margin-top: 3px;
      flex: 0 0 auto;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 12.8px;
      height: 12.8px;
      border: 1px solid var(--color-b);
      user-select: none;
      background: var(--color-b) url('data:image/svg+xml,%3Csvg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M9.4046 0.693385L9.10652 0.395304C9.01286 0.301567 8.88798 0.25 8.75466 0.25C8.62134 0.25 8.49638 0.301567 8.40272 0.395304L4.21427 4.58368L1.59739 1.96672C1.50372 1.87313 1.37877 1.82149 1.24545 1.82149C1.1122 1.82149 0.987318 1.87313 0.89358 1.96672L0.595426 2.26473C0.501688 2.35861 0.450195 2.48365 0.450195 2.61682C0.450195 2.75006 0.501688 2.87502 0.595426 2.96876L3.55004 5.92323C3.55396 5.92878 3.55811 5.93396 3.56292 5.93869L3.86107 6.23189C3.95473 6.32526 4.07969 6.37194 4.21405 6.37194H4.2156C4.34899 6.37194 4.47395 6.32526 4.56747 6.23189L4.8657 5.9361C4.87051 5.93137 4.87458 5.92744 4.87702 5.92345L9.40453 1.39638C9.59874 1.20262 9.59874 0.887371 9.4046 0.693385Z" fill= "white"/%3E%3C/svg%3E%0A') no-repeat center/10px 7px; }
    .Action-button input[type='radio'] + .Action-button__content::before {
      content: '';
      position: absolute;
      top: 0;
      margin-top: 3px;
      flex: 0 0 auto;
      display: inline-flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      width: 12.8px;
      height: 12.8px;
      border: 1px solid var(--color-b);
      user-select: none;
      background: var(--color-b) url('data:image/svg+xml,%3Csvg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M9.4046 0.693385L9.10652 0.395304C9.01286 0.301567 8.88798 0.25 8.75466 0.25C8.62134 0.25 8.49638 0.301567 8.40272 0.395304L4.21427 4.58368L1.59739 1.96672C1.50372 1.87313 1.37877 1.82149 1.24545 1.82149C1.1122 1.82149 0.987318 1.87313 0.89358 1.96672L0.595426 2.26473C0.501688 2.35861 0.450195 2.48365 0.450195 2.61682C0.450195 2.75006 0.501688 2.87502 0.595426 2.96876L3.55004 5.92323C3.55396 5.92878 3.55811 5.93396 3.56292 5.93869L3.86107 6.23189C3.95473 6.32526 4.07969 6.37194 4.21405 6.37194H4.2156C4.34899 6.37194 4.47395 6.32526 4.56747 6.23189L4.8657 5.9361C4.87051 5.93137 4.87458 5.92744 4.87702 5.92345L9.40453 1.39638C9.59874 1.20262 9.59874 0.887371 9.4046 0.693385Z" fill= "white"/%3E%3C/svg%3E%0A') no-repeat center/10px 7px; }
    .Action-button input + .Action-button__content:hover::before {
      border-color: var(--color-b);
      background-color: var(--color-b); }
    .Action-button input:not(:checked) + .Action-button__content::before {
      border-color: var(--color-b);
      background-color: var(--color-e);
      background-image: none; }
    .Action-button input:not(:checked) + .Action-button__content:hover::before {
      border-color: #dedede;
      background-color: var(--color-e); }
    .Action-button input:not(:checked):disabled + .Action-button__content::before {
      border-color: #e5e5e5 !important;
      background-color: #f7f6fa !important; }
    .Action-button input:disabled + .Action-button__content::before {
      border-color: #cccccc !important;
      background-color: #cccccc !important; }
    .Action-button input:disabled + .Action-button__content {
      cursor: initial; }
    .Action-button input:focus + .Action-button__content::before {
      outline: none; }
  .Action-button__content {
    display: inline-flex;
    cursor: pointer; }
  .Action-button__text {
    font-size: 15px; }
    .Action-button__text a {
      text-decoration: underline; }
  .Action-button__text--policy {
    color: var(--color-b);
    padding-left: 26px; }
    .Action-button__text--policy a {
      color: var(--color-b);
      transition: 0.3s all; }
      .Action-button__text--policy a:hover, .Action-button__text--policy a:focus {
        color: var(--color-b);
        opacity: 0.6; }

.None-result {
  margin-top: 55px;
  margin-bottom: 300px; }
  @media (max-width: 1366px) {
    .None-result {
      margin-top: calc(27.5px + 27.5 * ((100vw - 375px) / 1046)); } }
  @media (max-width: 1366px) {
    .None-result {
      margin-bottom: calc(150px + 150 * ((100vw - 375px) / 1046)); } }
  .None-result__text strong {
    font-weight: 700; }

.Bread-crumbs__list {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  flex-wrap: wrap;
  text-overflow: ellipsis; }

.Bread-crumbs__list-item:not(:last-child) {
  position: relative;
  margin-right: 3px;
  padding-right: 5px; }
  .Bread-crumbs__list-item:not(:last-child)::after {
    content: '/';
    color: var(--color-c);
    right: 0;
    top: 50%;
    width: 4px;
    height: 8px;
    font-size: 14px;
    transform: translateY(-50%); }

.Bread-crumbs__list-item--black::after {
  filter: blur(2px); }

.Bread-crumbs__list-item--black .Bread-crumbs__link:hover {
  border-bottom-color: var(--color-c);
  color: var(--color-c); }

.Bread-crumbs__list-item--black .Bread-crumbs__link-text {
  color: var(--color-c); }

.Bread-crumbs__link-text {
  color: #d1d1d1; }
  .Bread-crumbs__link-text:hover {
    color: var(--color-c); }

.Bread-crumbs__list-item--active .Bread-crumbs__link-text {
  color: var(--color-c); }

.Bread-crumbs__link {
  border-bottom: 1px solid;
  border-bottom-color: transparent;
  transition: 0.3s all; }
  .Bread-crumbs__link:hover {
    color: var(--color-c); }

.Bread-crumbs__link-text {
  font-size: 16px;
  line-height: 14px; }

.__select {
  display: flex;
  justify-content: center;
  background-color: var(--color-c); }
  .__select__wrapper {
    width: 230px;
    height: 40px;
    position: relative; }
  .__select[data-state="active"] .__select__title {
    border: 1px solid var(--color-a); }
    .__select[data-state="active"] .__select__title::before {
      transform: translate(-3px, -50%) rotate(-45deg); }
    .__select[data-state="active"] .__select__title::after {
      transform: translate(3px, -50%) rotate(45deg); }
  .__select[data-state="active"] .__select__content {
    border: 1px solid var(--color-a);
    pointer-events: auto;
    border-top: none;
    opacity: 1; }
  .__select[data-state="active"] .__select__label + .__select__input + .__select__label {
    max-height: 40px;
    border-top-width: 1px; }

.__select__title {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 8px 16px;
  border: solid 1px #c7ccd1;
  cursor: pointer; }
  .__select__title::before, .__select__title::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 16px;
    display: block;
    width: 10px;
    height: 2px;
    transition: all 0.3s ease-out;
    background-color: #333333;
    transform: translate(-3px, -50%) rotate(45deg); }
  .__select__title::after {
    transform: translate(3px, -50%) rotate(-45deg); }
  .__select__title:hover {
    border-color: var(--color-a); }
    .__select__title:hover::before, .__select__title:hover::after {
      background-color: var(--color-a); }

.__select__content {
  pointer-events: none;
  position: absolute;
  top: 40px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #c7ccd1;
  border-top: none;
  transition: all 0.3s ease-out;
  opacity: 0;
  z-index: 800; }

.__select__input {
  display: none; }
  .__select__input:checked + label {
    background-color: #dedede; }
  .__select__input:disabled + label {
    opacity: 0.6;
    pointer-events: none; }

.__select__label {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 16px;
  transition: all 0.2s ease-out;
  cursor: pointer;
  overflow: hidden; }
  .__select__label + input + .__select__label {
    border-top: 0 solid #C7CCD160; }
  .__select__label:hover {
    background-color: var(--color-a) !important;
    color: #ffffff; }

.Heading {
  font-weight: 600;
  font-size: 40px; }
  @media (max-width: 1366px) {
    .Heading {
      font-size: calc(24px + 16 * ((100vw - 375px) / 1046)); } }
  .Heading--dec::before {
    content: '/';
    font-size: 60px;
    font-weight: 900; }
  .Heading__wrapper {
    max-width: 900px; }
  .Heading--color-a {
    color: var(--color-a); }
  .Heading--color-b {
    color: var(--color-b); }
  .Heading--color-c {
    color: var(--color-c); }
  .Heading--color-d {
    color: var(--color-d); }
  .Heading--color-e {
    color: var(--color-e); }
  .Heading--color-f {
    color: var(--color-f); }
  .Heading--xs {
    font-size: 14px; }
  .Heading--s {
    font-size: 16px; }
  .Heading--m {
    font-size: 20px; }
    @media (max-width: 1366px) {
      .Heading--m {
        font-size: calc(16px + 4 * ((100vw - 375px) / 1046)); } }
  .Heading--l {
    font-size: 24px; }
    @media (max-width: 1366px) {
      .Heading--l {
        font-size: calc(14px + 10 * ((100vw - 375px) / 1046)); } }
  .Heading--xl {
    font-size: 32px; }
    @media (max-width: 1366px) {
      .Heading--xl {
        font-size: calc(20px + 12 * ((100vw - 375px) / 1046)); } }
  .Heading--xxl {
    font-size: 38px; }
    @media (max-width: 1366px) {
      .Heading--xxl {
        font-size: calc(20px + 18 * ((100vw - 375px) / 1046)); } }
  .Heading--xxxl {
    font-size: 40px; }
    @media (max-width: 1366px) {
      .Heading--xxxl {
        font-size: calc(24px + 16 * ((100vw - 375px) / 1046)); } }
  .Heading--bxl {
    font-size: 56px; }
    @media (max-width: 1366px) {
      .Heading--bxl {
        font-size: calc(24px + 32 * ((100vw - 375px) / 1046)); } }

.Title {
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center; }
  .Title--dec::before {
    content: '/';
    font-size: 60px;
    font-weight: 900; }
  .Title__wrapper {
    max-width: 900px; }
  .Title--color-a {
    color: var(--color-a); }
  .Title--color-b {
    color: var(--color-b); }
  .Title--color-c {
    color: var(--color-c); }
  .Title--color-d {
    color: var(--color-d); }
  .Title--color-e {
    color: var(--color-e); }
  .Title--color-f {
    color: var(--color-f); }
  .Title--xs {
    font-size: 14px; }
  .Title--s {
    font-size: 16px; }
  .Title--m {
    font-size: 20px; }
    @media (max-width: 1366px) {
      .Title--m {
        font-size: calc(16px + 4 * ((100vw - 375px) / 1046)); } }
  .Title--l {
    font-size: 24px; }
    @media (max-width: 1366px) {
      .Title--l {
        font-size: calc(14px + 10 * ((100vw - 375px) / 1046)); } }
  .Title--xl {
    font-size: 32px; }
    @media (max-width: 1366px) {
      .Title--xl {
        font-size: calc(20px + 12 * ((100vw - 375px) / 1046)); } }
  .Title--xxl {
    font-size: 38px; }
    @media (max-width: 1366px) {
      .Title--xxl {
        font-size: calc(20px + 18 * ((100vw - 375px) / 1046)); } }
  .Title--xxxl {
    font-size: 40px; }
    @media (max-width: 1366px) {
      .Title--xxxl {
        font-size: calc(24px + 16 * ((100vw - 375px) / 1046)); } }
  .Title--bxl {
    font-size: 56px; }
    @media (max-width: 1366px) {
      .Title--bxl {
        font-size: calc(24px + 32 * ((100vw - 375px) / 1046)); } }

.Subtitle {
  font-weight: 400; }
  .Subtitle--dec::before {
    content: '/';
    font-size: 60px;
    font-weight: 900; }
  .Subtitle--color-a {
    color: var(--color-a); }
  .Subtitle--color-b {
    color: var(--color-b); }
  .Subtitle--color-c {
    color: var(--color-c); }
  .Subtitle--color-d {
    color: var(--color-d); }
  .Subtitle--color-e {
    color: var(--color-e); }
  .Subtitle--color-f {
    color: var(--color-f); }
  .Subtitle--xs {
    font-size: 14px; }
  .Subtitle--s {
    font-size: 16px; }
  .Subtitle--m {
    font-size: 20px; }
    @media (max-width: 1366px) {
      .Subtitle--m {
        font-size: calc(16px + 4 * ((100vw - 375px) / 1046)); } }
  .Subtitle--l {
    font-size: 24px; }
    @media (max-width: 1366px) {
      .Subtitle--l {
        font-size: calc(14px + 10 * ((100vw - 375px) / 1046)); } }
  .Subtitle--xl {
    font-size: 32px; }
    @media (max-width: 1366px) {
      .Subtitle--xl {
        font-size: calc(20px + 12 * ((100vw - 375px) / 1046)); } }
  .Subtitle--xxl {
    font-size: 38px; }
    @media (max-width: 1366px) {
      .Subtitle--xxl {
        font-size: calc(20px + 18 * ((100vw - 375px) / 1046)); } }
  .Subtitle--xxxl {
    font-size: 40px; }
    @media (max-width: 1366px) {
      .Subtitle--xxxl {
        font-size: calc(24px + 16 * ((100vw - 375px) / 1046)); } }
  .Subtitle--bxl {
    font-size: 56px; }
    @media (max-width: 1366px) {
      .Subtitle--bxl {
        font-size: calc(24px + 32 * ((100vw - 375px) / 1046)); } }

.Text {
  font-weight: 400; }
  .Text--dec::before {
    content: '/';
    font-size: 60px;
    font-weight: 900; }
  .Text--color-a {
    color: var(--color-a); }
  .Text--color-b {
    color: var(--color-b); }
  .Text--color-c {
    color: var(--color-c); }
  .Text--color-d {
    color: var(--color-d); }
  .Text--color-e {
    color: var(--color-e); }
  .Text--color-f {
    color: var(--color-f); }
  .Text--xs {
    font-size: 14px; }
  .Text--s {
    font-size: 16px; }
  .Text--m {
    font-size: 20px; }
    @media (max-width: 1366px) {
      .Text--m {
        font-size: calc(16px + 4 * ((100vw - 375px) / 1046)); } }
  .Text--l {
    font-size: 24px; }
    @media (max-width: 1366px) {
      .Text--l {
        font-size: calc(14px + 10 * ((100vw - 375px) / 1046)); } }
  .Text--xl {
    font-size: 32px; }
    @media (max-width: 1366px) {
      .Text--xl {
        font-size: calc(20px + 12 * ((100vw - 375px) / 1046)); } }
  .Text--xxl {
    font-size: 38px; }
    @media (max-width: 1366px) {
      .Text--xxl {
        font-size: calc(20px + 18 * ((100vw - 375px) / 1046)); } }
  .Text--xxxl {
    font-size: 40px; }
    @media (max-width: 1366px) {
      .Text--xxxl {
        font-size: calc(24px + 16 * ((100vw - 375px) / 1046)); } }
  .Text--bxl {
    font-size: 56px; }
    @media (max-width: 1366px) {
      .Text--bxl {
        font-size: calc(24px + 32 * ((100vw - 375px) / 1046)); } }

/* 
@import '../components/elements/search/search.scss';
@import '../components/elements/map/map.scss';

*/
.popup-write-us {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 501;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  padding: 0;
  background: var(--color-d);
  transition: opacity 1s, transform 1s; }
  .popup-write-us__wrapper {
    position: relative;
    padding-top: 60px;
    padding-bottom: 60px;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 5px;
    max-width: 500px; }
    @media (max-width: 1366px) {
      .popup-write-us__wrapper {
        padding-top: calc(40px + 20 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .popup-write-us__wrapper {
        padding-bottom: calc(40px + 20 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .popup-write-us__wrapper {
        padding-left: calc(20px + 20 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .popup-write-us__wrapper {
        padding-right: calc(20px + 20 * ((100vw - 375px) / 1046)); } }
  .popup-write-us--disable {
    opacity: 0;
    pointer-events: none;
    transform: translateY(-100%); }
  .popup-write-us--enable {
    opacity: 1;
    transform: translateY(0); }
  .popup-write-us--contacts {
    position: static;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: transparent; }
    .popup-write-us--contacts .popup-write-us__download {
      color: var(--color-b); }
    .popup-write-us--contacts .popup-write-us__btns {
      justify-content: flex-start;
      width: 100%;
      align-items: flex-start; }
      .popup-write-us--contacts .popup-write-us__btns .Button--full {
        width: max-content;
        border-color: var(--color-b);
        border-style: solid;
        padding: 10px;
        transition: 0.3s border-color; }
        @media (max-width: 733px) {
          .popup-write-us--contacts .popup-write-us__btns .Button--full {
            width: 100%; } }
        .popup-write-us--contacts .popup-write-us__btns .Button--full:hover {
          border-color: var(--color-a);
          border-style: solid; }
      .popup-write-us--contacts .popup-write-us__btns .Button--full > .Button__text {
        color: var(--color-b); }
    .popup-write-us--contacts .popup-write-us__wrapper {
      padding: 0;
      max-width: 100%;
      border-radius: unset; }
    .popup-write-us--contacts .Input__inp {
      background-color: transparent !important;
      color: var(--color-b) !important; }
    .popup-write-us--contacts .Input__label {
      border-bottom: 1px solid var(--color-a); }
    .popup-write-us--contacts .Input__label-text {
      color: var(--color-b) !important; }
    .popup-write-us--contacts .Action-button__content * {
      color: var(--color-b) !important; }
  .popup-write-us .Button__icon-svg--close {
    cursor: pointer;
    position: absolute;
    top: 20px;
    width: 40px;
    height: 40px;
    right: -150px; }
    @media (max-width: 1366px) {
      .popup-write-us .Button__icon-svg--close {
        width: calc(20px + 20 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .popup-write-us .Button__icon-svg--close {
        height: calc(20px + 20 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .popup-write-us .Button__icon-svg--close {
        right: calc(20px + -170 * ((100vw - 375px) / 1046)); } }
  .popup-write-us__block-inputs {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%; }
  .popup-write-us__bg-img {
    position: relative; }
    .popup-write-us__bg-img .Picture {
      width: 150px;
      height: 100px;
      position: absolute;
      bottom: -30px;
      left: -20px;
      pointer-events: none; }
      .popup-write-us__bg-img .Picture__block-img {
        padding-bottom: 65%; }
  .popup-write-us__btns {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    @media (max-width: 1366px) {
      .popup-write-us__btns {
        margin-top: calc(10px + 10 * ((100vw - 375px) / 1046)); } }
    .popup-write-us__btns .Button--full {
      width: 200px;
      background: none;
      transition: border 0.3s;
      border-width: 1px;
      border-style: solid;
      border-color: var(--color-d); }
      .popup-write-us__btns .Button--full > .Button__text {
        color: var(--color-c); }
      .popup-write-us__btns .Button--full:hover {
        border-color: var(--color-c);
        border-radius: 5px; }
  .popup-write-us__status {
    margin-top: 10px; }
  .popup-write-us__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px; }
    @media (max-width: 1366px) {
      .popup-write-us__form {
        margin-top: calc(7.5px + 7.5 * ((100vw - 375px) / 1046)); } }
  .popup-write-us__subtitle {
    font-size: 24px;
    text-align: center;
    font-weight: 400;
    margin-top: 5px; }
    @media (max-width: 1366px) {
      .popup-write-us__subtitle {
        font-size: calc(16px + 8 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .popup-write-us__subtitle {
        margin-top: calc(2.5px + 2.5 * ((100vw - 375px) / 1046)); } }
    .popup-write-us__subtitle-text {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      width: 100%; }
      .popup-write-us__subtitle-text::after, .popup-write-us__subtitle-text::before {
        width: 10%;
        content: '';
        height: 1px;
        display: block;
        background: var(--color-b);
        margin: 0 10px; }
  .popup-write-us .Action-button__content * {
    color: var(--color-c); }
  .popup-write-us__title {
    font-size: 30px;
    font-weight: 700;
    text-align: center; }
    @media (max-width: 1366px) {
      .popup-write-us__title {
        font-size: calc(20px + 10 * ((100vw - 375px) / 1046)); } }
    .popup-write-us__title * {
      color: var(--color-c); }
  .popup-write-us .Action-button {
    margin-top: 20px; }
  .popup-write-us .Input {
    width: 100%;
    position: relative; }
    .popup-write-us .Input__inp {
      width: 100%;
      background-color: var(--color-d);
      max-height: 1px;
      min-height: 1px;
      padding: 0;
      display: flex;
      flex-shrink: 0;
      transition: max-height 0.5s, min-height 0.5s;
      color: var(--color-c);
      border: unset;
      border-radius: 0;
      border-bottom: 2px solid var(--color-c); }
      .popup-write-us .Input__inp--file {
        border: 2px solid var(--color-a);
        max-width: 58px;
        pointer-events: none; }
      .popup-write-us .Input__inp--open {
        max-height: 40px; }
    .popup-write-us .Input__label {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 20px; }
    .popup-write-us .Input__label-wrapper {
      display: flex; }
    .popup-write-us .Input [for='file'] {
      width: fit-content; }
    .popup-write-us .Input__label-file {
      position: absolute;
      z-index: 510;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 40%;
      top: 0;
      transform: translateY(-25%);
      border-bottom: 2px solid var(--color-c);
      margin-left: 10px;
      width: 40px;
      height: 40px;
      transition: opacity 0.3s; }
      .popup-write-us .Input__label-file > .Button__icon-svg {
        margin: 0; }
        .popup-write-us .Input__label-file > .Button__icon-svg--delete {
          position: absolute;
          top: 0;
          right: 0;
          width: 6px;
          height: 6px; }
    .popup-write-us .Input__label-text {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 10px;
      color: var(--color-c); }
      @media (max-width: 1366px) {
        .popup-write-us .Input__label-text {
          font-size: calc(14px + 2 * ((100vw - 375px) / 1046)); } }
  .popup-write-us .Title {
    font-weight: 600; }
  .popup-write-us .checkbox {
    margin-top: 20px; }
    @media (max-width: 1366px) {
      .popup-write-us .checkbox {
        margin-top: calc(35px + -15 * ((100vw - 375px) / 1046)); } }
  .popup-write-us .Action-button input:not(:checked) + .Action-button__content::before {
    border-color: var(--color-c);
    background-color: var(--color-d); }
  .popup-write-us .Action-button input[type='checkbox'] + .Action-button__content::before {
    border: 1px solid var(--color-c); }
  .popup-write-us__status--error {
    color: var(--color-a); }
  .popup-write-us__status--success {
    color: var(--color-c); }
  .popup-write-us__download {
    color: var(--color-c); }
  .popup-write-us__link {
    color: var(--color-f);
    position: relative;
    font-weight: 600; }
    .popup-write-us__link::before {
      content: '';
      transition: max-width 0.3s, background-color 1s;
      position: absolute;
      width: 100%;
      max-width: 1px;
      height: 2px;
      bottom: 0;
      background-color: transparent; }
    .popup-write-us__link:hover {
      color: var(--color-f); }
      .popup-write-us__link:hover::before {
        content: '';
        transition: max-width 0.3s;
        position: absolute;
        width: 100%;
        max-width: 100px;
        height: 2px;
        bottom: 0;
        background-color: var(--color-a); }
  .popup-write-us__pp {
    text-decoration: none !important; }
    .popup-write-us__pp:hover {
      color: var(--color-f) !important; }
  .popup-write-us .invalid {
    border-color: red !important; }

.popup-status {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 502;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  padding: 0;
  background: var(--color-d);
  transition: opacity 1s, transform 1s; }
  .popup-status__wrapper {
    position: relative;
    padding-top: 60px;
    padding-bottom: 60px;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 5px;
    max-width: 500px; }
    @media (max-width: 1366px) {
      .popup-status__wrapper {
        padding-top: calc(40px + 20 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .popup-status__wrapper {
        padding-bottom: calc(40px + 20 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .popup-status__wrapper {
        padding-left: calc(20px + 20 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .popup-status__wrapper {
        padding-right: calc(20px + 20 * ((100vw - 375px) / 1046)); } }
  .popup-status--disable {
    opacity: 0;
    pointer-events: none; }
  .popup-status--enable {
    opacity: 1; }
  .popup-status .Button__icon-svg--close {
    cursor: pointer;
    position: absolute;
    top: 20px;
    width: 40px;
    height: 40px;
    right: -150px; }
    @media (max-width: 1366px) {
      .popup-status .Button__icon-svg--close {
        width: calc(20px + 20 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .popup-status .Button__icon-svg--close {
        height: calc(20px + 20 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .popup-status .Button__icon-svg--close {
        right: calc(0px + -150 * ((100vw - 375px) / 1046)); } }
  .popup-status__btns {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    @media (max-width: 1366px) {
      .popup-status__btns {
        margin-top: calc(10px + 10 * ((100vw - 375px) / 1046)); } }
    .popup-status__btns .Button--full {
      max-width: 270px;
      width: 100%;
      padding: 10px;
      background: none;
      transition: border 0.3s;
      border-width: 1px;
      border-style: solid;
      border-color: var(--color-d); }
      .popup-status__btns .Button--full > .Button__text {
        display: flex;
        margin-left: 10px;
        order: 1;
        color: var(--color-c); }
      .popup-status__btns .Button--full > .Button__icon {
        transform: rotate(180deg); }
      .popup-status__btns .Button--full:hover {
        border-color: var(--color-c);
        border-radius: 5px; }
  .popup-status__subtitle {
    font-size: 24px;
    text-align: center;
    font-weight: 400;
    margin-top: 20px; }
    @media (max-width: 1366px) {
      .popup-status__subtitle {
        font-size: calc(16px + 8 * ((100vw - 375px) / 1046)); } }
    @media (max-width: 1366px) {
      .popup-status__subtitle {
        margin-top: calc(10px + 10 * ((100vw - 375px) / 1046)); } }
    .popup-status__subtitle-text {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      width: 100%; }
      .popup-status__subtitle-text::after, .popup-status__subtitle-text::before {
        width: 10%;
        content: '';
        height: 1px;
        display: block;
        background: var(--color-b);
        margin: 0 10px; }
  .popup-status__title {
    font-size: 30px;
    font-weight: 700;
    text-align: center; }
    @media (max-width: 1366px) {
      .popup-status__title {
        font-size: calc(20px + 10 * ((100vw - 375px) / 1046)); } }
    .popup-status__title * {
      color: var(--color-c); }
  .popup-status__title-text {
    font-size: 30px; }
    @media (max-width: 1366px) {
      .popup-status__title-text {
        font-size: calc(20px + 10 * ((100vw - 375px) / 1046)); } }

/* 
 @import '../components/popups/popup-order-call/popup-order-call.scss';

*/
@media (max-width: 1350px) {
  .projects__wrapper {
    flex-direction: column;
    background-image: unset !important;
    min-height: auto; }
  .projects__left {
    background-image: url(img/projects-bg-mobile.png) !important;
    min-height: 400px; }
  .projects__right {
    max-width: unset;
    margin-top: 30px; } }
  @media (max-width: 1350px) and (max-width: 1366px) {
    .projects__right {
      margin-top: calc(15px + 15 * ((100vw - 375px) / 1046)); } }

@media (max-width: 1350px) {
  .projects__title {
    margin: 0;
    position: relative;
    top: -70px; } }
  @media (max-width: 1350px) and (max-width: 1366px) {
    .projects__title {
      top: calc(-20px + -50 * ((100vw - 375px) / 1046)); } }

@media (max-width: 1250px) {
  [class*='--grid-g'] {
    display: grid;
    grid-template-columns: repeat(5, 1fr); } }

@media (max-width: 1023px) {
  .contacts__wrapper {
    flex-direction: column; }
  .contacts__inner {
    margin-bottom: 75px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 100%; } }
  @media (max-width: 1023px) and (max-width: 1366px) {
    .contacts__inner {
      margin-bottom: calc(37.5px + 37.5 * ((100vw - 375px) / 1046)); } }

@media (max-width: 1023px) {
    .contacts__inner .popup-write-us {
      justify-content: flex-start;
      align-items: flex-start; }
  .contacts__map {
    width: 100%; }
  .process--a .process__list .item-process {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr; }
    .process--a .process__list .item-process:nth-child(even) .Card-img {
      order: 0; }
    .process--a .process__list .item-process .Card-img {
      min-height: 208px; }
  .process--b .process__list .item-process__wrap {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr; }
  .process--b .process__list .item-process:nth-child(even) .Card-img {
    order: 0; }
  .process--b .process__list .item-process:nth-child(odd) {
    background-color: var(--color-d); }
    .process--b .process__list .item-process:nth-child(odd) .item-process__heading .item-process__title,
    .process--b .process__list .item-process:nth-child(odd) .item-process__heading .item-process__text {
      color: var(--color-c); }
  .process--b .process__list .item-process:nth-child(even) .item-process__heading {
    order: 1; }
  .process--b .process__list .item-process:nth-child(even) .Card-img {
    order: 2; }
  .process--b .process__list .item-process .Card-img {
    min-height: 400px; }
  .info__bottom-wrapper {
    justify-content: flex-end; }
  .info__nav-list {
    padding-right: 20px; }
  .portfolio__title-slide {
    font-size: 24px !important; }
  .detailed__wrapper {
    flex-direction: column; }
  .blog__list .item-blog {
    grid-template-columns: 100px 1fr; }
    .blog__list .item-blog__content-picture {
      display: none; }
    .blog__list .item-blog__content-text {
      font-size: 18px; }
    .blog__list .item-blog__date-text {
      font-size: 32px; }
    .blog__list .item-blog__month-text {
      font-size: 20px; }
    .blog__list .item-blog__date {
      padding-left: 4px;
      padding-right: 4px; }
  [class*='--grid-g'] {
    display: grid;
    grid-template-columns: repeat(4, 1fr); } }

@media (max-width: 866px) {
  .hero__bottom-line {
    width: 100vw; }
  .info--a .info__wrapper {
    height: 88vh; }
  .info__nav-list-btns {
    min-width: 52px;
    height: 52px; }
  .info__nav-list-inner {
    align-items: flex-end;
    width: 52px; }
  .about-us__wrapper {
    grid-template-columns: 1fr !important; } }

@media (max-width: 733px) {
  .dropdown__list .item-dropdown__visible .Icon {
    margin-right: 15px; }
  .resume__responsibilities {
    margin-top: 20px; }
  .resume__requirements {
    margin: 0;
    padding: 0;
    border: unset; }
  .resume__wrapper {
    flex-direction: column;
    max-height: 400px;
    overflow-y: scroll; }
  .Nav__main .Nav__list {
    align-items: flex-start;
    max-height: 65vh; }
  .Nav__main-wrapper {
    justify-content: flex-start;
    align-items: flex-start; }
  .Nav__button {
    opacity: 1;
    visibility: visible; }
    .Nav__button .Button {
      margin-top: 25px; } }
    @media (max-width: 733px) and (max-width: 1366px) {
      .Nav__button .Button {
        margin-top: calc(12.5px + 12.5 * ((100vw - 375px) / 1046)); } }

@media (max-width: 733px) {
    .Nav__button .Button--color-clear .Button__text {
      color: var(--color-c);
      font-size: 20px; }
  .administration__list {
    grid-template-columns: 1fr;
    gap: 80px; } }
  @media (max-width: 733px) and (max-width: 1366px) {
    .administration__list {
      gap: calc(40px + 40 * ((100vw - 375px) / 1046)); } }

@media (max-width: 733px) {
  .describe__wrapper {
    grid-template-columns: 1fr;
    gap: 60px;
    border: unset; } }
  @media (max-width: 733px) and (max-width: 1366px) {
    .describe__wrapper {
      gap: calc(30px + 30 * ((100vw - 375px) / 1046)); } }

@media (max-width: 733px) {
  .header__action {
    align-items: flex-start; }
  .header__phone-link {
    font-size: 20px; }
  .header__btn {
    justify-content: center; }
    .header__btn > :not(:last-child) {
      margin-right: 0; }
  .header .Hamb {
    order: 3; }
    .header .Hamb__bar {
      margin-left: auto; }
  .header__btn .header__text-link {
    display: none; }
  .header__wrapper {
    grid-template-columns: 1fr 2fr 1fr; }
  .footer__content {
    flex-direction: column; }
  .footer__copyright {
    flex-direction: column; }
  .footer__copyright-right {
    justify-content: flex-start;
    margin-top: 35px; } }
  @media (max-width: 733px) and (max-width: 1366px) {
    .footer__copyright-right {
      margin-top: calc(17.5px + 17.5 * ((100vw - 375px) / 1046)); } }

@media (max-width: 733px) {
  .footer__logo .Icon--xxl .Icon__svg {
    width: 52px;
    height: 41px;
    margin-bottom: 20px; } }
  @media (max-width: 733px) and (max-width: 1366px) {
    .footer__logo .Icon--xxl .Icon__svg {
      margin-bottom: calc(10px + 10 * ((100vw - 375px) / 1046)); } }

@media (max-width: 733px) {
  .footer__logo-creators {
    justify-content: flex-start; }
  .footer__nav .Nav-link__nav-list {
    flex-direction: column; }
  .footer__nav .Nav-link__nav-item:not(:last-child) {
    margin-right: 0;
    margin-bottom: 15px; } }
  @media (max-width: 733px) and (max-width: 1366px) {
    .footer__nav .Nav-link__nav-item:not(:last-child) {
      margin-bottom: calc(7.5px + 7.5 * ((100vw - 375px) / 1046)); } }

@media (max-width: 733px) {
  [class*='--grid-g'] {
    display: grid;
    grid-template-columns: repeat(3, 1fr); }
  .container.open {
    padding-right: 0; } }

@media (max-width: 500px) {
  .Card-c .Card-c__img {
    transform: scale(1);
    filter: brightness(0.7); }
  .Card-c .Card-c__title,
  .Card-c .Card-c__text {
    transform: scale(0.9);
    opacity: 1; }
  .info__nav-item {
    max-width: 87px;
    width: 100%; }
    .info__nav-item:nth-child(2) {
      max-width: 150px; }
    .info__nav-item:nth-child(4) {
      max-width: 63px; } }
