@media (max-width: 1650px) {
	//! Blocks

	//! Elements
	//! Popups
	//! Section
	//! Layouts
	//! Global
}
@media (max-width: 1350px) {
	//! Blocks
	//! Elements
	//! Popups
	//! Section
	.projects {
		&__wrapper {
			flex-direction: column;
			background-image: unset !important;
			min-height: auto;
			// @include adaptiv-value("padding-bottom", $offset-12, $offsetMobile-12, 1);
		}
		&__left {
			background-image: url(img/projects-bg-mobile.png) !important;
			min-height: 400px;
		}
		&__right {
			max-width: unset;
			@include adaptiv-value('margin-top', $offset-6, $offsetMobile-6, 1);
		}
		&__title {
			margin: 0;
			position: relative;
			@include adaptiv-value('top', -70, -20, 1);
		}
	}
	//! Layouts
	//! Global
}
@media (max-width: 1250px) {
	//! Blocks
	//! Elements
	//! Popups
	//! Section
	//! Layouts
	//! Global
	[class*='--grid-g'] {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
	}
}
//!Tablet
@media (max-width: 1023px) {
	//! Blocks
	.contacts {
		&__wrapper {
			flex-direction: column;
		}
		&__inner {
			@include adaptiv-value('margin-bottom', $offset-15, $offsetMobile-15, 1);
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			max-width: 100%;

			.popup-write-us {
				justify-content: flex-start;
				align-items: flex-start;
			}
		}
		&__map {
			width: 100%;
		}
	}
	//! Elements
	//! Popups
	//! Section
	.process {
		&--a {
			.process {
				&__list {
					.item-process {
						grid-template-columns: 1fr;
						grid-template-rows: 1fr;
						&:nth-child(even) {
							.Card-img {
								order: 0;
							}
						}
						.Card-img {
							min-height: 208px;
						}
						// grid-template-rows: 100%;
					}
				}
			}
		}
		&--b {
			.process {
				&__list {
					.item-process {
						&__wrap {
							grid-template-columns: 1fr;
							grid-template-rows: 1fr;
						}

						&:nth-child(even) {
							.Card-img {
								order: 0;
							}
						}
						&:nth-child(odd) {
							.item-process__heading {
								.item-process__title,
								.item-process__text {
									color: var(--color-c);
								}
							}
							background-color: var(--color-d);
							.Card-img {
								// order: unset;
							}
							.item-process__heading {
								// order: 3;
							}
						}
						&:nth-child(even) {
							.item-process__heading {
								order: 1;
							}
							.Card-img {
								order: 2;
							}
						}
						.Card-img {
							min-height: 400px;
						}
						// grid-template-rows: 100%;
					}
				}
			}
		}
	}
	.info {
		&__bottom-wrapper {
			justify-content: flex-end;
		}
		&__nav-list {
			padding-right: 20px;
			// padding-bottom: 0;
		}
	}
	.portfolio {
		&__title-slide {
			font-size: 24px !important;
		}
	}
	.detailed {
		&__wrapper {
			flex-direction: column;
		}
	}
	.blog {
		&__list {
			.item-blog {
				grid-template-columns: 100px 1fr;
				&__content-picture {
					display: none;
				}
				&__content-text {
					font-size: 18px;
				}
				&__date-text {
					font-size: 32px;
				}
				&__month-text {
					font-size: 20px;
				}
				&__date {
					padding-left: 4px;
					padding-right: 4px;
				}
			}
		}
	}
	//! Layouts
	//! Global
	[class*='--grid-g'] {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
	}
}
@media (max-width: 866px) {
	//! Blocks
	.hero {
		&__bottom-line {
			width: 100vw;
		}
	}
	//! Elements
	//! Popups
	//! Section
	.info {
		&--a {
			.info {
				&__wrapper {
					height: 88vh;
				}
			}
		}
		&__nav-list-btns {
			min-width: 52px;
			height: 52px;
		}
		&__nav-list-inner {
			align-items: flex-end;
			width: 52px;
		}
	}

	.about-us {
		&__wrapper {
			grid-template-columns: 1fr !important;
		}
	}
}

//!Mobile
@media (max-width: 733px) {
	//! Blocks
	.dropdown__list .item-dropdown__visible .Icon {
		margin-right: 15px;
	}
	.resume {
		&__responsibilities {
			margin-top: 20px;
		}
		&__requirements {
			margin: 0;
			padding: 0;
			border: unset;
		}
		&__wrapper {
			flex-direction: column;
			max-height: 400px;
			overflow-y: scroll;
		}
	}
	.Nav {
		&__main {
			.Nav__list {
				align-items: flex-start;
				max-height: 65vh;
			}
		}
		&__list {
		}
		&__main-wrapper {
			justify-content: flex-start;
			align-items: flex-start;
		}
		&__button {
			opacity: 1;
			visibility: visible;
			.Button {
				@include adaptiv-value('margin-top', $offset-5, $offsetMobile-5, 1);
			}
			.Button--color-clear .Button__text {
				color: var(--color-c);
				font-size: 20px;
			}
		}
	}
	//! Elements
	//! Popups
	//! Section data-mw
	.administration {
		&__list {
			grid-template-columns: 1fr;
			@include adaptiv-value('gap', $offset-16, $offsetMobile-16, 1);
		}
	}
	.describe {
		&__wrapper {
			grid-template-columns: 1fr;
			@include adaptiv-value('gap', $offset-12, $offsetMobile-12, 1);
			border: unset;
		}
	}
	//! Layouts
	
	.header {
		&__action {
			align-items: flex-start;
		}
		&__phone-link {
			font-size: 20px;
		}
		&__btn {
			& > :not(:last-child) {
				margin-right: 0;
			}
			justify-content: center;
		}
		.Hamb {
			&__bar {
				margin-left: auto;
			}
			order: 3;
		}
		&__btn {
			.header__text-link {
				display: none;
			}
		}
		&__wrapper {
			grid-template-columns: 1fr 2fr 1fr;
		}
	}
	.footer {
		&__content {
			flex-direction: column;
		}
		&__copyright {
			flex-direction: column;
		}
		&__copyright-right {
			justify-content: flex-start;
			@include adaptiv-value('margin-top', $offset-7, $offsetMobile-7, 1);
		}
		&__logo {
			.Icon--xxl .Icon__svg {
				width: 52px;
				height: 41px;
				@include adaptiv-value('margin-bottom', $offset-4, $offsetMobile-4, 1);
			}
		}
		&__logo-creators {
			justify-content: flex-start;
		}
		&__nav {
			.Nav-link {
				&__nav-list {
					flex-direction: column;
				}
				&__nav-item:not(:last-child) {
					margin-right: 0;
					@include adaptiv-value('margin-bottom', $offset-3, $offsetMobile-3, 1);
				}
			}
		}
	}
	//! Global
	[class*='--grid-g'] {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
	}
	.container.open {
		padding-right: 0;
	}
}

@media (max-width: 650px) {
	//! Blocks

	//! Elements
	//! Popups
	//! Section
	//! Layouts
	//! Global

}

@media (max-width: 500px) {
	//! Blocks
	.Card-c {
		& .Card-c__img {
			transform: scale(1);
			filter: brightness(0.7);
		}
		.Card-c__title,
		.Card-c__text {
			transform: scale(0.9);
			opacity: 1;
		}
	}
	//! Elements
	//! Popups
	//! Section
	.info {
		&__nav-item {
			max-width: 87px;
			width: 100%;
			&:nth-child(2) {
				max-width: 150px;
			}
			&:nth-child(4) {
				max-width: 63px;
			}
		}
	}
	//! Layouts
	//! Global
}
