.hero {
	height: 100vh;
	display: flex;
	align-items: center;
	background-color: black;

	.container {
		overflow: unset;
	}
	&__bottom {
		margin-top: auto;
		// position: absolute;
		// bottom: 0;
		// width: 85%;
		height: 28px;
		position: relative;
	}
	&__bottom-dec {
		// height: 28px;
		display: flex;
		justify-content: flex-end;
		width: 100%;
		height: 100%;
	}
	&__bottom-line {
		background-color: var(--color-a);
		width: 139.2vw;
		height: 100%;
		position: absolute;
		left: -100vw;
		z-index: -1;
	}
	&__bottom-wrapper {
		display: flex;
		background-color: var(--color-a);
		height: 100%;
		justify-content: space-between;
		// @include adaptiv-value('gap', $offset-4, $offsetMobile-4, 1);
		// flex-wrap: wrap;
	}
	@include adaptiv-value('padding-top', $offset-5, $offsetMobile-5, 1);
	@include adaptiv-value('padding-bottom', $offset-5, $offsetMobile-5, 1);

	&__desc {
		width: 50%;
		@include adaptiv-value('margin-right', $offset-10, $offsetMobile-10, 1);
		.Button {
			@include adaptiv-value('margin-top', $offset-5, $offsetMobile-5, 1);
		}
	}
	&__desc-subtitle {
		@include adaptiv-value('font-size', $font-size-l, $font-size-xs, 1);
		@include adaptiv-value('margin-top', $offset-2, $offsetMobile-2, 1);
	}
	&__desc-title {
		@include adaptiv-value('font-size', $font-size-bxl, $font-size-xl, 1);
		font-weight: 700;
	}
	&__footnote {
		@include adaptiv-value('font-size', $font-size-s, $font-size-xs, 1);
		@include adaptiv-value('margin-top', $offset-2, $offsetMobile-2, 1);
	}
	&__img {
		width: 50%;
	}
	&__logo-picture {
		.Picture {
			&__block-img {
				padding-bottom: 84%;
			}
		}
	}
	&__wrapper {
		display: flex;
		border-width: 0 1px 0 1px;
		border-style: solid;
		border-color: rgba(255, 255, 255, 0.25);

		&--container {
		}
		&--max-width {
		}
	}
}

.hero-slider {
	width: 100%;
	height: 100vh;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
	z-index: 0;
	&__container {
		width: 100%;
		height: 100%;
		display: flex;
		// justify-content: center;
		align-items: center;
	}
	&__wrapper {
		width: 100%;
		height: 100%;
		backdrop-filter: brightness(0.5);
		display: flex;
		align-items: flex-end;
		justify-content: flex-start;
		position: relative;
	}
	.container {
	}
	&__title {
		color: var(--color-c);
		@include adaptiv-value('font-size', $font-size-xxxl, $font-size-m, 1);
		font-weight: 600;
	}
	.swiper-slide {
		overflow: hidden;
		color: #fff;
	}

	.swiper-container {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}

	.slide-inner {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		background-size: cover;
		background-position: center;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: left;
	}
	.slide-bg-image {
		& .container {
			backdrop-filter: brightness(0.7);
		}
		// backdrop-filter: brightness(70.5);
	}
	&__pagination {
		position: absolute;
		z-index: 21;
		right: 20px;
		width: 11px !important;
		text-align: center;
		left: auto !important;
		top: 50%;
		bottom: auto !important;
		transform: translateY(-50%);

		&.swiper-pagination-bullets .swiper-pagination-bullet {
			margin: 8px 0;
		}

		.swiper-pagination-bullet {
			width: 15px;
			margin-top: 5px;
			height: 15px;
			display: block;
			border-radius: 10px;
			background: #2f3030a0;
			opacity: 1;
			transition: all 0.3s;
			&-active {
				filter: brightness(1.5);
				opacity: 1;
				background: var(--color-a);
				height: 30px;
				box-shadow: 0px 0px 20px rgba(252, 56, 56, 0.3);
			}
		}
	}
}

/*--------------------------------------------------------------
	#hero-style
--------------------------------------------------------------*/
.hero-style {
	height: 100vh;
	transition: all 0.4s ease;

	.slide-title,
	.slide-text,
	.slide-btns {
	}

	.slide-title h2 {
		font-size: 100px;
		font-weight: 600;
		line-height: 1;
		color: #ffffff;
		margin: 0 0 40px;
		text-transform: capitalize;
		transition: all 0.4s ease;
	}

	.slide-text p {
		opacity: 0.8;
		font-family: Rajdhani;
		font-size: 32px;
		font-weight: 500;
		line-height: 1.25;
		// letter-spacing: normal;
		color: #ffffff;
		margin: 0 0 40px;
		transition: all 0.4s ease;
	}

	.slide-btns > a:first-child {
		margin-right: 10px;
	}
	@media (max-width: 991px) {
		.hero-style {
			height: 600px;
		}
	}

	@media (max-width: 767px) {
		.hero-style {
			height: 500px;
		}
	}

	@media screen and (min-width: 992px) {
		.hero-style .container {
			padding-top: 95px;
		}
	}

	@media (max-width: 1199px) {
		.hero-style .slide-title h2 {
			font-size: 75px;
		}
	}

	@media (max-width: 991px) {
		.hero-style .slide-title h2 {
			font-size: 50px;
			margin: 0 0 35px;
		}
	}

	@media (max-width: 767px) {
		.hero-style .slide-title h2 {
			font-size: 35px;
			margin: 0 0 30px;
		}
	}

	@media (max-width: 767px) {
		.hero-style .slide-text p {
			font-size: 16px;
			font-size: 1rem;
			font-weight: normal;
			margin: 0 0 30px;
		}
	}
}
