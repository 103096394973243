.gui-page {
  padding: 20px;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: #fff;

  &--popups {
    padding: 50px 0;
    .gui-page {
      &__block-element {
        &--center {
          padding: 0 10px 20px;
        }
      }
    }
  }
  .Map-site-gui {
    &__list {
      display: grid;
      justify-items: center;
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;
    }

    &__item {
      font-weight: 600;
      text-transform: uppercase;
      a {
        color: currentColor;
      }
      &--final {
        color: var(--color-d);
        order: 1;
      }
      &--need-help {
        color: var(--color-a);
      }

      a {
        transition: $transition-time;
      }

      &:hover > a {
        color: blue;
      }
    }
  }

  &__title {
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 35px;
    text-align: center;
    text-transform: uppercase;
  }

  &__container-elements {
    margin: 0 -15px 30px;
  }

  &__section-title {
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
    color: blue;
  }

  &__block-theme {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    justify-content: center;
  }

  &__block-element {
		.Img {
			padding-bottom: 30%;
			width: 200px;
			height: 200px;
		}
    &--center {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    position: relative;
    padding: 0 15px 20px;
    margin-top: 30px;
  }
  &__block-element-text {
    display: flex;
    justify-content: center;
    color: black;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  &__block-element-map {
    display: grid;
    padding: 0 15px 20px;
    width: 100%;
  }
  &__section {
    &--full-screen {
      grid-template-columns: repeat(1, 100%);
    }
    display: grid;
    grid-template-columns: repeat(1, 100%);
    justify-content: center;
  }

}
