.Link {
	text-decoration-line: none;
	transition: 0.02s;
	cursor: pointer;
	$blockName: &;
	//! Общие стили для всех кнопок (для каждого проекта свои стили)
	color: var(--color-a);
	$colorDecor: var(--color-a);
	&:hover {
		color: var(--color-c);
		opacity: 0.8;
	}
	&:disabled {
		color: rgb(0, 0, 0);
		cursor: initial;
	}
	// !Color
	&--color-a {
		$colorAccent: var(--color-a);
		color: $colorAccent;
		&:not(:disabled) {
			&:hover,
			&:focus {
				color: $colorAccent;
				opacity: 0.7;
			}
		}
		&-decor {
			position: relative;
			color: $colorAccent;
		}
		&-decor::before {
			content: '';
			position: absolute;
			top: 100%;
			display: block;
			width: 105%;
			height: 1px;
			background: $colorAccent;
			transform: scale(0);
			transition: 0.02s;
		}
		&-decor:hover::before {
			transform: scale(1);
			transition: 0.02s;
		}
	}
	&--color-b {
		$colorAccent: var(--color-b);
		color: $colorAccent;
		&:not(:disabled) {
			&:hover,
			&:focus {
				color: $colorAccent;
				opacity: 0.7;
			}
		}
		&-decor {
			position: relative;
			color: $colorAccent;
		}
		&-decor::before {
			content: '';
			position: absolute;
			top: 100%;
			display: block;
			width: 105%;
			height: 1px;
			background: $colorDecor;
			transform: scale(0);
			transition: 0.02s;
		}
		&-decor:hover::before {
			transform: scale(1);
			transition: 0.02s;
		}
	}
	&--color-c {
		$colorAccent: var(--color-c);
		color: $colorAccent;
		&:not(:disabled) {
			&:hover,
			&:focus {
				color: $colorAccent;
				opacity: 0.7;
			}
		}
		&-decor {
			position: relative;
			color: $colorAccent;
		}
		&-decor::before {
			content: '';
			position: absolute;
			top: 100%;
			display: block;
			width: 105%;
			height: 1px;
			background: $colorDecor;
			transform: scale(0);
			transition: 0.02s;
		}
		&-decor:hover::before {
			transform: scale(1);
			transition: 0.02s;
		}
	}
	&--color-d {
		$colorAccent: var(--color-d);
		color: $colorAccent;
		&:not(:disabled) {
			&:hover,
			&:focus {
				color: $colorAccent;
				opacity: 0.7;
			}
		}
		&-decor {
			position: relative;
			color: $colorAccent;
		}
		&-decor::before {
			content: '';
			position: absolute;
			top: 100%;
			display: block;
			width: 105%;
			height: 1px;
			background: $colorDecor;
			transform: scale(0);
			transition: 0.02s;
		}
		&-decor:hover::before {
			transform: scale(1);
			transition: 0.02s;
		}
	}
	&--color-e {
		$colorAccent: var(--color-e);
		color: $colorAccent;
		&:not(:disabled) {
			&:hover,
			&:focus {
				color: $colorAccent;
				opacity: 0.7;
			}
		}
		&-decor {
			position: relative;
			color: $colorAccent;
		}
		&-decor::before {
			content: '';
			position: absolute;
			top: 100%;
			display: block;
			width: 105%;
			height: 1px;
			background: $colorDecor;
			transform: scale(0);
			transition: 0.02s;
		}
		&-decor:hover::before {
			transform: scale(1);
			transition: 0.02s;
		}
	}
	&--color-header {
		$colorAccent: var(--color-b);
		$colorHoverAccent: var(--color-a);
		color: $colorAccent;
		&:not(:disabled) {
			&:hover {
				color: $colorHoverAccent;
			}
		}
	}
	// !Size
	&--xs {
		font-size: $font-size-xs + px;
	}
	&--s {
		@include adaptiv-value('font-size', $font-size-s, $font-size-xs, 1);
	}
	&--m {
		@include adaptiv-value('font-size', $font-size-m, $font-size-s, 1);
	}
	&--l {
		@include adaptiv-value('font-size', $font-size-l, $font-size-s, 1);
	}
	&--xl {
		@include adaptiv-value('font-size', $font-size-xl, $font-size-l, 1);
	}
	&--xxl {
		@include adaptiv-value('font-size', $font-size-xxl, $font-size-l, 1);
	}
}
