.detailed {
	&__desc {
		flex: 1 1;
		@include width-percent(657);
		.detailed {
			&__title {
				justify-content: flex-start;
			}
		}
	}
	&__title {
	}

	&__item {
		@include adaptiv-value('margin-top', $offset-4, $offsetMobile-4, 1);
	}
	&__item-text {
		line-height: 1.4;
	}
	&__picture {
		flex: 1 1;
		@include width-percent(575);
		position: relative;
		z-index: -1;
		.Picture {
			&__block-img {
				padding-bottom: unset;
			}
			&__img {
				position: unset;

				pointer-events: none;
				@include adaptiv-value('max-height', 730, 436, 1);
			}
		}
	}
	&__wrapper {
		@include adaptiv-value('margin-top', $offset-20, $offsetMobile-20, 1);
		display: flex;
		@include adaptiv-value('gap', $offset-26, 30, 1);

		&--container {
		}
		&--max-width {
		}
	}
}
