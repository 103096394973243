.preloader {
    &--enabled {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--color-c);
        opacity: 1;
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;

        .preloader__content {
            position: relative;
            width: 150px;
            height: 150px;
            margin: 30px auto;
            border-radius: 50%;
            border: 3px solid transparent;
            border-top-color: var(--color-f);
            animation: preloader-5-spin 2s linear infinite;

            &::before {
                content: "";
                position: absolute;
                top: 5px;
                left: 5px;
                right: 5px;
                bottom: 5px;
                border-radius: 50%;
                border: 3px solid transparent;
                border-top-color: var(--color-a);
                animation: preloader-5-spin 3s linear infinite;
            }

            &::after {
                content: "";
                position: absolute;
                top: 15px;
                left: 15px;
                right: 15px;
                bottom: 15px;
                border-radius: 50%;
                border: 3px solid transparent;
                border-top-color: var(--color-f);
                animation: preloader-5-spin 1.5s linear infinite;
            }
        }

    }

    &--disabled {
        transition: opacity .3s;
        opacity: 0;
        pointer-events: none;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--color-c);
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
    }
}


@keyframes preloader-5-spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}