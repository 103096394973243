// !COLORS
:root {
	--color-a: #891a22;
	--color-b: #000000;
	--color-c: #ffffff;
	--color-d: #232323;
	--color-e: #bdbdbd;
	--color-f: #d13843;
	--color-g: #646464;
	--color-h: #e2e2e2;
	--color-i: #222631;
}
// !Offset
$primaryOffset: 5;
$mobileOffset: 2;

// !Fonts Size
//?? Fonts Size variables
$font-size-xss: 12;
$font-size-xs: 14;
$font-size-s: 16;
$font-size-m: 20;
$font-size-l: 24;
$font-size-xl: 32;
$font-size-xxl: 38;
$font-size-xxxl: 40;
$font-size-bxl: 56;

// !Button Size
$button-size-xs: 42;
$button-size-s: 200;
$button-size-m: 286;
$button-size-l: 378;

// !Icon Size
$icon-size-xs: 18;
$icon-size-s: 24;
$icon-size-m: 32;
$icon-size-l: 56;
$icon-size-xl: 60;
$icon-size-xxl: 80;
$icon-size-xxxl: 105;
$icon-size-bxl: 152;

// !POSITION

// ? center
[class*='--center'] {
	display: flex;
	justify-content: center;
	align-items: center;
}
// !THEME

// ? theme-a
[class*='--theme-a'] {
	line-height: 112%;
	font-weight: 600;
	font-size: 18px;
}

// !SIZE
$maxWidth: 1920;

// !FONTS
$font-family-default: 'Jost';

// !BREAKPOINTS
$maxWidthContainer: 1366;
$md1: $maxWidthContainer + 40;
$md2: 991.98;
$md3: 767.98;
$md4: 600.98;

// !Transition
$transition-time: 0.3s;

//TODO : Technical var

//! Desktop
// ? Offset
$offset-05: $primaryOffset / 2;
$offset-1: $primaryOffset;
$offset-2: $primaryOffset * 2;
$offset-3: $primaryOffset * 3;
$offset-4: $primaryOffset * 4;
$offset-5: $primaryOffset * 5;
$offset-6: $primaryOffset * 6;
$offset-7: $primaryOffset * 7;
$offset-8: $primaryOffset * 8;
$offset-9: $primaryOffset * 9;
$offset-10: $primaryOffset * 10;
$offset-11: $primaryOffset * 11;
$offset-12: $primaryOffset * 12;
$offset-13: $primaryOffset * 13;
$offset-14: $primaryOffset * 14;
$offset-15: $primaryOffset * 15;
$offset-16: $primaryOffset * 16;
$offset-17: $primaryOffset * 17;
$offset-18: $primaryOffset * 18;
$offset-19: $primaryOffset * 19;
$offset-20: $primaryOffset * 20;
$offset-21: $primaryOffset * 21;
$offset-22: $primaryOffset * 22;
$offset-23: $primaryOffset * 23;
$offset-24: $primaryOffset * 24;
$offset-25: $primaryOffset * 25;
$offset-26: $primaryOffset * 26;
$offset-27: $primaryOffset * 27;
$offset-28: $primaryOffset * 28;
$offset-29: $primaryOffset * 29;
$offset-30: $primaryOffset * 30;

//! Mobile
// ? Offset
$mobileOffset: 2;
$offsetMobile-05: $offset-05;
$offsetMobile-1: $offset-1 / 2;
$offsetMobile-2: $offset-2 / 2;
$offsetMobile-3: $offset-3 / 2;
$offsetMobile-4: $offset-4 / 2;
$offsetMobile-5: $offset-5 / 2;
$offsetMobile-6: $offset-6 / 2;
$offsetMobile-7: $offset-7 / 2;
$offsetMobile-8: $offset-8 / 2;
$offsetMobile-9: $offset-9 / 2;
$offsetMobile-10: $offset-10/ 2;
$offsetMobile-11: $offset-11 / 2;
$offsetMobile-12: $offset-12 / 2;
$offsetMobile-13: $offset-13 / 2;
$offsetMobile-14: $offset-14 / 2;
$offsetMobile-15: $offset-15 / 2;
$offsetMobile-16: $offset-16 / 2;
$offsetMobile-17: $offset-17 / 2;
$offsetMobile-18: $offset-18 / 2;
$offsetMobile-19: $offset-19 / 2;
$offsetMobile-20: $offset-20 / 2;
$offsetMobile-21: $offset-21 / 2;
$offsetMobile-22: $offset-22 / 2;
$offsetMobile-23: $offset-23 / 2;
$offsetMobile-24: $offset-24 / 2;
$offsetMobile-25: $offset-25 / 2;
$offsetMobile-26: $offset-26 / 2;
$offsetMobile-27: $offset-27 / 2;
$offsetMobile-28: $offset-28 / 2;
$offsetMobile-29: $offset-29 / 2;
$offsetMobile-30: $offset-30 / 2;
