.Feedback {
	&__address-title {
		@include adaptiv-value('font-size', $font-size-s, $font-size-xs, 1);
		color: var(--color-c);
		line-height: 1.6;
	}
	&__block-address {
	}
	&__block-mail {
	}
	&__block-number {
	}
	&__mail {
		@include adaptiv-value("margin-top", $offset-1, $offsetMobile-1, 1);
	}
	&__number {
	}
	&__wrapper {
	}
}
