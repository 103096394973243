.about-us {
	&__item {
	}
	&__img {
		@include adaptiv-value('margin-top', $offset-10, $offsetMobile-10, 1);
		.Picture {
			&__block-img {
				padding-bottom: 71%;
			}
		}
	}
	&__left {
		.Title {
			justify-content: flex-start;
		}
	}
	&__list {
		@include adaptiv-value('max-width', 343, 250, 1);
		@include adaptiv-value('margin-top', $offset-4, $offsetMobile-4, 1);
		@include adaptiv-value('margin-bottom', $offset-4, $offsetMobile-4, 1);
		.item-about-us {
			display: flex;
			justify-content: space-between;
			@include adaptiv-value('font-size', $font-size-l, $font-size-xs, 1);
			border-bottom: 1px solid var(--color-b);
			&:not(:first-child) {
				@include adaptiv-value('margin-top', $offset-4, $offsetMobile-4, 1);
			}

			&__count {
				@include adaptiv-value('font-size', 72, 50, 1);
				display: flex;
				color: var(--color-a);
				line-height: 0.93;
				span {
					color: var(--color-a);
				}
			}
			&__text {
				display: flex;
				align-items: flex-end;
				@include adaptiv-value('max-width', 145, 100, 1);
			}
		}
	}
	&__right {
		max-width: 475px;
	}
	&__subtitle {
	}
	&__text {
		@include adaptiv-value('margin-top', $offset-4, $offsetMobile-4, 1);
	}
	&__title {
	}
	&__wrapper {
		@include adaptiv-value('margin-top', $offset-20, $offsetMobile-20, 1);
		@include adaptiv-value('padding-bottom', $offset-12, $offsetMobile-12, 1);
		border-bottom: 1px solid var(--color-e);
		display: grid;
		grid-template-columns: 1fr 1fr;
		@include adaptiv-value('gap', $offset-16, $offsetMobile-16, 1);
		&--container {
		}
		&--max-width {
		}
	}
}
