.dropdown {
	&--vacancy {
		.dropdown {
			&__list {
				.item-dropdown {
					&--active {
						.item-dropdown {
							&:last-child {
								border-bottom: 1px solid #ebebeb;
							}
						}
						.item-dropdown__visible {
							.Icon {
								&__svg {
									stroke: var(--color-a);
								}
							}
						}
					}

					&:not(:last-child) {
						border-bottom: 1px solid #ebebeb;
					}
					&:last-child {
						border-bottom: 1px solid #ebebeb;
					}
					&__visible {
						@include adaptiv-value('margin-top', $offset-9, $offsetMobile-9, 1);
						@include adaptiv-value('margin-bottom', $offset-3, $offsetMobile-3, 1);
						.Icon {
							&__svg {
								stroke: var(--color-a);
							}
						}
					}
				}
			}
		}
	}
	&__button {
		display: flex;
		justify-content: center;
		@include adaptiv-value('margin-top', $offset-8, $offsetMobile-8, 1);
	}
	&__item {
	}
	.dropdown__list .item-dropdown__visible .Title {
		font-weight: 400;
	}
	&__list {
		.item-dropdown {
			&--active {
				.item-dropdown {
					&__visible {
						.Icon {
							transform: rotate(-180deg);
							&__svg {
								stroke: var(--color-c);
							}

							&--color-f {
								.Icon {
									&__svg {
										fill: var(--color-a);
									}
								}
							}
						}
					}
				}
			}
			&__icon {
				@include adaptiv-value('margin-right', $offset-12, $offsetMobile-12, 1);
			}

			border-bottom: 1px solid var(--color-c);

			&__hidden {
				display: none;
				@include adaptiv-value('margin-top', $offset-7, $offsetMobile-7, 1);
				@include adaptiv-value('margin-bottom', $offset-5, $offsetMobile-5, 1);
			}
			&__hidden-text {
				line-height: 140%;
				max-width: 1120px;
			}
			&__visible {
				@include adaptiv-value('margin-top', $offset-11, $offsetMobile-11, 1);
				@include adaptiv-value('margin-bottom', $offset-5, $offsetMobile-5, 1);
				display: flex;
				justify-content: space-between;
				align-items: center;

				cursor: pointer;
				min-height: 72px;
				.Icon {
					width: 25px;
					height: 25px;
					@include adaptiv-value('margin-left', $offset-1, $offsetMobile-1, 1);
					&__svg {
						stroke: var(--color-c);
					}
				}
				.Title {
					text-align: left;
				}
				&:hover {
					.Title {
						&--color-f {
							color: var(--color-a);
						}
					}
					.Icon {
						// transform: rotate(-180deg);

						&--color-f {
							.Icon {
								&__svg {
									fill: var(--color-a);
								}
							}
						}
					}
				}
			}
		}
	}
}
