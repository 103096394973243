.__select {
    display: flex;
    justify-content: center;
		background-color: var(--color-c);

    &__wrapper {
        width: 230px;
        height: 40px;
        position: relative;
    }

    &[data-state="active"] {
        .__select__title {
            border: 1px solid var(--color-a);
            &::before {
                transform: translate(-3px, -50%) rotate(-45deg);
            }

            &::after {
                transform: translate(3px, -50%) rotate(45deg);
            }
        }

        .__select__content {
            border: 1px solid var(--color-a);
            pointer-events: auto;
            border-top: none;
            opacity: 1;
        }

        .__select__label+.__select__input+.__select__label {
            max-height: 40px;
            border-top-width: 1px;
        }
    }
}

.__select__title {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 8px 16px;

    border: solid 1px #c7ccd1;

    cursor: pointer;

    &::before,
    &::after {
        content: "";

        position: absolute;
        top: 50%;
        right: 16px;

        display: block;
        width: 10px;
        height: 2px;

        transition: all 0.3s ease-out;

        background-color: #333333;

        transform: translate(-3px, -50%) rotate(45deg);
    }

    &::after {
        transform: translate(3px, -50%) rotate(-45deg);
    }

    &:hover {
        border-color: var(--color-a);

        &::before,
        &::after {
            background-color: var(--color-a);
        }
    }
}

.__select__content {
    pointer-events: none;
    position: absolute;
    top: 40px;

    display: flex;
    flex-direction: column;
    width: 100%;

    background-color: #ffffff;

    border: 1px solid #c7ccd1;
    border-top: none;

    transition: all 0.3s ease-out;

    opacity: 0;
    z-index: 800;
}

.__select__input {
    display: none;

    &:checked+label {
        background-color: #dedede;
    }

    &:disabled+label {
        opacity: 0.6;
        pointer-events: none;
    }
}

.__select__label {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 0 16px;

    transition: all 0.2s ease-out;

    cursor: pointer;

    overflow: hidden;

    &+input+& {
        border-top: 0 solid #C7CCD160;
    }

    &:hover {
        background-color: var(--color-a) !important;

        color: #ffffff;
    }
}