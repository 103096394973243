.Hamb {
	&__bar {
		display: block;
		width: 40px;
		height: 4px;
		&:not(:first-child) {
			margin-top: 6px;
		}
		background-color: var(--color-c);
		transition: 0.2s;
		&:nth-child(2) {
			width: 30px;
		}
		&:nth-child(3) {
			width: 20px;
		}
	}
	&__field {
		// padding: 10px 20px;
		cursor: pointer;
	}

	&__field.active &__bar {
		&:nth-child(2) {
			opacity: 0;

			width: 40px;
		}
		&:nth-child(1) {
			transform: translateY(12px) rotate(45deg);

			width: 40px;
		}
		&:nth-child(3) {
			transform: translateY(-8px) rotate(-45deg);

			width: 40px;
		}
	}
}
