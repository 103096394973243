.Bread-crumbs {
	// margin: 20px 0;
	&__list {
		display: flex;
		overflow: hidden;
		white-space: nowrap;
		flex-wrap: wrap;
		text-overflow: ellipsis;
	}

	&__list-item {
		&:not(:last-child) {
			position: relative;
			margin-right: 3px;
			padding-right: 5px;

			&::after {
				content: '/';
				// position: absolute;
				color: var(--color-c);
				right: 0;
				top: 50%;
				width: 4px;
				height: 8px;
				font-size: 14px;
				transform: translateY(-50%);
			}
		}
	}

	&__list-item--black {
		&::after {
			filter: blur(2px);
		}

		.Bread-crumbs__link {
			&:hover {
				border-bottom-color: var(--color-c);
				color: var(--color-c);
			}
		}

		.Bread-crumbs__link-text {
			color: var(--color-c);
		}
	}
	&__link-text {
		color: #d1d1d1;
		&:hover {
			color: var(--color-c);
		}
	}
	&__list-item--active {
		.Bread-crumbs__link-text {
			color: var(--color-c);
		}
	}
	&__link {
		border-bottom: 1px solid;
		border-bottom-color: transparent;
		transition: 0.3s all;

		&:hover {
			color: var(--color-c);
		}
	}

	&__link-text {
		font-size: 16px;
		line-height: 14px;
	}
}
