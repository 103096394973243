.Page-not-found {
	&__btn {
		display: flex;
		justify-content: center;
		@include adaptiv-value('margin-top', 30, 15, 1);

		.Button {
			&__text {}

			&--blue {}

			&--theme-s {}
		}
	}

	&__content-desc-btn {
		@include adaptiv-value('margin-top', $offset-5, $offsetMobile-5, 1);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	&__desc {
		text-align: center;
		position: relative;
		@include adaptiv-value('padding-top', 30, 15, 1);
		@include adaptiv-value('font-size', 24, 16, 1);
		line-height: 117%;
		max-width: 600px;
		text-align: left;
		color: var(--color-a);
		padding: 10px;
		text-align: center;
	}

	&__heading {
		color: var(--color-a);
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	&__subtitle {
		@include adaptiv-value('font-size', 168, 83, 1);
		font-family: 'HelveticaNeueCyr', sans-serif;
		font-weight: 700;
		line-height: 120%;
		color: transparent;
		-webkit-background-clip: text;
		filter: brightness(0.5);
	}

	&__subtitle-text {
		color: transparent;
		-webkit-background-clip: text;
	}

	&__title {
		font-weight: 500;
		@include adaptiv-value('font-size', $font-size-xxl, 18, 1);
		line-height: 122%;
		text-shadow: 5px 10px 20px rgba(0, 0, 0, 0.15);
		@include adaptiv-value('margin-bottom', $offset-8, $offsetMobile-8, 1);
	}

	&__title-text {
		color: var(--color-b);
	}

	&__wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		@include adaptiv-value('padding-top', 100, 30, 1);
		@include adaptiv-value('padding-bottom', 100, 30, 1);
		@include adaptiv-value('padding-left', 50, 20, 1);
		@include adaptiv-value('padding-right', 50, 20, 1);
	}

	&__home-link {
		@include adaptiv-value('margin-top', $offset-5, $offsetMobile-5, 1);
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__home-link-link {
		display: flex;

		.Link {
			&__text {
				margin-left: 10px;
			}
			&__icon {
				display: flex;
				order: -1;
				align-items: center;
				* {
					width: 27px;
					height: 5px;
				}
			}
			&__icon-svg {
				transform: rotateY(-180deg);
			}
		}
	}
}