.describe {
	&__content {
	}
	&__date {
	}
	&__date-text {
	}
	&__text {
		br {
			height: 10px;
		}
	}
	&__picture {
		@include adaptiv-value("margin-top", $offset-8, $offsetMobile-8, 1);
		@include adaptiv-value("margin-bottom", $offset-8, $offsetMobile-8, 1);
	}
	&__wrapper {
		display: grid;
		grid-template-columns: 150px 1fr;
		@include adaptiv-value("padding-top", $offset-8, $offsetMobile-8, 1);
		@include adaptiv-value("margin-top", $offset-20, $offsetMobile-20, 1);
		border-top: 1px solid var(--color-h);
		@include adaptiv-value("gap", $offset-23, $offsetMobile-23, 1);
		&--container {
		}
		&--max-width {
		}
	}
}
