.Nav-link {
	&__nav {
	}
	&__nav-item {
		text-transform: uppercase;
		&:not(:last-child) {
			@include adaptiv-value("margin-right", $offset-12, $offsetMobile-12, 1);
		} 
	}
	&__nav-list {
		display: flex;

		&--scroll-h {
		}
	}
	&__wrapper {
	}
	&--a {
	}
}
