.gallery {
	&--project {
		.gallery {
			&__list-area {
				@include adaptiv-value('gap', $offset-6, 8, 1);
				@include adaptiv-value('margin-top', $offset-19, $offsetMobile-19, 1);
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				grid-template-rows: repeat(2, 315px) repeat(2, 400px);
				&:first-child {
				}
				&:not(:first-child) {
				}
			}
			&__item-area {
				&:nth-child(1) {
					grid-area: 1 / 1 / 3 / 2;
				}
				&:nth-child(2) {
					grid-area: 1 / 2 / 2 / 3;
				}
				&:nth-child(3) {
					grid-area: 2 / 2 / 3 / 3;
				}
				&:nth-child(4) {
					grid-area: 3 / 1 / 4 / 3;
				}
				&:nth-child(5) {
					grid-area: 4 / 1 / 5 / 3;
				}
				&:nth-child(6) {
					grid-area: 5 / 1 / 6 / 2;
				}
				&:nth-child(7) {
					grid-area: 4 / 2 / 6 / 3;
				}
				&:nth-child(8) {
					grid-area: 6 / 1 / 7 / 3;
				}

				img {
					object-fit: cover;
					width: 100%;
					height: 100%;
				}
			}
		}
	}
	&--projects {
		@include adaptiv-value('margin-top', $offset-20, $offsetMobile-20, 1);
		.gallery {
			&__list-area {
				&:not(:first-child) {
					@include adaptiv-value('margin-top', $offset-6, $offsetMobile-6, 1);
				}
				display: grid;
				// grid-template-columns: repeat(2, 1fr);
				// grid-template-rows: 454px repeat(4, 330px) 555px;
				@include adaptiv-value('gap', $offset-6, 8, 1);
			}

			&__item-area:last-child {
				aspect-ratio: 1/1 !important;

				img {
				}
			}

			&__item-area {
				max-height: 669px;

				img {
					width: 100%;
					height: 100%;
					aspect-ratio: 2.4/1;
					object-fit: cover;
				}
				&:last-child {
				}
				&:nth-child(1) {
					img {
						width: 100%;
						height: 100%;
						aspect-ratio: 16/5.5;
						object-fit: cover;
					}

					grid-area: 1 / 1 / 2 / 3;
				}
				&:nth-child(2) {
					img {
						height: 100%;
						aspect-ratio: 1/1;
						width: 100%;
						object-fit: cover;
					}

					grid-area: 2 / 1 / 4 / 2;
				}
				&:nth-child(3) {
					img {
						width: 100%;
						height: 100%;
						aspect-ratio: 16/7.55;
						object-fit: cover;
					}
					grid-area: 2 / 2 / 3 / 3;
				}
				&:nth-child(4) {
					img {
						width: 100%;
						height: 100%;
						aspect-ratio: 16/7.55;
						object-fit: cover;
					}
					grid-area: 3 / 2 / 4 / 3;
				}
				&:nth-child(5) {
					img {
						width: 100%;
						height: 100%;
						aspect-ratio: 16/7.55;
						object-fit: cover;
					}
					grid-area: 4 / 1 / 5 / 2;
				}
				&:nth-child(6) {
					img {
						width: 100%;
						height: 100%;
						aspect-ratio: 16/7.55;
						object-fit: cover;
					}
					grid-area: 5 / 1 / 6 / 2;
				}
				&:nth-child(7) {
					img {
						object-fit: cover;
						height: 100%;
						aspect-ratio: 1/1;
						width: 100%;
					}

					grid-area: 4 / 2 / 6 / 3;
				}

				img {
					object-fit: cover;
				}
			}

			&__wrapper {
				min-height: 50vh;
			}

			&__button {
				display: flex;
				justify-content: center;
				@include adaptiv-value('margin-top', $offset-10, $offsetMobile-10, 1);
			}

			&__list-area {
				&--filter-error {
					height: 40vh;
					width: 100%;
				}
				.error {
					&__wrapper {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 100%;
						height: 100%;
					}

					&__text {
						@include adaptiv-value('font-size', 20, 14, 1);

						.text-error {
							&__type {
								font-weight: 600;
							}
						}
					}
				}
			}
		}
	}
}
@media (max-width: 500px) {
	.gallery--projects {
		.gallery {
			&__item-area:last-child {
				aspect-ratio: unset !important;

				img {
				}
			}
			&__list-area {
				grid-template-columns: 1fr;
			}
			&__item-area {
				&:nth-child(1) {
					img {
						aspect-ratio: unset;
					}
					grid-area: unset;
				}
				&:nth-child(2) {
					img {
						aspect-ratio: unset;
					}
					grid-area: unset;
				}
				&:nth-child(3) {
					img {
						aspect-ratio: unset;
					}
					grid-area: unset;
				}
				&:nth-child(4) {
					img {
						aspect-ratio: unset;
					}
					grid-area: unset;
				}
				&:nth-child(5) {
					img {
						aspect-ratio: unset;
					}
					grid-area: unset;
				}
				&:nth-child(6) {
					img {
						aspect-ratio: unset;
					}
					grid-area: unset;
				}
				&:nth-child(7) {
					img {
						aspect-ratio: unset;
					}
					grid-area: unset;
				}
			}
		}
	}
	.gallery--project {
		.gallery {
			&__list-area {
				grid-template-columns: repeat(1, 1fr);
				grid-template-rows: unset;
			}
			&__item-area {
				&:nth-child(1) {
					img {
						aspect-ratio: unset;
					}
					grid-area: unset;
				}
				&:nth-child(2) {
					img {
						aspect-ratio: unset;
					}
					grid-area: unset;
				}
				&:nth-child(3) {
					img {
						aspect-ratio: unset;
					}
					grid-area: unset;
				}
				&:nth-child(4) {
					img {
						aspect-ratio: unset;
					}
					grid-area: unset;
				}
				&:nth-child(5) {
					img {
						aspect-ratio: unset;
					}
					grid-area: unset;
				}
				&:nth-child(6) {
					img {
						aspect-ratio: unset;
					}
					grid-area: unset;
				}
				&:nth-child(7) {
					img {
						aspect-ratio: unset;
					}
					grid-area: unset;
				}
			}
		}
	}
}
